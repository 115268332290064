import React, { useEffect, useState, useRef, Fragment, useContext } from 'react'
import {
  Card,
  CardContent,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Input } from 'shadcn-components/ui/input'
import { Label } from 'shadcn-components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { Slider } from 'shadcn-components/ui/slider'
import { Switch } from 'shadcn-components/ui/switch'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import { InfoIcon } from 'lucide-react'

import { inputFieldChange } from 'services/Utils'
import apiGoals from 'services/api/Goal'
import { useQueryClient } from 'react-query'
import { useAsyncDebounce } from 'react-table'
import { useHistory, Link } from 'react-router-dom'
import ThemeContext from 'services/providers/ThemeContext'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from 'shadcn-components/ui/dialog'
import { AlertTriangle } from 'lucide-react'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { SelectGroup } from 'shadcn-components/ui/select'
import { useToast } from 'shadcn-components/ui/use-toast'

const Goal = ({
  objectives,
  strategies,
  type,
  goal,
  goalGlobalPublicId,
  goalGlobalASIN,
  products,
  newProducts,
  seller,
  profileId,
  isFreeUser,
  dataIsLoading,
  setSelectedRow = () => {},
  setSelectedRows = () => {},
}) => {
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const history = useHistory()

  const [loadingDisable, setloadingDisable] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const { lightMode } = useContext(ThemeContext)

  const types = [
    { value: goalGlobalPublicId, label: 'Global' },
    { value: 'custom', label: 'Custom' },
  ]

  const ACOSPlaceholder = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0.3)
  const TACOSPlaceholder = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0.15)
  const minACOS = 5
  const minTACOS = 1
  const minBudget = 10
  const recommendedPriority = 60
  const [currency, setCurrency] = useState('USD')
  const [budgetPlaceholder, setBudgetPlaceholder] = useState()

  const [publicId, setPublicId] = useState('')

  const [goalASIN, setGoalASIN] = useState(
    goalGlobalASIN ? { value: goalGlobalASIN, label: goalGlobalASIN } : {}
  )
  const [goalASINValidation, setGoalASINValidation] = useState(
    goalGlobalASIN ? 'has-success' : ''
  )
  const [goalASINFocus, setGoalASINFocus] = useState('')
  const [goalASINOptions, setGoalASINOptions] = useState([])

  const [objective, setObjective] = useState()
  const [objectiveValidation, setObjectiveValidation] = useState('')
  const [objectiveFocus, setObjectiveFocus] = useState('')
  const [objectiveOptions] = useState(objectives)

  const [strategy, setStrategy] = useState()
  const [strategyValidation, setStrategyValidation] = useState('')
  const [strategyFocus, setStrategyFocus] = useState('')
  const [strategyOptions, setStrategyOptions] = useState(strategies)

  const [profitTarget, setProfitTarget] = useState()

  const [targetACOS, setTargetACOS] = useState('')
  const [targetACOSValidation, setTargetACOSValidation] = useState('')
  const [targetROAS, setTargetROAS] = useState(0)

  const [targetTACOS, setTargetTACOS] = useState('')
  const [targetTACOSValidation, setTargetTACOSValidation] = useState('')
  const [targetTROAS, setTargetTROAS] = useState(0)

  const [targetBudget, setTargetBudget] = useState('')
  const [targetBudgetValidation, setTargetBudgetValidation] = useState('')

  const [adsOn, setAdsOn] = useState(false)
  const [turboOn, setTurboOn] = useState(false)

  const [goalType, setGoalType] = useState()
  const [goalTypeOptions] = useState(types)

  const [priorityValidation, setPriorityValidation] = useState('')
  const [negativeProfitValidation, setNegativeProfitValidation] =
    useState(false)
  const prioritySliderRef = useRef(null)
  const [avgProductProfit, setAvgProductProfit] = useState(0)
  const [avgProductMargin, setAvgProductMargin] = useState(0)
  const [avgProductAdSpend, setAvgProductAdSpend] = useState(0)
  const [avgProductAdSpendPerc, setAvgProductAdSpendPerc] = useState(0)

  const campaignTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'sp_targets', label: 'Product Targeting' },
  ]

  const useCampaignTypes =
    publicId &&
    type !== 'global' &&
    goalType?.label === 'Custom' &&
    [
      'd49bc0d2-5ecd-43af-9ddd-ffe6ea7f0301',
      'ccda45de-9d76-4df6-bbfe-426c8f134739', // SI
      '9d3ed2f5-3af7-430e-af18-5b0ab08e4198', // SmashCommerce
    ].includes(seller.company.public_id)

  const [campaignTypes, setCampaignTypes] = useState(
    goal?.campaign_type
      ?.split(',')
      ?.map((t) => campaignTypeOptions.find((o) => o.value === t)) || [
      campaignTypeOptions[0],
    ]
  )

  const [showCustomFields, setShowCustomFields] = useState(false)
  const [showMaxProfitFields, setShowMaxProfitFields] = useState(false)
  const [showMaxSalesFields, setShowMaxSalesFields] = useState(false)
  const [showACOSFields, setShowACOSFields] = useState(false)
  const [showProfitFields, setShowProfitFields] = useState(false)
  const [showBudgetFields, setShowBudgetFields] = useState(false)
  const [showTACOSFields, setShowTACOSFields] = useState(false)

  const [turnOffAdsWarningAlert, setTurnOffAdsWarningAlert] = useState(null)

  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (seller?.primary_marketplace_currency_code) {
      const cur = seller.primary_marketplace_currency_code
      setBudgetPlaceholder(
        Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: cur ? cur : 'USD',
        }).format(100)
      )
      setCurrency(cur)
    }
  }, [seller])

  useEffect(() => {
    if (products?.length) {
      setGoalASINOptions(
        products?.map((p) => ({ value: p.asin, label: p.asin }))
      )
    } else if (newProducts?.length) {
      setGoalASINOptions(
        newProducts?.map((asin) => ({ value: asin, label: asin }))
      )
    }
  }, [products, newProducts])

  useEffect(() => {
    const id = goal?.public_id
    const obj = objectives.find((o) => o.value === goal?.objective)
    const strat = strategies.find((s) => s.value === goal?.strategy)
    const strat_val = goal?.strategy_value
    const state = goal ? (goal.state === 'enabled' ? true : false) : false
    const turbo_mode = goal
      ? goal.turbo_mode === 'enabled'
        ? true
        : false
      : false
    const goal_type = types.find(
      (t) =>
        t.value ===
        (goal?.use_global_public_id ? goal?.use_global_public_id : 'custom')
    )
    setPublicId(id)
    setObjective(obj)
    setGoalType(goal_type)
    setShowCustomFields(goal_type?.value === 'custom' ? true : false)
    switch (obj?.value) {
      case 'maximize_sales':
        setObjectiveValidation('has-success')
        setStrategy(strat)
        setShowMaxSalesFields(true)
        switch (strat?.value) {
          case 'minimum_net_profit':
            setStrategyValidation('has-success')
            //setPriorityValidation('has-success')
            setProfitTarget(strat_val * 100)
            setShowProfitFields(true)
            break
          case 'maximum_acos':
            setStrategyValidation('has-success')
            setTargetACOSValidation('has-success')
            setTargetACOS(strat_val * 100)
            setShowACOSFields(true)
            break
          case 'maximum_daily_budget':
            setStrategyValidation('has-success')
            setTargetBudgetValidation('has-success')
            setTargetBudget(strat_val)
            setShowBudgetFields(true)
            break
          case 'maximum_tacos':
            setStrategyValidation('has-success')
            setTargetTACOSValidation('has-success')
            setTargetTACOS(strat_val * 100)
            setShowTACOSFields(true)
            break
          default:
          // code block
        }
        break
      case 'maximize_profit':
        setObjectiveValidation('has-success')
        break
      default:
      // code block
    }
    setAdsOn(state)
    setTurboOn(turbo_mode)
  }, [goal])

  const resetFieldInputs = () => {
    setTargetACOSValidation('')
    setTargetBudgetValidation('')
    setTargetTACOSValidation('')

    setTargetACOS('')
    setTargetBudget('')
    setProfitTarget()
    setTargetTACOS('')
    if (prioritySliderRef.current && prioritySliderRef.current.noUiSlider) {
      prioritySliderRef.current.noUiSlider.set(recommendedPriority)
    }

    updateROAS('')
    updateTROAS('')
  }

  const goalTypeSelect = (goalType) => {
    setGoalType(goalType)
    setShowCustomFields(goalType?.value === 'custom' ? true : false)
    if (goalType?.value != 'custom') {
      setAdsOn(true)
    }
  }

  const objectiveSelect = (objective) => {
    setStrategy('')
    setStrategyValidation('')
    setObjectiveValidation('has-success')
    if (objective !== 'maximize_profit') {
      setShowMaxProfitFields(false)
      setShowMaxSalesFields(true)
    } else {
      setShowMaxProfitFields(true)
      setShowMaxSalesFields(false)
      setShowACOSFields(false)
      setShowProfitFields(false)
      setShowBudgetFields(false)
      setShowTACOSFields(false)
      resetFieldInputs()
    }
  }

  const strategySelect = (strategy) => {
    setStrategyValidation('has-success')
    setObjectiveValidation('has-success')
    resetFieldInputs()
    switch (strategy) {
      case 'minimum_net_profit':
        setShowProfitFields(true)
        setShowACOSFields(false)
        setShowBudgetFields(false)
        setShowTACOSFields(false)
        break
      case 'maximum_acos':
        setShowACOSFields(true)
        setShowProfitFields(false)
        setShowBudgetFields(false)
        setShowTACOSFields(false)
        break
      case 'maximum_daily_budget':
        setShowBudgetFields(true)
        setShowACOSFields(false)
        setShowProfitFields(false)
        setShowTACOSFields(false)
        break
      case 'maximum_tacos':
        setShowACOSFields(false)
        setShowProfitFields(false)
        setShowBudgetFields(false)
        setShowTACOSFields(true)
        break
      default:
      // code block
    }
  }

  const updateROAS = (acos) => {
    setTargetROAS(1 / (Number(acos) / 100))
  }

  const updateTROAS = (tacos) => {
    setTargetTROAS(1 / (Number(tacos) / 100))
  }

  const debounceSetProductStats = useAsyncDebounce(
    (ad_spend, ad_spend_perc, net_profit, net_profit_margin) => {
      setAvgProductAdSpend(ad_spend)
      setAvgProductAdSpendPerc(ad_spend_perc)
      setAvgProductProfit(net_profit)
      setAvgProductMargin(net_profit_margin)
    },
    10
  )

  const updateProductStats = (
    values,
    handle,
    unencoded,
    tap,
    positions,
    noUiSlider
  ) => {
    // values: Current slider values (array);
    // handle: Handle that caused the event (number);
    // unencoded: Slider values without formatting (array);
    // tap: Event was caused by the user tapping the slider (boolean);
    // positions: Left offset of the handles (array);
    // noUiSlider: slider public Api (noUiSlider);
    let priorityValue = unencoded[0] / 100
    const data = products?.map((product, key) => {
      let price = product.price || product.your_price
      const out = {
        asin: product.asin,
        price: price,
        profit: product.profit,
        ad_spend: product.profit * priorityValue,
        ad_spend_perc: (product.profit * priorityValue) / price,
        net_profit: product.profit * (1 - priorityValue),
        net_profit_margin: (product.profit * (1 - priorityValue)) / price,
      }
      return out
    })
    const stats = {
      mean_profit:
        data?.reduce((prev, cur) => prev + cur.profit, 0) / data?.length,
      mean_ad_spend:
        data?.reduce((prev, cur) => prev + cur.ad_spend, 0) / data?.length,
      mean_ad_spend_perc:
        data?.reduce((prev, cur) => prev + cur.ad_spend_perc, 0) / data?.length,
      mean_net_profit:
        data?.reduce((prev, cur) => prev + cur.net_profit, 0) / data?.length,
      mean_net_profit_margin:
        data?.reduce((prev, cur) => prev + cur.net_profit_margin, 0) /
        data?.length,
    }

    debounceSetProductStats(
      // for smoother sliding...
      stats.mean_ad_spend,
      stats.mean_ad_spend_perc,
      stats.mean_net_profit,
      stats.mean_net_profit_margin
    )

    if (priorityValue < 0.15) {
      setPriorityValidation('has-danger')
    } else {
      setPriorityValidation('')
    }
    if (stats.mean_net_profit < 0) {
      setNegativeProfitValidation(true)
    } else {
      setNegativeProfitValidation(false)
    }
  }

  const meanNetProfitMarginToSliderValue = (xbar, prods) => {
    // see notes for expression, xbar is meanNetProfitMargin as %
    // x = 1 - (xbar / c) where c = sum(profit / numProducts * price)
    const c = prods?.reduce(
      (prev, cur) =>
        prev + cur.profit / (prods.length * (cur.price || cur.your_price)),
      0
    )
    return (1 - xbar / 100 / c) * 100
  }

  const recommendedClickTargetACOS = (e) => {
    setObjective(objectives[0])
    setObjectiveValidation('has-success')

    setStrategy(strategies[0])
    setStrategyOptions(strategies)
    setStrategyValidation('has-success')

    setShowMaxProfitFields(false)
    setShowACOSFields(false)
    setShowTACOSFields(false)
    setShowBudgetFields(false)
    setShowProfitFields(true)
    setShowMaxSalesFields(true)

    setAdsOn(true)

    if (prioritySliderRef.current && prioritySliderRef.current.noUiSlider) {
      prioritySliderRef.current.noUiSlider.set(recommendedPriority)
    }
  }

  const recommendedClick = (e) => {
    e.preventDefault()
    const rObj = 'maximize_sales'
    const rStrat = 'maximum_acos' // 'minimum_net_profit'

    let bACOSFields = false
    let bProfitFields = false
    let bTACOSFields = false
    if (rStrat === 'maximum_acos') {
      bACOSFields = true
      bProfitFields = false
      bTACOSFields = false
    } else if (rStrat === 'maximum_tacos') {
      bACOSFields = false
      bProfitFields = false
      bTACOSFields = true
    } else {
      bACOSFields = false
      bProfitFields = true
      bTACOSFields = false
    }

    setObjective(objectives.find((o) => o.value === rObj))
    setObjectiveValidation('has-success')

    setStrategy(strategies.find((s) => s.value === rStrat))
    setStrategyOptions(strategies)
    setStrategyValidation('has-success')

    setShowMaxProfitFields(false)
    setShowACOSFields(bACOSFields)
    setShowBudgetFields(false)
    setShowProfitFields(bProfitFields)
    setShowMaxSalesFields(true)
    setShowTACOSFields(bTACOSFields)

    setTurboOn(false)

    if (bACOSFields) {
      setTargetACOS(30)
      setTargetACOSValidation('has-success')
    } else {
      if (prioritySliderRef.current && prioritySliderRef.current.noUiSlider) {
        prioritySliderRef.current.noUiSlider.set(recommendedPriority)
      }
    }
  }

  const disableGoalState = async (e) => {
    if (type !== 'global') {
      setloadingDisable(true)
      e.preventDefault()
    }

    const goal_campaign_type = goal?.campaign_type
      ?.split(',')
      ?.map((t) => campaignTypeOptions.find((o) => o.value === t)) || [
      campaignTypeOptions[0],
    ]

    const goalData = {
      objective: goal.objective,
      strategy: goal.strategy,
      strategy_value: goal.strategy_value,
      type: goal.type,
      state: 'disabled',
      turbo_mode: turboOn ? 'enabled' : 'disabled',
      seller_selling_partner_id: seller.selling_partner_id,
      marketplace_marketplace_id: seller.primary_marketplace_id,
      profile_profile_id: profileId,
      use_global_public_id:
        goal.use_global_public_id == 'custom'
          ? null
          : goal.use_global_public_id,
      ...(type === 'global' ? { product_asin: goalASIN.value } : {}),
      ...(goal?.campaign_type && { campaign_type: goal_campaign_type }),
    }

    if (goal?.isBulk) {
      goalData.public_ids = products?.map((item) => item.goal_public_id) || []
    }

    try {
      let response
      if (goal?.isBulk) {
        response = await apiGoals.updateMultiple(goalData)
      } else if (publicId) {
        response = await apiGoals.updateSingle(publicId, goalData)
      }

      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/sellers/')
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        history.push('/admin/goals')
      } else {
        toast({
          variant: 'destructive',
          description: `${
            response ? response?.data?.message : 'Failed to process request.'
          }`,
        })
        // ,
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        description: error?.message,
      })
    } finally {
      if (type !== 'global') {
        setloadingDisable(false)
      }
      // remove modal if it exist
      setModalLoading(false)
      setShowAlert(false)
    }
  }

  const toogleTurboOn = () => {
    if (type === 'global' && !turboOn === false) {
      setTurboOn(!turboOn)
    } else {
      setTurboOn(!turboOn)
    }
  }

  const saveClick = async (e) => {
    e.preventDefault()
    const isGoalValid = () => {
      if (!isFreeUser || goalASINValidation === 'has-success') {
        if (objectiveValidation !== 'has-success') return false

        if (objective.value !== 'maximize_profit') {
          if (strategyValidation !== 'has-success') return false

          switch (strategy.value) {
            case 'minimum_net_profit':
              return true
            case 'maximum_acos':
              return targetACOSValidation === 'has-success'
            case 'maximum_daily_budget':
              return targetBudgetValidation === 'has-success'
            case 'maximum_tacos':
              return targetTACOSValidation === 'has-success'
            default:
              return false
          }
        }

        return true
      }

      return false
    }

    if (isGoalValid()) {
      setLoading(true)

      const goalData = {
        objective: objective.value,
        strategy: strategy?.value || null,
        strategy_value:
          strategy?.value === 'minimum_net_profit'
            ? avgProductMargin
            : strategy?.value === 'maximum_acos'
            ? targetACOS / 100
            : strategy?.value === 'maximum_daily_budget'
            ? targetBudget
            : strategy?.value === 'maximum_tacos'
            ? targetTACOS / 100
            : null,
        type: type,
        state: 'enabled',
        turbo_mode: turboOn ? 'enabled' : 'disabled',
        seller_selling_partner_id: seller.selling_partner_id,
        marketplace_marketplace_id: seller.primary_marketplace_id,
        profile_profile_id: profileId,
        use_global_public_id:
          goalType.value == 'custom' ? null : goalType?.value,
        ...(type === 'global' && { product_asin: goalASIN.value }),
        ...(useCampaignTypes && { campaign_type: campaignTypes[0].value }),
      }

      if (goal?.isBulk) {
        goalData.public_ids = products?.map((item) => item.goal_public_id) || []
      }

      try {
        let response
        if (goal?.isBulk) {
          response = await apiGoals.updateMultiple(goalData)
        } else if (publicId) {
          response = await apiGoals.updateSingle(publicId, goalData)
        } else {
          response = await apiGoals.createSingle(goalData)
        }

        if (response && response.status < 300) {
          queryClient.invalidateQueries('GET/sellers/')
          toast({
            variant: 'success',
            description: response?.data?.message,
          })
          history.push('/admin/goals')
        } else {
          toast({
            variant: 'destructive',
            title: 'Oops!',
            description: response
              ? response?.data?.message
              : 'Failed to process request.',
          })
        }
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Oops!',
          description: error?.message,
        })
      } finally {
        setLoading(false)
      }
    } else {
      if (isFreeUser && goalASINValidation === '') {
        setGoalASINValidation('has-danger')
      }
      if (objectiveValidation === '') {
        setObjectiveValidation('has-danger')
      }
      if (strategyValidation === '') {
        setStrategyValidation('has-danger')
      }
      if (targetACOSValidation === '') {
        setTargetACOSValidation('has-danger')
      }
      if (targetBudgetValidation === '') {
        setTargetBudgetValidation('has-danger')
      }
      if (targetTACOSValidation === '') {
        setTargetTACOSValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    updateROAS(targetACOS)
  }, [targetACOS])

  useEffect(() => {
    updateTROAS(targetTACOS)
  }, [targetTACOS])

  const updatePips = (value, type) => {
    switch (true) {
      case value >= 0 && value < 50:
        value = 'Profit'
        break
      case value >= 50 && value < 150:
        value = 'Sales'
        break
      case value >= 150:
        value = 'Growth'
        break
      default:
        break
    }
    return value
  }

  useEffect(() => {
    if (prioritySliderRef.current !== null) {
      Slider.create(prioritySliderRef.current, {
        range: { min: 0, '75%': 100, max: 200 },
        start: [recommendedPriority],
        connect: [true, false],
        step: 0.1,
        pips: {
          mode: 'positions',
          values: [0, 75, 100],
          density: 100,
          format: { to: updatePips },
        },
        tooltips: [false],
        format: {
          // 'to' the formatted value. Receives a number.
          to: function (value) {
            return Intl.NumberFormat('en-US', {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value / 100)
          },
          // 'from' the formatted value.
          // Receives a string, should return a number.
          from: function (value) {
            return value
          },
        },
      })
    }
  }, [])
  useEffect(() => {
    if (prioritySliderRef.current !== null) {
      prioritySliderRef.current.noUiSlider.on('update', updateProductStats)
    }
  }, [products])

  useEffect(() => {
    if (prioritySliderRef.current !== null) {
      prioritySliderRef.current.noUiSlider.set(
        meanNetProfitMarginToSliderValue(profitTarget, products)
      )
    }
  }, [profitTarget, products])

  const handleModalConfirm = () => {
    setModalLoading(true)
    disableGoalState()
  }

  const handleModalCancel = () => {
    setShowAlert(false)
  }

  const showAlertModal = (e) => {
    e.preventDefault()
    setShowAlert(true)
  }

  return (
    <>
      {showAlert && (
        <Dialog
          open={showAlert}
          onOpenChange={handleModalCancel}
          className="z-[99999]"
        >
          <DialogContent className="w-[380px] rounded-lg sm:max-w-[425px] z-[99999]">
            <DialogHeader>
              <DialogTitle className="text-destructive text-xl font-space-grotesk font-normal flex items-center gap-2">
                <AlertTriangle className="h-6 w-6" />
                Are you sure?
              </DialogTitle>
            </DialogHeader>
            <DialogDescription className="space-y-4 mb-4">
              <p className="text-sm ">
                Enabling this will immediately stop all automated activities in
                Autron.
              </p>
              <ul className="list-disc pl-5 space-y-2 ">
                <li>All AI-driven automations will be disabled</li>
                <li>Ads managed by Autron will be removed</li>
              </ul>
            </DialogDescription>
            <DialogFooter className="sm:justify-end">
              <Button
                variant="outline"
                onClick={handleModalCancel}
                className="mt-4 sm:mt-0"
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={handleModalConfirm}
                disabled={modalLoading}
              >
                {modalLoading ? (
                  <>
                    <LoadingSpinner />
                  </>
                ) : (
                  'Yes, I want to go it alone!'
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}

      {dataIsLoading ? (
        <div className="space-y-4 py-6 w-[50%]">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-1/2" />
          <div className="grid grid-cols-3 gap-4 pt-4 w-full">
            <Skeleton className="h-40 w-full" />
            <Skeleton className="h-40 w-full" />
            <Skeleton className="h-40 w-full" />
          </div>
          <div className="grid grid-cols-3 gap-4 pt-4 w-full">
            <Skeleton className="h-40 w-full" />
            <Skeleton className="h-40 w-full" />
            <Skeleton className="h-40 w-full" />
          </div>
        </div>
      ) : (
        <Card className="w-full lg:w-[700px]">
          <CardContent className="px-6 py-4">
            <div className="flex flex-col gap-4">
              {type === 'global' ? (
                <>
                  <div>
                    <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                      Global Goal
                    </CardTitle>
                    {isFreeUser ? (
                      <p className="text-sm text-muted-foreground">
                        You can assign 1 product to your global goal.{' '}
                        <Link
                          to="/admin/account/billing"
                          className="inline-block p-0 underline hover:text-primary"
                        >
                          Upgrade to add additional products and custom goals.
                        </Link>
                      </p>
                    ) : (
                      <p className="text-sm text-muted-foreground">
                        Your global goal affects{' '}
                        <strong
                          className={
                            lightMode ? 'text-muted-foreground' : 'text-primary'
                          }
                        >
                          all
                        </strong>{' '}
                        Ads managed by Autron, unless a custom product goal ise
                        set.
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <CardTitle className="text-2xl font-normal truncate font-space-grotesk">
                  Product Goal:{' '}
                  {products?.length > 0
                    ? products?.length > 1
                      ? products.map((item) => item.asin).join(', ')
                      : products[0]['asin']
                    : ''}
                </CardTitle>
              )}
              {type == 'global' && (
                <div>
                  <p
                    className="ml-auto underline cursor-pointer font-medium"
                    onClick={(e) => recommendedClick(e)}
                  >
                    Apply recommended settings
                  </p>
                </div>
              )}
              <form className="flex flex-col gap-4">
                {type !== 'global' ? (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="goalType" className="flex items-center">
                      Goal Used
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            Create a custom goal for this product or set to use
                            global goal settings.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Select
                      value={goalType?.value}
                      onValueChange={(value) => {
                        const selectedOption = goalTypeOptions.find(
                          (option) => option.value === value
                        )
                        if (selectedOption) {
                          goalTypeSelect(selectedOption)
                        }
                      }}
                    >
                      <SelectTrigger id="goalType">
                        <SelectValue placeholder="Select goal type" />
                      </SelectTrigger>
                      <SelectContent>
                        {goalTypeOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                ) : (
                  <>
                    {isFreeUser ? (
                      <div className="flex flex-col gap-1">
                        <Label htmlFor="goalASIN" className="flex items-center">
                          Assign Product ASIN
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <InfoIcon
                                  className="h-4 w-4 ml-2 cursor-pointer"
                                  strokeWidth={2.5}
                                />
                              </TooltipTrigger>
                              <TooltipContent>
                                Select the ASIN for this goal
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </Label>
                        <Select
                          disabled={goalGlobalASIN ? true : false}
                          onValueChange={(value) => {
                            const selectedOption = objectiveOptions.find(
                              (option) => option.value === value
                            )
                            inputFieldChange(
                              selectedOption,
                              setGoalASIN,
                              setGoalASINValidation,
                              'select_length',
                              1
                            )
                            objectiveSelect(value)
                          }}
                          value={goalASIN}
                        >
                          <SelectTrigger id="goalASIN">
                            <SelectValue placeholder="Select ASIN" />
                          </SelectTrigger>
                          <SelectContent>
                            {goalASINOptions.map((option) => (
                              <SelectItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    ) : null}
                  </>
                )}

                {showCustomFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="objective" className="flex items-center">
                      Objective
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-60">
                            Autron's Ad Engine optimizes performance towards
                            your chosen objective
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Select
                      value={objective?.value}
                      onValueChange={(value) => {
                        const selectedOption = objectiveOptions.find(
                          (option) => option.value === value
                        )
                        if (selectedOption) {
                          // Now pass the entire selectedOption (label and value)
                          inputFieldChange(
                            selectedOption, // Pass the selected object
                            setObjective,
                            setObjectiveValidation,
                            'select_length',
                            1
                          )
                          objectiveSelect(value) // Pass the selected object
                        }
                      }}
                    >
                      <SelectTrigger id="objective">
                        <SelectValue placeholder="Select objective" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {objectiveOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="strategy" className="flex items-center">
                      Strategy
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-60">
                            Choose a strategy that meets your operational or
                            budgetary constraints
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Select
                      value={strategy?.value}
                      onValueChange={(value) => {
                        const selectedOption = strategyOptions.find(
                          (option) => option.value === value
                        )
                        inputFieldChange(
                          selectedOption,
                          setStrategy,
                          setStrategyValidation,
                          'select_length',
                          objective.value === 'maximize_profit' ? 0 : 1
                        )
                        strategySelect(value)
                      }}
                    >
                      <SelectTrigger id="strategy">
                        <SelectValue
                          placeholder={
                            strategy ? strategy.label : 'Select strategy'
                          }
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {strategyOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}

                {showCustomFields && showACOSFields && showMaxSalesFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetACOS" className="flex items-center">
                      Target ACOS %
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className=" w-72">
                            Set your Advertising Cost of Sale (ACOS) target,
                            defined as the percent of attributed sales spent on
                            advertising
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      name="shipping_cost"
                      type="number"
                      min={minACOS}
                      max="1000"
                      step="0.01"
                      value={targetACOS}
                      placeholder={ACOSPlaceholder}
                      onChange={(e) => {
                        inputFieldChange(
                          e,
                          setTargetACOS,
                          setTargetACOSValidation,
                          'positiveNumberGTE',
                          minACOS
                        )
                      }}
                    />
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && showACOSFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetACOS" className="flex items-center">
                      ROAS
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className=" w-72">
                            Return on Advertising Spend (ROAS) is the ratio of
                            attributed sales to advertising spend i.e. the
                            inverse of ACOS
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      disabled
                      id="targetACOS"
                      name="target_roas"
                      type="text"
                      value={Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(targetROAS)}
                    />
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && showTACOSFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetTACOS" className="flex items-center">
                      Target TACOS %
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            Set your Total Advertising Cost of Sale (TACOS)
                            target, defined as the percent of total sales spent
                            on advertising
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      name="tacos_target"
                      type="number"
                      min={minTACOS}
                      max="1000"
                      step="0.01"
                      value={targetTACOS}
                      placeholder={TACOSPlaceholder}
                      onChange={(e) => {
                        inputFieldChange(
                          e,
                          setTargetTACOS,
                          setTargetTACOSValidation,
                          'positiveNumberGTE',
                          minTACOS
                        )
                      }}
                    />
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && showTACOSFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetTACOS" className="flex items-center">
                      TROAS
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            Total Return on Advertising Spend (TROAS) is the
                            ratio of total sales to advertising spend i.e. the
                            inverse of TACOS{' '}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      disabled
                      name="target_troas"
                      type="text"
                      value={Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(targetTROAS)}
                    />
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && showProfitFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetTACOS" className="flex items-center">
                      {type === 'global' ? 'Avg.' : ''} Unit Ad Spend
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            Avg. Unit Ad Spend details.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      disabled
                      name="product_ad_spend"
                      type="text"
                      value={
                        Intl.NumberFormat('en-US', {
                          style: 'percent',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(avgProductAdSpendPerc) +
                        '     ' +
                        (type === 'global' ? '(~' : '(') +
                        Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currency,
                        }).format(avgProductAdSpend) +
                        ')'
                      }
                    />
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && showProfitFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetTACOS" className="flex items-center">
                      Target {type === 'global' ? 'Avg.' : ''} Unit Net Profit
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            Target Avg. Unit Net Profit details.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      disabled
                      name="product_profit"
                      type="text"
                      value={
                        Intl.NumberFormat('en-US', {
                          style: 'percent',
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(avgProductMargin) +
                        '     ' +
                        (type === 'global' ? '(~' : '(') +
                        Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currency,
                        }).format(avgProductProfit) +
                        ')'
                      }
                    />
                  </div>
                )}

                {showCustomFields && showMaxSalesFields && showBudgetFields && (
                  <div className="flex flex-col gap-1">
                    <Label htmlFor="targetBudget" className="flex items-center">
                      Target Daily Budget
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            Target Daily Budget details.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                    <Input
                      name="shipping_cost"
                      type="number"
                      min={minBudget}
                      max="1000"
                      step="0.01"
                      value={targetBudget}
                      placeholder={budgetPlaceholder}
                      onChange={(e) => {
                        inputFieldChange(
                          e,
                          setTargetBudget,
                          setTargetBudgetValidation,
                          'positiveNumberGTE',
                          minBudget
                        )
                      }}
                    />
                  </div>
                )}

                {useCampaignTypes && (
                  <div>
                    <Label htmlFor="goalType">Campaign Types</Label>
                    <Select
                      onValueChange={(value) => {
                        const selectedOption = campaignTypeOptions.find(
                          (option) => option.value === value
                        )
                        setCampaignTypes([selectedOption]) // Update campaign types based on the selected value
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="" />
                      </SelectTrigger>
                      <SelectContent>
                        {campaignTypeOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}

                <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5 flex flex-col gap-1">
                    <Label htmlFor="turboMode" className="flex items-center">
                      Turbo Mode
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon
                              className="h-4 w-4 ml-2 cursor-pointer"
                              strokeWidth={2.5}
                            />
                          </TooltipTrigger>
                          <TooltipContent className="w-96">
                            When Turbo Mode is ON, Autron aggressively bids to
                            establish impressions and clicks for every keyword
                            or product target within its campaigns.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </Label>
                  </div>
                  <Switch
                    id="turboMode"
                    checked={turboOn}
                    onCheckedChange={() => toogleTurboOn()}
                  />
                </div>

                <div className="space-y-5">
                  <div className="flex justify-between">
                    <div className="flex justify-center items-center">
                      <Button
                        variant="default"
                        className={`min-w-[150px] ${
                          loading ? 'opacity-50 pointer-events-none' : ''
                        }`}
                        onClick={(e) => saveClick(e)}
                      >
                        {loading ? (
                          <LoadingSpinner size="sm" />
                        ) : goalGlobalPublicId ? (
                          adsOn ? (
                            'Save Goal'
                          ) : (
                            'Relaunch Autron!'
                          )
                        ) : (
                          'Launch Autron!'
                        )}
                      </Button>

                      {adsOn && (
                        <Button
                          variant={'destructive'}
                          onClick={(e) => {
                            type === 'global'
                              ? showAlertModal(e)
                              : disableGoalState(e)
                          }}
                          className="ml-2 min-w-[140px]"
                        >
                          {loadingDisable ? (
                            <LoadingSpinner size="sm" />
                          ) : type === 'global' ? (
                            'Disable Autron'
                          ) : (
                            'Disable Goal'
                          )}
                        </Button>
                      )}

                      {type !== 'global' && (
                        <Button
                          variant="outline"
                          onClick={(e) => {
                            history.push('/admin/goals')
                            setSelectedRow(null)
                            setSelectedRows([])
                          }}
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default Goal