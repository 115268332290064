import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { generateRandomString, pkceChallengeFromVerifier } from 'services/Utils'
import { getAuthToken } from 'variables/authToken'
import { CardTitle, CardDescription } from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Link } from 'lucide-react'
import { useToast } from 'shadcn-components/ui/use-toast'

const OnboardingSellerStep2 = forwardRef((props, ref) => {
  const { toast } = useToast()
  const params = new URLSearchParams(useLocation().search)
  const [status] = useState(params.get('status'))
  const [message] = useState(params.get('message'))

  const [profileId] = useState(props.profileId)
  const [region] = useState(props.region)
  const [publicId] = useState(props.publicId)
  const [connectAmazonAdvertisingButtonDisabled] = useState(
    props.profileId === '' ? false : true
  )
  const history = useHistory()

  useEffect(() => {
    if (status && message) {
      if (status === 'fail') {
        toast({ variant: 'destructive', description: message })
      }
      if (status === 'success') {
        toast({ variant: 'success', description: message })
      }
      // don't want noti to fire again incase of refresh
      params.delete('status')
      params.delete('message')
      history.replace({ search: params.toString() })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const startAmazonAdvertisingOAuth = async (region) => {
    let baseUrl
    // Use authToken as state and thus backend api auth on redirect
    const authToken = getAuthToken()

    // Inspired by https://github.com/aaronpk/pkce-vanilla-js/blob/master/index.html
    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var code_verifier = generateRandomString()
    // Hash and base64-urlencode the secret to use as the challenge
    var code_challenge = await pkceChallengeFromVerifier(code_verifier)

    // As per https://advertising.amazon.com/API/docs/en-us/setting-up/generate-api-tokens
    if (region === 'Far East') {
      baseUrl = 'https://apac.account.amazon.com/ap/oa'
    } else if (region === 'Europe') {
      baseUrl = 'https://eu.account.amazon.com/ap/oa'
    } else {
      baseUrl = 'https://www.amazon.com/ap/oa'
    }
    // Build the amazon oauth URL
    var url = new URL(baseUrl)
    url.searchParams.append(
      'client_id',
      process.env.REACT_APP_LOGIN_WITH_AMAZON_CLIENT_ID
    )
    url.searchParams.append('scope', 'advertising::campaign_management')
    url.searchParams.append('response_type', 'code')
    url.searchParams.append(
      'redirect_uri',
      [process.env.REACT_APP_BACKEND_API_BASE_URL, '/auth/amazon-ads/cb'].join(
        ''
      )
    )
    url.searchParams.append(
      'state',
      [authToken, publicId, code_verifier].join(' ')
    )
    url.searchParams.append('code_challenge', code_challenge)
    url.searchParams.append('code_challenge_method', 'S256')

    // Redirect to the amazon authorization server
    window.location = url
  }

  const isValidated = () => {
    if (profileId || status) {
      toast({
        variant: 'success',
        description: 'Successfully connected to Amazon Advertising.',
      })
      return true
    } else {
      toast({
        variant: 'destructive',
        title: 'Oops!',
        description: 'Amazon Advertising is not connected, please try again.',
      })
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
  }))

  return (
    <>
      <div className="flex flex-col gap-4 p-4">
        <CardTitle className="text-2xl text-center font-space-grotesk font-normal">
          Connect Amazon Advertising
        </CardTitle>
        <CardDescription className="text-base text-center font-normal">
          Grant Autron permission to read and modify your Amazon Advertising
          campaigns.
        </CardDescription>
        <div className="mx-auto w-full flex justify-center">
          <Button
            disabled={connectAmazonAdvertisingButtonDisabled}
            color="primary"
            onClick={(e) => startAmazonAdvertisingOAuth(region)}
          >
            {connectAmazonAdvertisingButtonDisabled ? (
              <div className="flex justify-center items-center gap-2">
                <Link className="h-3 w-3 inline" /> Amazon Advertising connected
              </div>
            ) : (
              <div>Connect Amazon Advertising</div>
            )}
          </Button>
        </div>
        <div className="w-full flex justify-center items-center">
          {connectAmazonAdvertisingButtonDisabled ? (
            <div className="justify-content-center">
              <p className="mb-0">
                <span
                  className="underline cursor-pointer"
                  onClick={(e) => startAmazonAdvertisingOAuth(region)}
                >
                  Need to reconnect?
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
})

export default OnboardingSellerStep2
