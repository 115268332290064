import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'shadcn-components/ui/button'
import { Card, CardContent } from 'shadcn-components/ui/card'
import { Input } from 'shadcn-components/ui/input'
import { Label } from 'shadcn-components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { Separator } from 'shadcn-components/ui/separator'
import {
  User,
  Mail,
  Phone,
  DollarSign,
  Briefcase,
  ShoppingCart,
  Globe2,
  Building,
  UserCheck,
} from 'lucide-react'
import {
  marketplaces,
  budgetOptions,
  categories,
  businessTypes,
  marketplaceOptions,
} from 'services/Utils'
import { generateRandomString } from 'services/Utils'
import apiLeads from 'services/api/Leads'
import { pkceChallengeFromVerifier } from 'services/Utils'
import { getMarketplaceInfo } from 'services/Utils'
import verifiedPartner from 'assets/img/amazon-ads-verified-partner.png'
import innovationFinalist from 'assets/img/2022 US Innovation Award Finalist.png'
import amazon_spn from 'assets/img/amazon-spn-2.png'
import form_submit_image from 'assets/img/form_submit_image.jpg'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { useToast } from 'shadcn-components/ui/use-toast'
import ThemeContext from 'services/providers/ThemeContext'
import { PhoneInput } from 'shadcn-components/ui/phone-input'

const PPCAudit = () => {
  const { lightMode } = useContext(ThemeContext)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    businessEmail: '',
    phoneNumber: '',
    companyName: '',
    adBudget: null,
    businessType: null,
    marketplace: null,
    category: null,
  })

  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [connected, setConnected] = useState(false)
  const [stepNumber, setStepNumber] = useState(1)
  const [marketplaceId, setMarketplaceId] = useState(null)
  const [regionCode, setRegionCode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [numberOfConfetti, setNumberOfConfetti] = useState(200)
  const { toast } = useToast()

  useEffect(() => {
    if (connected) {
      const timer = setTimeout(() => setNumberOfConfetti(0), 3000)
      return () => clearTimeout(timer)
    }
  }, [connected])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const status = queryParams.get('status')
    const message = queryParams.get('message')

    if (
      status === 'success' &&
      message === 'Successfully connected to Amazon Advertising!'
    ) {
      setLoading(false)
      setStepNumber(3)
      setConnected(true)
    } else if (status === 'fail') {
      setLoading(false)
      toast({ status: 'destructive', description: message })
    }
  }, [stepNumber])

  useEffect(() => {
    if (stepNumber === 3) {
      toast({
        variant: 'success',
        description: 'Successfully connected to Amazon Advertising!',
      })
    }
  }, [stepNumber])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSelectChange = (value, name) => {
    if (name === 'marketplace') {
      const selectedMarkeplace = marketplaces.find(
        (marketplace) => marketplace?.value.marketplace_id === value
      )
      setMarketplaceId(selectedMarkeplace?.value.marketplace_id)
      setRegionCode(selectedMarkeplace?.value.region_code)
      setFormData({
        ...formData,
        [name]: selectedMarkeplace?.value.sales_channel,
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const validatePhone = (phoneWithoutCountryCode) => {
    if (phoneWithoutCountryCode.length === 0) return
    const isValid = /^\d{9,}$/.test(phoneWithoutCountryCode)
    setValidPhoneNumber(isValid)
  }

  const handlePhoneChange = (value, country) => {
    const phoneWithoutCountryCode = value.slice(country.dialCode.length)
    setFormData({
      ...formData,
      phoneDetails: {
        countryCode: '+' + country.dialCode,
        phoneNumber: phoneWithoutCountryCode,
      },
    })
    validatePhone(phoneWithoutCountryCode)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    setStepNumber(2)
    const seller_public_id = crypto.randomUUID()
    const marketplaceRegion = getMarketplaceInfo(marketplaceId).region
    const lead = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      phone: formData.phoneNumber,
      email: formData.businessEmail,
      company_name: formData.companyName,
      segment_event: 'PPC Audit Signed Up',
      marketplace: formData.marketplace,
      business_type: formData.businessType,
      seller_public_id: seller_public_id,
      monthly_ad_budget: formData.adBudget,
      category: formData.category,
      marketplace_id: marketplaceId,
      marketplace_region: marketplaceRegion,
    }
    apiLeads.createLeads(lead).then(async (res) => {
      let baseUrl
      var code_verifier = generateRandomString()
      var code_challenge = await pkceChallengeFromVerifier(code_verifier)

      if (regionCode === 'fe') {
        baseUrl = 'https://apac.account.amazon.com/ap/oa'
      } else if (regionCode === 'eu') {
        baseUrl = 'https://eu.account.amazon.com/ap/oa'
      } else {
        baseUrl = 'https://www.amazon.com/ap/oa'
      }
      var url = new URL(baseUrl)
      url.searchParams.append(
        'client_id',
        process.env.REACT_APP_LOGIN_WITH_AMAZON_CLIENT_ID
      )
      url.searchParams.append('scope', 'advertising::campaign_management')
      url.searchParams.append('response_type', 'code')
      url.searchParams.append(
        'redirect_uri',
        [
          process.env.REACT_APP_BACKEND_API_BASE_URL,
          '/auth/amazon-ads/ppc-audit',
        ].join('')
      )
      url.searchParams.append(
        'state',
        [regionCode, seller_public_id, code_verifier].join(' ')
      )
      url.searchParams.append('code_challenge', code_challenge)
      url.searchParams.append('code_challenge_method', 'S256')

      window.location = url
    })
  }

  return (
    <>
      <div className="px-4 md:px-8 lg:px-28 py-8 md:py-20 mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="space-y-6 w-full lg:w-[44%]">
            <h2
              className={`text-3xl text-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Get your Amazon PPC Audit{' '}
              <span className="text-foreground">in three simple steps</span>
            </h2>
            <p className="text-muted-foreground font-geist font-normal tracking-normal">
              Uncover wasted ad spend and see how active your agency truly is
              with our Amazon Advertising audit tool. Identify missed keyword
              opportunities and more. It's 100% safe, secure, and free.
            </p>
            <div className="relative">
              <div className="absolute left-4 top-0 h-full w-0.5 bg-muted">
                <div
                  className="absolute left-0 top-0 w-full bg-primary transition-all duration-300 ease-in-out"
                  style={{ height: `${((stepNumber - 1) / 2) * 100}%` }}
                ></div>
              </div>
              <div className="space-y-8">
                {[1, 2, 3].map((step) => (
                  <div key={step} className="flex items-center">
                    <div
                      className={`z-10 mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full border-2 ${
                        stepNumber >= step
                          ? 'border-primary bg-primary text-primary-foreground'
                          : 'border-muted bg-background text-muted-foreground'
                      }`}
                    >
                      {step}
                    </div>
                    <p
                      className={`text-sm ${
                        stepNumber >= step
                          ? 'text-foreground'
                          : 'text-muted-foreground'
                      }`}
                    >
                      {step === 1 && 'Confirm your company information'}
                      {step === 2 &&
                        'Import data from your Amazon Advertising account'}
                      {step === 3 &&
                        'We generate your report & notify you when it is ready'}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="w-full flex flex-col gap-4 sm:flex-row">
                <div className="p-2 rounded-lg border border-foreground-muted shadow-sm flex items-center space-x-2 w-[240px]">
                  <img src={innovationFinalist} />
                </div>
                <div className="p-2 rounded-lg border border-foreground-muted shadow-sm flex items-center space-x-2 w-[240px]">
                  <img src={amazon_spn} />
                </div>
              </div>
              <div
                className={`p-2 rounded-lg border border-foreground-muted shadow-sm lg:flex gap-4 max-w-[120px] flex  ${
                  lightMode ? '' : 'bg-white'
                }`}
              >
                <img
                  src={verifiedPartner}
                  className="w-[100px] sm:w-[90px] md:w-[90px] lg:w-[120px]"
                />
              </div>
            </div>
          </div>
          <Card className="w-full lg:w-[56%]">
            <CardContent className="p-6">
              {connected ? (
                <div className="text-center space-y-4">
                  <h2 className="text-2xl font-bold">
                    Your audit will be ready in 24 hours!
                  </h2>
                  <p>
                    Your Amazon account has been connected & we are preparing
                    your report. We will <strong>notify you via email</strong>{' '}
                    when the report is ready.
                  </p>
                  <img
                    src={form_submit_image}
                    width={390}
                    height={350}
                    className="mx-auto"
                  />
                  <p>
                    While you wait you can head on to our blog, stacked with
                    resources that will help you get the most out of your Amazon
                    Advertising.
                  </p>
                  <Button
                    href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/blog'}
                  >
                    Visit our blog
                  </Button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid gap-4 sm:grid-cols-2">
                    <div className="space-y-2">
                      <Label htmlFor="firstName">First Name</Label>
                      <div className="relative">
                        <User className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="firstName"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          className="pl-8"
                          placeholder="Max"
                          required
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="lastName">Last Name</Label>
                      <div className="relative">
                        <UserCheck className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="lastName"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          className="pl-8"
                          placeholder="Robinson"
                          required
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="businessEmail">Business Email</Label>
                      <div className="relative">
                        <Mail className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="businessEmail"
                          name="businessEmail"
                          type="email"
                          value={formData.businessEmail}
                          onChange={handleInputChange}
                          className="pl-8"
                          placeholder="m@example.com"
                          required
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="phoneNumber">Phone Number</Label>
                      <PhoneInput
                        value={formData.phoneNumber}
                        onChange={(value) =>
                          handleInputChange({
                            target: { name: 'phoneNumber', value },
                          })
                        }
                        defaultCountry="US"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="companyName">Company Name</Label>
                      <div className="relative">
                        <Building className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="companyName"
                          name="companyName"
                          value={formData.companyName}
                          onChange={handleInputChange}
                          className="pl-8"
                          placeholder="Acme Inc."
                          required
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="adBudget">Monthly Ad Budget</Label>
                      <div className="relative">
                        <DollarSign className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Select
                          onValueChange={(value) =>
                            handleSelectChange(value, 'adBudget')
                          }
                          required
                        >
                          <SelectTrigger className="pl-8">
                            <SelectValue placeholder="Select budget" />
                          </SelectTrigger>
                          <SelectContent>
                            {budgetOptions.map((option) => (
                              <SelectItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="businessType">Business Type</Label>
                    <div className="relative">
                      <Briefcase className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                      <Select
                        onValueChange={(value) =>
                          handleSelectChange(value, 'businessType')
                        }
                        required
                      >
                        <SelectTrigger className="pl-8">
                          <SelectValue placeholder="Select business type" />
                        </SelectTrigger>
                        <SelectContent>
                          {businessTypes.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  <div className="grid gap-4 sm:grid-cols-2">
                    <div className="space-y-2">
                      <Label htmlFor="marketplace">
                        Select your marketplace
                      </Label>
                      <div className="relative">
                        <Globe2 className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Select
                          onValueChange={(value) =>
                            handleSelectChange(value, 'marketplace')
                          }
                          required
                        >
                          <SelectTrigger className="pl-8">
                            <SelectValue placeholder="Select marketplace" />
                          </SelectTrigger>
                          <SelectContent>
                            {marketplaceOptions.map((option) => (
                              <SelectItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="category">
                        What is your main category?
                      </Label>
                      <div className="relative">
                        <ShoppingCart className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Select
                          onValueChange={(value) =>
                            handleSelectChange(value, 'category')
                          }
                          required
                        >
                          <SelectTrigger className="pl-8">
                            <SelectValue placeholder="Select category" />
                          </SelectTrigger>
                          <SelectContent>
                            {categories.map((option) => (
                              <SelectItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <Separator />
                  <p className="text-xs text-center text-muted-foreground">
                    By clicking "Connect your Amazon account" you agree to our{' '}
                    <a
                      href={
                        process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/terms'
                      }
                      className="text-primary hover:underline font-medium transition-colors"
                      target="_blank"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      href={
                        process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/privacy'
                      }
                      className="text-primary hover:underline font-medium transition-colors"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>{' '}
                    as well as to receive marketing email from Autron. You can
                    unsubscribe from these communications at any time.
                  </p>
                  <Button type="submit" className="w-full" disabled={loading}>
                    {loading ? (
                      <LoadingSpinner className="w-4 h-4" />
                    ) : (
                      'Connect your Amazon account'
                    )}
                  </Button>
                  <p className="text-xs text-center text-muted-foreground">
                    Your data is safe with us.{' '}
                    <a
                      href={
                        process.env.REACT_APP_HELP_CENTER_URL +
                        '/en/articles/8954194-how-does-autron-utilize-seller-central-data'
                      }
                      className="underline"
                      target="_blank"
                    >
                      Learn more about why we need to connect your account
                    </a>
                  </p>
                </form>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  )
}

export default PPCAudit
