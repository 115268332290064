import React, { useEffect, useState, useMemo } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useExpanded,
  useRowSelect,
} from 'react-table'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'shadcn-components/ui/pagination'
import { ChevronsLeft, ChevronsRight, Search, X } from 'lucide-react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'shadcn-components/ui/table'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { Label } from 'shadcn-components/ui/label'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'

const CustomTable = ({
  columns,
  data,
  loading,
  total,
  pageCount: controlledPageCount,
  queryParam,
  setQueryParam,
  expandRowsByDefault = false,
  onRowClick = null,
  showGlobalSearch = true,
  setSelectedRows,
  hiddenColumns = [],
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    toggleAllRowsExpanded,
    // Get the state from the instance
    state: { pageIndex, pageSize, filters, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: queryParam ? queryParam.page - 1 : 0,
        pageSize: queryParam ? queryParam.per_page : total,
        hiddenColumns,
      },
      manualFilters: true, // import to set these 4 to true if you server-side sorting/filtering/pagination
      manualGlobalFilter: true,
      manualSortBy: true,
      manualPagination: true,
      autoResetPage: false, // see https://react-table.tanstack.com/docs/api/usePagination#table-options
      pageCount: controlledPageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  useMemo(
    () => toggleAllRowsExpanded(expandRowsByDefault),
    [toggleAllRowsExpanded, expandRowsByDefault]
  )

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    // 'filter[asin][eq]': 'B07QDQDLZF',
    // 'filter[product_name][ilike]': '%cutlery%',
    const filter = []

    if (globalFilter) {
      const gfilter_key = 'gfilter['
        .concat(
          columns
            .filter((column) => Number.isFinite(column.gfilter))
            .sort(function (a, b) {
              return a.gfilter - b.gfilter
            })
            .map((col) => col.accessor)
            .toString()
        )
        .concat('][ilike]')
      var gfilter = [{ [gfilter_key]: '%'.concat(globalFilter).concat('%') }]
    }

    if (sortBy.length > 0) {
      var sorts = sortBy.map((field) => {
        let key = 'sort['.concat(field.id).concat(']')
        return { [key]: field.desc ? 'desc' : 'asc' }
      })
    }

    if (setQueryParam) {
      setQueryParam(
        Object.assign(
          {},
          {
            page: pageIndex + 1, // +1 due to flask being 1 indexed
            per_page: pageSize,
          },
          ...filter,
          ...(gfilter ? gfilter : []),
          ...(sorts ? sorts : [])
        )
      )
    }
  }, [
    columns,
    pageIndex,
    pageSize,
    filters,
    globalFilter,
    sortBy,
    setQueryParam,
  ])

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map((row) => row.original))
    }
  }, [selectedFlatRows, setSelectedRows])

  // Define a default UI for filtering
  function GlobalFilter({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter)
    const [searchFocus, setSearchFocus] = useState(false)
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined)
    }, 500)

    return (
      <div className="w-full max-w-md">
        <div
          className="flex items-center border rounded-md px-3 transition-shadow duration-200 focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2"
          cmdk-input-wrapper=""
        >
          <Search className="mr-2 h-4 w-4 shrink-0 text-muted-foreground" />
          <input
            type="text"
            placeholder="Search..."
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value)
              onChange(e.target.value)
            }}
            className="flex h-10 w-full bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 border-0 focus:ring-0 focus:ring-offset-0"
            style={{ boxShadow: 'none' }}
          />
          {value && (
            <button
              onClick={() => {
                setValue('')
                onChange('')
              }}
              className="focus:outline-none"
              aria-label="Clear search"
            >
              <X className="h-4 w-4 text-muted-foreground hover:text-foreground transition-colors duration-200" />
            </button>
          )}
        </div>
      </div>
    )
  }

  const results = () => {
    let start = (pageIndex + 1) * pageSize - (pageSize - 1)
    let finish = Math.min((pageIndex + 1) * pageSize, total)
    return (
      (finish === 0 ? '0' : start + (start === finish ? '' : '-' + finish)) +
      ' of ' +
      total +
      ' results'
    )
  }

  const disabledClasses =
    'opacity-50 cursor-not-allowed pointer-events-none border border-input'
  const activeClasses = 'cursor-pointer font-semibold border border-input'
  return (
    <>
      <div className="space-y-4 sm:mt-0 font-geist">
        {queryParam && showGlobalSearch && (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <div className="rounded-md">
          <Table {...getTableProps()}>
            <TableHeader className="font-geist font-regular tracking-normal">
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHead
                      {...(column.sortable
                        ? column.getHeaderProps(column.getSortByToggleProps())
                        : column.getHeaderProps())}
                    >
                      {column.render('Header')}
                      {column.sortable && column.canSort && (
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-4 w-4 ml-2 inline"
                          >
                            <path
                              d="m21 16-4 4-4-4"
                              className={
                                column.isSorted && column.isSortedDesc
                                  ? 'stroke-primary'
                                  : 'stroke-muted-foreground'
                              }
                              strokeWidth={
                                column.isSorted && column.isSortedDesc
                                  ? '2.8'
                                  : '2'
                              }
                            />
                            <path
                              d="M17 20V4"
                              className={
                                column.isSorted && column.isSortedDesc
                                  ? 'stroke-primary'
                                  : 'stroke-muted-foreground'
                              }
                              strokeWidth={
                                column.isSorted && column.isSortedDesc
                                  ? '2.8'
                                  : '2'
                              }
                            />
                            <path
                              d="m3 8 4-4 4 4"
                              className={
                                column.isSorted && !column.isSortedDesc
                                  ? 'stroke-primary'
                                  : 'stroke-muted-foreground'
                              }
                              strokeWidth={
                                column.isSorted && !column.isSortedDesc
                                  ? '2.8'
                                  : '2'
                              }
                            />
                            <path
                              d="M7 4v16"
                              className={
                                column.isSorted && !column.isSortedDesc
                                  ? 'stroke-primary'
                                  : 'stroke-muted-foreground'
                              }
                              strokeWidth={
                                column.isSorted && !column.isSortedDesc
                                  ? '2.8'
                                  : '2'
                              }
                            />
                          </svg>
                        </span>
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody
              {...getTableBodyProps()}
              className="font-geist font-regular tracking-normal"
            >
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <TableRow
                    {...row.getRowProps()}
                    onClick={(e) => (onRowClick ? onRowClick(row, e) : {})}
                    className={
                      onRowClick
                        ? 'cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-900'
                        : ''
                    }
                  >
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        {queryParam && (
          <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-sm text-muted-foreground flex gap-2 items-center justify-center">
                {loading ? (
                  <>
                    <LoadingSpinner className="w-4 h-4" />
                    <span>Loading...</span>
                  </>
                ) : (
                  <p className="text-center text-wrap sm:text-nowrap">
                    {results()}
                  </p>
                )}
              </p>
              <div className="flex items-center sm:hidden gap-2">
                <Label htmlFor="per-page" className="text-sm text-nowrap">
                  Rows per page:
                </Label>
                <Select
                  value={pageSize.toString()}
                  onValueChange={(value) => setPageSize(Number(value))}
                >
                  <SelectTrigger className="w-[70px]">
                    <SelectValue placeholder={pageSize} />
                  </SelectTrigger>
                  <SelectContent>
                    {![10, 25, 50, 100, 500].includes(pageSize) && (
                      <SelectItem key={pageSize} value={pageSize.toString()}>
                        {pageSize}
                      </SelectItem>
                    )}
                    {[10, 25, 50, 100, 500].map((size) => (
                      <SelectItem key={size} value={size.toString()}>
                        {size}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="flex items-center gap-10">
              <div className="hidden sm:flex items-center gap-2">
                <Label htmlFor="per-page" className="text-sm text-nowrap">
                  Rows per page:
                </Label>
                <Select
                  value={pageSize.toString()}
                  onValueChange={(value) => setPageSize(Number(value))}
                >
                  <SelectTrigger className="w-[70px]">
                    <SelectValue placeholder={pageSize} />
                  </SelectTrigger>
                  <SelectContent>
                    {![10, 25, 50, 100, 500].includes(pageSize) && (
                      <SelectItem key={pageSize} value={pageSize.toString()}>
                        {pageSize}
                      </SelectItem>
                    )}
                    {[10, 25, 50, 100, 500].map((size) => (
                      <SelectItem key={size} value={size.toString()}>
                        {size}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => gotoPage(0)}
                      aria-disabled={!canPreviousPage}
                      className={
                        !canPreviousPage ? disabledClasses : activeClasses
                      }
                    >
                      <ChevronsLeft className="h-4 w-4" />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={previousPage}
                      className={
                        !canPreviousPage ? disabledClasses : activeClasses
                      }
                      aria-disabled={!canPreviousPage}
                    />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationNext
                      onClick={nextPage}
                      className={!canNextPage ? disabledClasses : activeClasses}
                      aria-disabled={!canNextPage}
                    />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => gotoPage(pageCount - 1)}
                      className={!canNextPage ? disabledClasses : activeClasses}
                      aria-disabled={!canNextPage}
                    >
                      <ChevronsRight className="h-4 w-4" />
                    </PaginationLink>
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CustomTable
