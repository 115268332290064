import React, { useState } from 'react'
import {
  CardTitle,
} from 'shadcn-components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'shadcn-components/ui/tabs'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from 'shadcn-components/ui/breadcrumb'

import ProductCosts from './ProductCosts'
import ProductTargeting from './ProductTargeting'
import { ChevronRight } from 'lucide-react'

const Product = ({ product }) => {
  const [horizontalTabs, setHorizontalTabs] = useState('product_costs')

  return (
    <>
      <div className="space-y-4 w-full">
        <Breadcrumb className="flex gap-2">
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/admin/products"
              className="text-muted-foreground"
            >
              Products
            </BreadcrumbLink>
          </BreadcrumbItem>
          <ChevronRight className="h-4 w-4 translate-y-1 opacity-50" />
          <BreadcrumbItem>
            <BreadcrumbPage>{product.asin}</BreadcrumbPage>
          </BreadcrumbItem>
          <ChevronRight className="h-4 w-4 translate-y-1 opacity-50" />
          <BreadcrumbItem>
            <BreadcrumbPage>{product.sku}</BreadcrumbPage>
          </BreadcrumbItem>
        </Breadcrumb>
        <CardTitle className="text-2xl font-space-grotesk font-normal mt-2">
          {product.product_name.substring(0, 100) + '...'}
        </CardTitle>

        <Tabs
          value={horizontalTabs}
          onValueChange={setHorizontalTabs}
          className="w-full"
        >
          <TabsList>
            <TabsTrigger value="product_costs">Unit Costs</TabsTrigger>
            <TabsTrigger value="ad_targeting">Ad Targeting</TabsTrigger>
          </TabsList>
          <TabsContent value="product_costs" className="w-full">
            <ProductCosts product={product} />
          </TabsContent>
          <TabsContent value="ad_targeting">
            <ProductTargeting product={product} />
          </TabsContent>
        </Tabs>
      </div>
    </>
  )
}

export default Product
