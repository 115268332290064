import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from 'shadcn-components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import { Label } from 'shadcn-components/ui/label'
import UploadThumbnailGallery from 'components/CustomUpload/UploadThumbnailGallery'
import { inputFieldChange } from 'services/Utils'
import { InfoIcon } from 'lucide-react'
import { Skeleton } from 'shadcn-components/ui/skeleton'

const BrandCreatives = ({
  brands,
  brandUpdate,
  uploadingLogo,
  updatingLogo,
}) => {
  const [brand, setBrand] = useState({ value: '', label: '' })
  const [brandValidation, setBrandValidation] = useState('')
  const [brandFocus, setBrandFocus] = useState(false)
  const [logos, setLogos] = useState({})

  const handleBrandChange = (selectedBrand) => {
    const brand = brands.find((b) => b.brand_entity_id === selectedBrand.value)
    setLogos(
      brand?.store_assets
        .filter((sa) => sa.media_type === 'brandLogo')
        .map((logo) => ({
          action: 'update_brand_logo',
          brand_entity_id: logo.brand_brand_entity_id,
          url: logo.url,
          picked: brand.logo_url === logo.url,
        }))
    )
  }

  useEffect(() => {
    if (brands?.length > 0) {
      const b = {
        value: brands[0]?.brand_entity_id,
        label: brands[0]?.brand_registry_name,
      }
      setBrand(b)
      setBrandValidation(b ? 'has-success' : 'has-danger')
      handleBrandChange(b)
    }
  }, [brands])

  const onPick = (asset) => {
    brandUpdate(asset)
  }

  const onUpload = (file) => {
    brandUpdate({
      action: 'upload_brand_logo',
      brand_entity_id: brand.value,
      file: file,
    })
  }
  return (
    <>
      {!brand.value ? (
        <>
          <Card className="overflow-visible h-auto w-full lg:w-[700px]">
            <CardContent className="pt-6 overflow-visible">
              <div className="space-y-4 py-6">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-4 w-1/2" />
                <div className="grid grid-cols-3 gap-4 pt-4 w-full">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
                <div className="grid grid-cols-3 gap-4 pt-4 w-full">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Card className="w-full lg:w-[700px]">
            <CardContent className="px-6 py-4 pb-8">
              <div className="flex flex-col gap-4">
                <div>
                  <CardTitle className="text-2xl font-space-grotesk font-normal tracking-normal">
                    Branding
                  </CardTitle>
                  <CardDescription className="font-normal">
                    Select a registered brand and add your awesome logo.
                  </CardDescription>
                </div>
                <form>
                  <div className="space-y-4">
                    <div className="flex flex-col gap-1">
                      <Label
                        htmlFor="brand-select"
                        className="flex items-center"
                      >
                        Brand
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <InfoIcon
                                className="h-4 w-4 ml-2 cursor-pointer"
                                strokeWidth={2.5}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              Your brand must be enrolled in Amazon Brand
                              Registry for it to appear here.
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Label>
                      <Select
                        onValueChange={(value) => {
                          const selectedBrandOption = brands.find(
                            (b) => b?.brand_entity_id === value
                          )
                          const selectedOption = {
                            value: selectedBrandOption?.brand_entity_id,
                            label: selectedBrandOption?.brand_registry_name,
                          }
                          inputFieldChange(
                            selectedOption,
                            setBrand,
                            setBrandValidation,
                            'select_length',
                            1
                          )
                          handleBrandChange(selectedOption)
                        }}
                        value={brand?.value}
                      >
                        <SelectTrigger id="brand-select" className="w-full">
                          <SelectValue placeholder="Select a brand" />
                        </SelectTrigger>
                        <SelectContent>
                          {brands?.map((b) => (
                            <SelectItem
                              key={b?.brand_entity_id}
                              value={b?.brand_entity_id}
                            >
                              {b?.brand_registry_name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    {brand?.value && (
                      <div>
                        <Label
                          htmlFor="logo-upload"
                          className="flex items-center"
                        >
                          Logo
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <InfoIcon
                                  className="h-4 w-4 ml-2 cursor-pointer"
                                  strokeWidth={2.5}
                                />
                              </TooltipTrigger>
                              <TooltipContent>
                                <strong>Logo requirements</strong>
                                <br />
                                - Dimensions are at least 400 x 400 px
                                <br />
                                - File size is smaller than 1MB
                                <br />
                                - File format is PNG, JPEG, or GIF
                                <br />- Logo needs to fill the image or have a
                                white or transparent background
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </Label>
                        <div className="mt-2">
                          <UploadThumbnailGallery
                            assets={logos}
                            assetType={'image'}
                            onPick={onPick}
                            onUpload={onUpload}
                            uploading={uploadingLogo}
                            updating={updatingLogo}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

export default BrandCreatives
