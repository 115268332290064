import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'partners'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiPartners = new ApiCore({
  url: url,
})

apiPartners.generatePartnerLead = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/lead`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

export default apiPartners
