import { CheckIcon, ChevronsUpDown, Search } from 'lucide-react'
import React, { useState, useMemo, useCallback, forwardRef } from 'react'
import * as RPNInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

import { Button } from 'shadcn-components/ui/button'
import { Input } from 'shadcn-components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'shadcn-components/ui/popover'
import { ScrollArea } from 'shadcn-components/ui/scroll-area'

import { cn } from 'services/Utils'

const PhoneInput = forwardRef(
  ({ className, onChange, defaultCountry, ...props }, ref) => {
    return (
      <RPNInput.default
        ref={ref}
        className={cn('flex', className)}
        flagComponent={FlagComponent}
        countrySelectComponent={CountrySelect}
        inputComponent={InputComponent}
        smartCaret={false}
        onChange={(value) => onChange?.(value || '')}
        defaultCountry={defaultCountry || 'US'}
        {...props}
      />
    )
  }
)
PhoneInput.displayName = 'PhoneInput'

const InputComponent = forwardRef(({ className, ...props }, ref) => (
  <Input
    className={cn('rounded-e-lg rounded-s-none', className)}
    {...props}
    ref={ref}
  />
))
InputComponent.displayName = 'InputComponent'

const CountrySelect = ({ disabled, value, onChange, options }) => {
  const [open, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const filteredOptions = useMemo(() => {
    return options
      .filter((option) => option.value !== undefined) // Filter out International option
      .filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
  }, [options, searchQuery])

  const handleSelect = useCallback(
    (country) => {
      onChange(country)
      setOpen(false)
    },
    [onChange]
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant={'outline'}
          className={cn('flex gap-1 rounded-e-none rounded-s-lg px-3')}
          disabled={disabled}
        >
          <FlagComponent country={value} countryName={value} />
          <ChevronsUpDown
            className={cn(
              '-mr-2 h-4 w-4 opacity-50',
              disabled ? 'hidden' : 'opacity-100'
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <div className="flex items-center border-b px-3 py-2">
          <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
          <input
            className="flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
            placeholder="Search country..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <ScrollArea className="h-[300px]">
          {filteredOptions.length === 0 ? (
            <div className="py-6 text-center text-sm">No country found.</div>
          ) : (
            filteredOptions.map((option) => (
              <div
                key={`country-select-${option.value}`}
                className="flex cursor-pointer items-center justify-between px-3 py-2 hover:bg-accent"
                onClick={(e) => {
                  e.preventDefault()
                  handleSelect(option.value)
                }}
              >
                <div className="flex items-center gap-2">
                  <FlagComponent
                    country={option.value}
                    countryName={option.label}
                  />
                  <span className="flex-1 text-sm">{option.label}</span>
                </div>
                <div className="flex items-center gap-2">
                  {option.value && (
                    <span className="text-muted-foreground text-sm">
                      {`+${RPNInput.getCountryCallingCode(option.value)}`}
                    </span>
                  )}
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      option.value === value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </div>
              </div>
            ))
          )}
        </ScrollArea>
      </PopoverContent>
    </Popover>
  )
}

const FlagComponent = ({ country, countryName }) => {
  const Flag = flags[country]

  return (
    <span className="bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm">
      {Flag && <Flag title={countryName} />}
    </span>
  )
}
FlagComponent.displayName = 'FlagComponent'

export { PhoneInput }