import React, { useContext } from 'react'
import { Card, CardContent } from 'shadcn-components/ui/card'
import ThemeContext from 'services/providers/ThemeContext'


const getYear = () => {
  return new Date().getFullYear()
}

const MainFooter = (props) => {
  const { lightMode } = useContext(ThemeContext)
  return (
    <>
      <footer
        className={`bg-background pt-6 pb-10 px-6 md:px-12 lg:px-52 ${
          lightMode === true ? 'bg-white' : 'bg-black'
        }`}
      >
        <div className="container px-4">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-10">
            <FooterSection title="Company">
              <FooterLink
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/about'}
              >
                About Us
              </FooterLink>
            </FooterSection>

            <FooterSection title="Resources">
              <FooterLink href={process.env.REACT_APP_HELP_CENTER_URL} external>
                Help Center
              </FooterLink>
              <FooterLink
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/privacy'}
              >
                Privacy Policy
              </FooterLink>
              <FooterLink
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/terms'}
              >
                Terms of Service
              </FooterLink>
            </FooterSection>

            <FooterSection title="Follow Us">
              <FooterLink href="https://www.facebook.com/autronio" external>
                Facebook
              </FooterLink>
              <FooterLink href="https://twitter.com/autronio" external>
                Twitter
              </FooterLink>
              <FooterLink
                href="https://www.linkedin.com/company/autron"
                external
              >
                LinkedIn
              </FooterLink>
            </FooterSection>

            <FooterSection title="Contact">
              <FooterLink
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                external
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </FooterLink>
              <FooterLink href="#">
                100 California St
                <br />
                San Francisco, CA
                <br />
                94111
              </FooterLink>
              <FooterLink href="#">© {getYear()} Autron, Inc.</FooterLink>
            </FooterSection>
          </div>
        </div>
      </footer>
    </>
  )
}

export default MainFooter

function FooterSection({ title, children }) {
  return (
    <Card className="bg-transparent shadow-none border-none md:flex md:justify-start sm:flex sm:justify-start">
      <CardContent className="p-0 border-red-600 space-y-4 sm:ml-20 lg:ml-0">
        <h6 className="font-medium mb-2 text-foreground">{title}</h6>
        <nav className="flex flex-col items-start space-y-4">{children}</nav>
      </CardContent>
    </Card>
  )
}

function FooterLink({ href, children, external = false }) {
  const linkProps = external
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {}

  return (
    <a
      href={href}
      className="text-muted-foreground hover:text-foreground transition-colors"
      {...linkProps}
    >
      {children}
    </a>
  )
}
