import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'brands'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiBrands = new ApiCore({
  url: url,
  post: true,
})

apiBrands.logoUpload = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/logo-upload`, data, {
      withCredentials: withCredentials,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(handleResponse)
    .catch(handleError)
}

apiBrands.mediaLocation = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/media-location`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiBrands.mediaUploadComplete = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/media-upload-complete`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiBrands.media = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/media`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

export default apiBrands
