import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import apiAuth from 'services/api/Auth.js'
import { useQuery } from 'react-query'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { copyTextToClipboard, managedSellerInviteLink } from 'services/Utils'
import { ArrowRight, Clipboard, RefreshCw } from 'lucide-react'
import { Skeleton } from 'shadcn-components/ui/skeleton'

const Onboarding = () => {
  const [primarySeller, setPrimarySeller] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const { data: user } = useQuery('GET/auth/user', () => apiAuth.user(), {
    onSuccess: (user) => {
      setPrimarySeller(user.primary_seller)
      setIsLoading(false)
    },
  })

  const connectAccountClick = async (public_id = null) => {
    if (public_id) {
      history.push('/admin/onboarding/seller/' + public_id)
    } else {
      history.push('/admin/onboarding/seller/')
    }
  }
  return (
    <div className="w-full mx-auto min-h-[70vh] flex flex-col gap-12 justify-center items-center">
      {isLoading ? (
        <Card className="w-full max-w-md mx-auto overflow-hidden shadow-lg">
          <CardHeader className="relative z-10">
            <Skeleton className="h-9 w-3/4 mx-auto" />
          </CardHeader>
          <CardContent className="space-y-6">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-5/6" />
            <Skeleton className="h-4 w-4/6" />
            <Skeleton className="h-10 w-full" />
          </CardContent>
          <CardFooter className="flex flex-col items-center space-y-2 ">
            <Skeleton className="h-4 w-3/4" />
            <Skeleton className="h-10 w-1/2" />
          </CardFooter>
        </Card>
      ) : (
        <>
          <Card className="w-full max-w-md mx-auto overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl">
            {primarySeller?.profiles?.length > 0 ? (
              primarySeller?.ingest_completed_at ? (
                primarySeller?.company.managing_company?.public_id &&
                primarySeller?.company.managing_company?.public_id !==
                  user.company.public_id ? (
                  <CardContent className="relative z-10 space-y-6 pt-6">
                    <div className="space-y-4">
                      <p className="text-center">
                        {user?.firstname}, this account is managed by{' '}
                        <span className="font-bold text-primary">
                          {primarySeller?.company.managing_company?.name}
                        </span>
                        .
                      </p>
                      <p className="text-center text-sm text-gray-600">
                        If you would like to change this,{' '}
                        <Link
                          to={{
                            pathname: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Seller: ${primarySeller.public_id} - External Account Management`,
                          }}
                          className="text-primary underline"
                          target="_blank"
                        >
                          Contact Us
                        </Link>
                        .
                      </p>
                      <p className="text-center text-sm text-gray-600">
                        Or, click below to add/edit Amazon accounts under{' '}
                        {primarySeller?.company.managing_company?.name}{' '}
                        management.
                      </p>
                      <div className="space-y-2">
                        <Button
                          className="w-full group"
                          onClick={() => connectAccountClick()}
                        >
                          Add New Amazon Account
                          <ArrowRight className="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
                        </Button>
                        <Button
                          variant="outline"
                          className="w-full"
                          onClick={() =>
                            connectAccountClick(primarySeller.public_id)
                          }
                        >
                          Edit {primarySeller?.company.name} Connection
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                ) : null
              ) : (
                <CardContent>
                  <CardHeader className="relative z-10">
                    <CardTitle className="text-3xl font-normal font-space-grotesk text-center">
                      Syncing Your Data <br />& Training AI
                    </CardTitle>
                  </CardHeader>

                  <div className="space-y-4 text-center">
                    <RefreshCw className="w-16 h-16 stroke-1 mx-auto text-primary animate-spin" />
                    <p className="text-lg font-medium">
                      {user?.firstname}, we're syncing your Amazon account.
                    </p>
                    <p className="pb-4">
                      This may{' '}
                      <span className="font-medium">take up to 24 hours</span>,
                      and we’ll send you an email as soon as we're ready.
                    </p>
                    <Link
                      to={{
                        pathname: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Seller: ${primarySeller.public_id} - account sync not complete after 24 hours`,
                      }}
                      className="text-primary underline"
                      target="_blank"
                    >
                      Haven’t received an email?
                    </Link>
                  </div>
                </CardContent>
              )
            ) : (
              <CardContent>
                <CardHeader className="relative z-10">
                  <CardTitle className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    Hello
                  </CardTitle>
                </CardHeader>
                <div className="space-y-6 text-center">
                  <p className="font-semibold">Welcome {user?.firstname}!</p>
                  <p className="text-sm text-gray-600">
                    Get started by connecting an Amazon account
                  </p>
                  <Button
                    onClick={() => connectAccountClick()}
                    className="w-full group"
                  >
                    Connect Amazon Account
                    <ArrowRight className="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
                  </Button>
                </div>
              </CardContent>
            )}
          </Card>

          {!primarySeller?.profiles?.length > 0 && (
            <CardFooter className="flex flex-col items-center space-y-2 relative z-10 pt-6">
              <p className="text-sm text-gray-600">
                Looking to manage someone else's Amazon Ad account?
              </p>
              <Button
                variant="outline"
                onClick={() =>
                  copyTextToClipboard(
                    managedSellerInviteLink(
                      user?.company?.public_id,
                      user?.company?.name
                    )
                  )
                }
                className="group"
              >
                <Clipboard className="w-4 h-4 mr-2 transition-transform group-hover:scale-110" />
                <span>Copy Invite Link</span>
              </Button>
            </CardFooter>
          )}
        </>
      )}
    </div>
  )
}

export default Onboarding
