import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from 'shadcn-components/ui/card'
import { Label } from 'shadcn-components/ui/label'
import { Input } from 'shadcn-components/ui/input'
import { Button } from 'shadcn-components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import { Calendar } from 'shadcn-components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'shadcn-components/ui/popover'
import moment from 'moment-timezone' // import required for "react-datetime" to work
import { inputFieldChange } from 'services/Utils'
import { useQueryClient } from 'react-query'
import apiProducts from 'services/api/Product'
import { CalendarIcon, InfoIcon } from 'lucide-react'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { useToast } from 'shadcn-components/ui/use-toast'

const ProductCost = ({
  productCost,
  productPublicId,
  currency,
  timezone,
  setShowComponent,
}) => {
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)

  const [publicId, setPublicId] = useState(productCost.public_id)
  const [effectiveFrom, setEffectiveFrom] = useState(productCost.effective_from)
  const [cog, setCog] = useState(productCost.cost_of_goods_per_unit)
  const [shippingCost, setShippingCost] = useState(
    productCost.shipping_cost_per_unit
  )
  const [miscCost, setMiscCost] = useState(productCost.misc_cost_per_unit)
  const [totalCost, setTotalCost] = useState(productCost.total_cost_per_unit)

  const [effectiveFromValidation, setEffectiveFromValidation] = useState()
  const [cogValidation, setCogValidation] = useState()
  const [shippingCostValidation, setShippingCostValidation] = useState()
  const [miscCostValidation, setMiscCostValidation] = useState()

  const queryClient = useQueryClient()

  useEffect(() => {
    //console.log(effectiveFrom)

    setPublicId(productCost.public_id)
    setEffectiveFrom(productCost.effective_from)
    setCog(productCost.cost_of_goods_per_unit)
    setShippingCost(productCost.shipping_cost_per_unit)
    setMiscCost(productCost.misc_cost_per_unit)

    setEffectiveFromValidation('has-success')
    setCogValidation(productCost.cost_of_goods_per_unit ? 'has-success' : '')
    setShippingCostValidation(
      productCost.shipping_cost_per_unit ? 'has-success' : ''
    )
    setMiscCostValidation(productCost.misc_cost_per_unit ? 'has-success' : '')
  }, [productCost])

  const costPlaceholder = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(0)

  const saveClick = async (e) => {
    if (
      effectiveFromValidation === 'has-success' &&
      cogValidation === 'has-success' &&
      shippingCostValidation === 'has-success' &&
      (miscCostValidation === 'has-success' || miscCostValidation === '')
    ) {
      setLoading(true)
      const costs = {
        cost_of_goods_per_unit: formatCurrency(cog),
        shipping_cost_per_unit: formatCurrency(shippingCost),
        misc_cost_per_unit: formatCurrency(miscCost),
        total_cost_per_unit: totalCost,
        effective_from: effectiveFrom,
      }
      // create or update product cost via api
      // const response = ''
      const response = await (publicId
        ? apiProducts.updateSingleCost(productPublicId, publicId, costs)
        : apiProducts.createSingleCost(productPublicId, costs)
      ).then((res) => {
        return res
      })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/sellers/') // overkill invalidating effectively with wildcard?
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        setShowComponent(false)
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
      setLoading(false)
    } else {
      if (effectiveFromValidation === '') {
        setEffectiveFromValidation('has-danger')
      }
      if (cogValidation === '') {
        setCogValidation('has-danger')
      }
      if (shippingCostValidation === '') {
        setShippingCostValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  const formatCurrency = (num) => {
    return Math.round(((Number(num) || 0) + Number.EPSILON) * 100) / 100
  }

  useEffect(() => {
    let totalCost = [
      formatCurrency(cog),
      formatCurrency(shippingCost),
      formatCurrency(miscCost),
    ].reduce((a, b) => a + b, 0)
    setTotalCost(totalCost ? totalCost : 0)
  }, [currency, cog, shippingCost, miscCost])

  return (
    <>
      <Card className="w-full lg:w-[700px]">
        <CardContent className="p-6 space-y-4">
          <CardHeader className="pt-0 pl-0 pb-0">
            <h4 className="text-2xl font-space-grotesk font-normal">
              Unit Costs
            </h4>
          </CardHeader>
          <CardContent className="pl-0 pr-0">
            <div className="space-y-4">
              <div className="flex flex-col gap-2 items-start">
                <Label htmlFor="effective-from" className="flex gap-2">
                  Effective From
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <InfoIcon className="w-4 h-4" />
                      </TooltipTrigger>
                      <TooltipContent>
                        Products sold on or after this date will use this cost
                        in Ad optimizations (Date is in marketplace default
                        timezone: {timezone}).
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <div className="w-full">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={'outline'}
                        className={`w-full justify-start text-left font-normal ${
                          !effectiveFrom && 'text-muted-foreground'
                        }`}
                      >
                        {effectiveFrom ? (
                          <>{moment(effectiveFrom).format('MMM D, YYYY')}</>
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={effectiveFrom}
                        onSelect={(date) => {
                          setEffectiveFrom(date)
                          setEffectiveFromValidation(
                            date ? 'has-success' : 'has-danger'
                          )
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                  {effectiveFromValidation === 'has-danger' && (
                    <p className="text-sm text-red-400 mt-1">
                      This field is required.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 items-start">
                <Label htmlFor="cog" className="text-right">
                  Cost of Goods
                </Label>
                <div className="w-full">
                  <Input
                    name="cog"
                    type="number"
                    min="0.01"
                    step="0.01"
                    value={cog}
                    placeholder={costPlaceholder}
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setCog,
                        setCogValidation,
                        'positiveNumber'
                      )
                    }
                  />
                  {cogValidation === 'has-danger' && (
                    <p className="text-sm text-red-400 mt-1">
                      This field is required and must be a positive number.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 items-start">
                <Label htmlFor="shipping-cost" className="text-right">
                  Inbound Shipping Cost
                </Label>
                <div className="w-full">
                  <Input
                    name="shipping_cost"
                    type="number"
                    min="0.01"
                    step="0.01"
                    value={shippingCost}
                    placeholder={costPlaceholder}
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setShippingCost,
                        setShippingCostValidation,
                        'positiveNumber'
                      )
                    }
                  />
                  {shippingCostValidation === 'has-danger' && (
                    <p className="text-sm text-red-400 mt-1">
                      This field is required and must be a positive number.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 items-start">
                <Label htmlFor="misc-cost" className="flex gap-2">
                  Miscellaneous Cost
                  <TooltipProvider>
                    <Tooltip className="inline">
                      <TooltipTrigger asChild>
                        <InfoIcon className="w-4 h-4" />
                      </TooltipTrigger>
                      <TooltipContent>
                        Optional misc. cost for product prep, taxes or any other
                        cost you would like to expense every time this product
                        is sold.
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <div className="w-full">
                  <Input
                    name="misc_cost"
                    type="number"
                    min="0.01"
                    step="0.01"
                    value={miscCost}
                    placeholder={costPlaceholder}
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setMiscCost,
                        setMiscCostValidation,
                        'positiveNumber',
                        null,
                        false
                      )
                    }
                  />
                  {miscCostValidation === 'has-danger' && (
                    <p className="text-sm text-red-400 mt-1">
                      This field must be a positive number.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 items-start">
                <Label htmlFor="total-cost" className="text-right">
                  Total Cost
                </Label>
                <div className="w-full">
                  <Input
                    id="total-cost"
                    type="text"
                    value={new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: currency,
                    }).format(totalCost)}
                    disabled
                    className="bg-muted"
                  />
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-start gap-2 pl-0 pr-0">
            <Button onClick={saveClick} disabled={loading}>
              {loading ? (
                <LoadingSpinner className="w-4 h-4 min-w-[74px]" />
              ) : (
                'Save Costs'
              )}
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowComponent(false)}
              className="px-6"
            >
              Cancel
            </Button>
          </CardFooter>
        </CardContent>
      </Card>
    </>
  )
}

export default ProductCost
