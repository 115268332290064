import { ApiCore } from './utilities/Core'

// User API
const url = 'marketplaces'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiMarketplaces = new ApiCore({
  getAll: true,
  url: url,
})

export default apiMarketplaces
