import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from 'shadcn-components/ui/card'
import { Tabs, TabsContent } from 'shadcn-components/ui/tabs'
import { Button } from 'shadcn-components/ui/button'
import { Progress } from 'shadcn-components/ui/progress'
import { User, CheckCircle, Handshake, Link } from 'lucide-react'

import PropTypes from 'prop-types'
import classnames from 'classnames'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { withRouter } from 'react-router-dom'

const isForwardRefComponent = (component) => {
  return (
    typeof component === 'object' &&
    component['$$typeof'] === Symbol.for('react.forward_ref')
  )
}

const steps = [
  { icon: User, label: 'Seller Info' },
  { icon: Handshake, label: 'Authorize Selling Partner' },
  { icon: Link, label: 'Connect Amazon Advertising' },
  { icon: CheckCircle, label: 'Completed' },
]

class ReactWizardMod extends React.Component {
  constructor(props) {
    super(props)
    let width
    if (this.props.steps.length === 1) {
      width = 100
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = 100
        } else {
          width = 100 / 3
        }
      } else {
        if (this.props.steps.length === 2) {
          width = 100
        } else {
          width = 100 / 3
        }
      }
    }
    this.state = {
      currentStep: this.props.startStep,
      highestStep: this.props.startStep,
      color: this.props.color !== undefined ? this.props.color : 'primary',
      nextButton:
        this.props.steps.length > 1 &&
        this.props.steps.length !== this.props.startStep + 1
          ? true
          : false,
      previousButton: this.props.startStep > 0 ? true : false,
      finishButton:
        this.props.steps.length === this.props.startStep + 1 ? true : false,
      width: width,
      wizardData:
        this.props.wizardData !== undefined ? this.props.wizardData : {},
      movingTabStyle: {
        transition: 'transform 0s',
      },
      progressbarStyle: {
        width: 100 / this.props.steps.length / 2 + '%',
      },
      loading: false,
    }
    this.navigationStepChange = this.navigationStepChange.bind(this)
    // this.refreshAnimation = this.refreshAnimation.bind(this)
    this.previousButtonClick = this.previousButtonClick.bind(this)
    this.nextButtonClick = this.nextButtonClick.bind(this)
    this.finishButtonClick = this.finishButtonClick.bind(this)
  }
  componentDidMount() {
    // this.refreshAnimation(this.state.currentStep)
    // window.addEventListener('resize', this.updateWidth.bind(this))
  }
  componentWillUnmount() {
    this.isCancelled = true
    window.removeEventListener('resize', this.updateWidth)
    var id = window.setTimeout(null, 0)
    while (id--) {
      window.clearTimeout(id)
    }
  }
  // updateWidth() {
  // //   !this.isCancelled &&
  //     setTimeout(() => this.refreshAnimation(this.state.currentStep), 200)
  // }
  async navigationStepChange(key) {
    if (this.props.navSteps) {
      var validationState = true
      if (this.props.validate && key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (
            this.refs[this.props.steps[i].stepName].isValidated !== undefined &&
            (await this.refs[this.props.steps[i].stepName].isValidated()) ===
              false
          ) {
            validationState = false
            break
          }
        }
      }
      if (validationState) {
        this.setState({
          wizardData: {
            ...this.state.wizardData,
            [this.props.steps[this.state.currentStep].stepName]:
              this.refs[this.props.steps[this.state.currentStep].stepName]
                .state,
          },
          currentStep: key,
          highestStep:
            key > this.state.highestStep ? key : this.state.highestStep,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false,
        })
        // this.refreshAnimation(key)
      }
    }
  }
  async nextButtonClick() {
    this.setState({ loading: true })
    if (
      (this.props.validate &&
        ((this.refs[this.props.steps[this.state.currentStep].stepName]
          .isValidated !== undefined &&
          (await this.refs[
            this.props.steps[this.state.currentStep].stepName
          ].isValidated())) ||
          this.refs[this.props.steps[this.state.currentStep].stepName]
            .isValidated === undefined)) ||
      this.props.validate === undefined ||
      !this.props.validate
    ) {
      var key = this.state.currentStep + 1
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [this.props.steps[this.state.currentStep].stepName]:
            this.refs[this.props.steps[this.state.currentStep].stepName].state,
        },
        currentStep: key,
        highestStep:
          key > this.state.highestStep ? key : this.state.highestStep,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      })
      // this.refreshAnimation(key)
      // update the step parameter in the url to indicate the current step in the wizard
      this.props.history.push(`${this.props.location.pathname}?step=${key}`)
    }
    this.setState({ loading: false })
  }
  previousButtonClick() {
    var key = this.state.currentStep - 1
    if (key >= 0) {
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [this.props.steps[this.state.currentStep].stepName]:
            this.refs[this.props.steps[this.state.currentStep].stepName].state,
        },
        currentStep: key,
        highestStep:
          key > this.state.highestStep ? key : this.state.highestStep,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      })
      // this.refreshAnimation(key)
      // update the step parameter in the url to indicate the current step in the wizard
      this.props.history.push(`${this.props.location.pathname}?step=${key}`)
    }
  }
  async finishButtonClick() {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this.refs[this.props.steps[this.state.currentStep].stepName]
          .isValidated !== undefined &&
          (await this.refs[
            this.props.steps[this.state.currentStep].stepName
          ].isValidated())) ||
          this.refs[this.props.steps[this.state.currentStep].stepName]
            .isValidated === undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          progressbarStyle: {
            width: '100%',
          },
          wizardData: {
            ...this.state.wizardData,
            [this.props.steps[this.state.currentStep].stepName]:
              this.refs[this.props.steps[this.state.currentStep].stepName]
                .state,
          },
        },
        () => {
          this.props.finishButtonClick(this.state.wizardData)
        }
      )
    }
  }
  // refreshAnimation(index) {
  //   var total = this.props.steps.length
  //   var li_width = 100 / total

  //   var total_steps =
  //     this.props.steps !== undefined ? this.props.steps.length : 0
  //   var move_distance =
  //     this.refs.wizard !== undefined
  //       ? this.refs.navStepsLi.children[0].clientWidth / total_steps
  //       : 0
  //   var index_temp = index
  //   var vertical_level = 0

  //   var mobile_device = window.innerWidth < 600 && total > 3

  //   if (mobile_device) {
  //     move_distance = this.refs.navStepsLi.children[0].clientWidth / 2
  //     index_temp = index % 2
  //     li_width = 50
  //   }

  //   this.setState({ width: li_width + '%' })

  //   var step_width = move_distance

  //   move_distance = move_distance * index_temp

  //   if (mobile_device) {
  //     vertical_level = parseInt(index / 2)
  //     vertical_level = vertical_level * 38
  //   }

  //   var movingTabStyle = {
  //     width: step_width,
  //     transform:
  //       'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
  //     transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)',
  //   }
  //   var progressbarStyle
  //   if (index === total - 1) {
  //     progressbarStyle = {
  //       width: '100%',
  //     }
  //   } else {
  //     progressbarStyle = {
  //       width: move_distance + step_width / 2,
  //     }
  //   }

  //   this.setState({
  //     movingTabStyle: movingTabStyle,
  //     progressbarStyle: progressbarStyle,
  //   })
  // }

  renderComponent(prop) {
    const { component, stepProps, stepName } = prop
    if (typeof component === 'function' || isForwardRefComponent(component)) {
      return (
        <prop.component
          ref={stepName}
          wizardData={this.state.wizardData}
          {...stepProps}
        />
      )
    }

    return <div ref={stepName}>{component}</div>
  }

  render() {
    return (
      <>
        <div className="border-none shadow-none flex flex-col gap-6">
          <CardHeader>
            {this.props.title !== undefined ? (
              <CardTitle className="text-2xl font-space-grotesk font-medium my-4 text-center">
                {this.props.title}
              </CardTitle>
            ) : null}
          </CardHeader>
          <div className="relative">
            <Progress
              value={(this.state.currentStep / 3) * 100}
              className="h-1 absolute top-5 left-0 right-0 z-0"
            />
            <div className="flex justify-between relative z-10">
              {steps.map((step, index) => {
                const StepIcon = step.icon
                return (
                  <div key={index} className="flex flex-col items-center">
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center ${
                        index + 1 <= this.state.currentStep
                          ? 'bg-primary text-primary-foreground'
                          : this.state.currentStep === 3
                          ? 'bg-primary text-primary-foreground'
                          : 'bg-muted text-muted-foreground'
                      }`}
                    >
                      <StepIcon className="w-5 h-5" />
                    </div>
                    <span
                      className={`mt-2 text-sm text-center max-w-[100px] ${
                        index + 1 <= this.state.currentStep
                          ? 'text-primary'
                          : 'text-muted-foreground'
                      }`}
                    >
                      {step.label}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
          <Card>
            <CardContent>
              <Tabs value={this.state.currentStep}>
                {this.props.steps.map((prop, key) => {
                  return (
                    <TabsContent
                      value={key}
                      key={key}
                      className={classnames('fade', {
                        show: this.state.currentStep === key,
                      })}
                    >
                      {this.renderComponent(prop)}
                    </TabsContent>
                  )
                })}
              </Tabs>
            </CardContent>
            <CardFooter className="relative px-12">
              <div className="absolute right-12">
                {this.state.nextButton ? (
                  <Button
                    disabled={this.state.loading}
                    onClick={() => this.nextButtonClick()}
                  >
                    {this.state.loading ? (
                      <LoadingSpinner className="w-4 h-4" />
                    ) : this.props.nextButtonText !== undefined ? (
                      this.props.nextButtonText
                    ) : (
                      'Next'
                    )}
                  </Button>
                ) : null}
                {this.state.finishButton ? (
                  <Button onClick={() => this.finishButtonClick()}>
                    {this.props.finishButtonText !== undefined
                      ? this.props.finishButtonText
                      : 'Finish'}
                  </Button>
                ) : null}
              </div>
              <div className="left-0">
                <Button
                  onClick={() => this.previousButtonClick()}
                  className={`${this.state.previousButton ? '' : 'invisible'}`}
                  disabled={this.state.previousButton ? false : true}
                >
                  {this.props.previousButtonText !== undefined
                    ? this.props.previousButtonText
                    : 'Previous'}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </>
    )
  }
}

ReactWizardMod.defaultProps = {
  validate: false,
  previousButtonText: 'Previous',
  finishButtonText: 'Finish',
  nextButtonText: 'Next',
  color: 'primary',
  progressbar: false,
  startStep: 0,
}

ReactWizardMod.propTypes = {
  color: PropTypes.oneOf(['primary', 'green', 'orange', 'red', 'blue']),
  previousButtonClasses: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  headerTextCenter: PropTypes.bool,
  navSteps: PropTypes.bool,
  validate: PropTypes.bool,
  finishButtonClick: PropTypes.func,
  previousButtonText: PropTypes.node,
  finishButtonText: PropTypes.node,
  nextButtonText: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  progressbar: PropTypes.bool,
  startStep: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepIcon: PropTypes.string,
      component: PropTypes.oneOfType([
        PropTypes.func,
        function (props, key, componentName, location, propFullName) {
          if (!isForwardRefComponent(props.component)) {
            return new Error(
              `Invalid prop ${propFullName} supplied to ${componentName}. Validation failed.`
            )
          }
        },
      ]),
      stepProps: PropTypes.object,
    })
  ).isRequired,
}

export default withRouter(ReactWizardMod)