import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'goals'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiGoals = new ApiCore({
  createSingle: true,
  updateSingle: true,
  updateMultiple: true,
  url: url,
})

apiGoals.createDefaultGoals = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/default`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

export default apiGoals
