// Approach inspired by: https://dev.to/mmcshinsky/a-simple-approach-to-managing-api-calls-1lo6

import axios from 'axios'
import { getAuthToken, setAuthToken } from 'variables/authToken'
import { handleError, handleResponse } from './Response'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { QueryCache } from 'react-query'
import { useHistory } from 'react-router-dom'

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL
axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.headers.put['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.headers.delete['Content-Type'] =
  'application/json; charset=UTF-8'

const queryCache = new QueryCache()

export const setAuthorizationHeader = (withCredentials) => {
  axios.defaults.headers.common['Authorization'] = ''
  delete axios.defaults.headers.common['Authorization']
  if (withCredentials) {
    const authToken = getAuthToken()
    if (authToken) {
      axios.defaults.headers.common['Authorization'] = `${authToken}`
    }
  }
}

// Function that will be called to refresh auth_token if any api call returns a 401 error (e.g. handles when auth_token expires)
const refreshAuthToken = (failedRequest) =>
  axios
    .post('/auth/token/refresh', { skipAuthRefresh: true })
    .then((tokenRefreshResponse) => {
      // ideally if Authorization is empty, we shouldn't retry failedRequest, but doesn't look like feature available yet...
      setAuthToken(tokenRefreshResponse.data.Authorization)
      failedRequest.response.config.headers[
        'Authorization'
      ] = `${tokenRefreshResponse.data.Authorization}`
      if (tokenRefreshResponse.data.status === 'fail') {
        queryCache.clear()
      }
      return Promise.resolve()
    })
    .catch((error) => {
      //useHistory().push("/auth/login")
      //window.location.replace("/auth/login");
      return Promise.reject()
    })
// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthToken)

/** @param {string} resource */
const getAll = (resource, withCredentials = axios.defaults.withCredentials) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${resource}/`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {string} id */
const getSingle = (
  resource,
  id,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${resource}/${id}`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} data */
const createSingle = (
  resource,
  data,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${resource}/`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {string} id */
/** @param {object} data */
const updateSingle = (
  resource,
  id,
  data,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .put(`/${resource}/${id}`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {string} id */
const deleteSingle = (
  resource,
  id,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .delete(`/${resource}/${id}`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} data */
const updateMultiple = (
  resource,
  data,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .put(`/${resource}/`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} data */
const post = (
  resource,
  data,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${resource}/`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} data */
const put = (
  resource,
  data,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .put(`/${resource}/`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} data */
const patch = (
  resource,
  data,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .patch(`/${resource}/`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {string} id */
const remove = (
  resource,
  id,
  withCredentials = axios.defaults.withCredentials
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .delete(`/${resource}/`, id, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

export const apiProvider = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  updateMultiple,
  post,
  put,
  patch,
  remove,
}
