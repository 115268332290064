import React, { useContext, useEffect, useRef } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import MainNavbar from 'components/Navbars/MainNavbar.js'
import MainFooter from 'components/Footer/MainFooter'

import routes from 'routes.js'
import ThemeContext from 'services/providers/ThemeContext'

const Pages = () => {
  const fullPages = useRef()
  const { lightMode } = useContext(ThemeContext)
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  const getFullPageName = (routes) => {
    let pageName = getActiveRoute(routes)
    switch (pageName) {
      case 'Login':
        return 'login-page'
      case 'Register':
        return 'register-page'
      default:
        return 'Default Brand Text'
    }
  }
  useEffect(() => {
    document.documentElement.classList.remove('nav-open')
    return () => {
      document.documentElement.classList.remove('nav-open')
    }
  }, [])

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <MainNavbar />
        <main className="flex-grow " ref={fullPages}>
          <div
            className={`px-4 py-10 sm:p-20 md:p-32 lg:p-40 ${
              lightMode ? '' : 'bg-black'
            } ${getFullPageName(routes)}`}
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </div>
        </main>
        <MainFooter />
      </div>
    </>
  )
}

export default Pages
