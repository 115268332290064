import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from 'shadcn-components/ui/button'
import { motion, useAnimation } from 'framer-motion'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Input } from 'shadcn-components/ui/input'
import { Label } from 'shadcn-components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { Checkbox } from 'shadcn-components/ui/checkbox'
import { PasswordInput } from 'shadcn-components/ui/passwordInput'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { useToast } from 'shadcn-components/ui/use-toast'

import {
  Atom,
  RefreshCw,
  Rocket,
  User,
  UserCheck,
  Building,
  Mail,
  Lock,
  Megaphone,
  MessageCircle,
  AlertCircle,
} from 'lucide-react'

import apiUsers from 'services/api/User'
import apiAuth from 'services/api/Auth'
import { setAuthToken } from 'variables/authToken'
import ThemeContext from 'services/providers/ThemeContext'

const Register = () => {
  const { toast } = useToast()
  const params = new URLSearchParams(useLocation().search)
  const { lightMode } = useContext(ThemeContext)
  const [firstname, setFirstname] = useState(params.get('firstname') || '')
  const [lastname, setLastname] = useState(params.get('lastname') || '')
  const [company, setCompany] = useState(params.get('company') || '')
  const [email, setEmail] = useState(params.get('email') || '')
  const [password, setPassword] = useState('')
  const [, setTerms] = useState('')
  const [, setExternalManagement] = useState('')

  const [hearFrom, setHearFrom] = useState('')
  const [reasonToUse, setReasonToUse] = useState('')

  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [managingCompanyPublicId] = useState(params.get('managing_company'))
  const [managingCompanyName] = useState(params.get('managing_company_name'))
  const [hearFromOptions, setHearFromOptions] = useState([])
  const [reasonToUseOptions, setReasonToUseOptions] = useState([])
  useEffect(() => {
    setHearFromOptions(randomArray(initialHearFromOptions))
    setReasonToUseOptions(randomArray(initialReasonToUseOptions))
  }, [])
  function randomArray(array) {
    const newArray = [...array]
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
    }
    return newArray
  }

  // Options for the select inputs
  const initialHearFromOptions = [
    { value: 'Introduced', label: 'I was introduced to Autron' },
    { value: 'Autron outreach', label: 'Autron outreach' },
    { value: 'Google', label: 'Google' },
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Reddit', label: 'Reddit' },
    { value: 'Blogs', label: 'Blogs' },
    { value: 'Influencers', label: 'Influencers' },
  ]

  const initialReasonToUseOptions = [
    {
      value: '24/7 AI campaign optimization',
      label: '24/7 AI campaign optimization',
    },
    {
      value: 'Pay for ad sales, not ad spend',
      label: 'Pay for ad sales, not ad spend',
    },
    { value: 'Save time', label: 'Save time' },
    { value: 'Save money', label: 'Save money' },
    { value: 'Free PPC audit', label: 'Free PPC audit' },
  ]

  // Post login data to API if everything is validated
  const loginUser = async () => {
    const auth = { email, password }
    // Login via API
    const response = await apiAuth
      .login(JSON.stringify(auth))
      .then((res) => res)

    if (response?.status === 200) {
      setAuthToken(response.data.Authorization)
      history.push('/admin')
    } else {
      toast({
        variant: 'destructive',
        description: response?.data?.message,
      })
    }
  }

  // post user data to api if everything validated
  const registerClick = async (e) => {
    e.preventDefault()
    setLoading(true)

    const managing_company_public_id = managingCompanyPublicId
    const hear_from = hearFrom
    const reason_to_use = reasonToUse

    const user = {
      firstname,
      lastname,
      company,
      email,
      password,
      managing_company_public_id,
      hear_from,
      reason_to_use,
    }
    // create user via api
    const response = await apiUsers
      .createSingle(JSON.stringify(user))
      .then((res) => {
        return res
      })

    if (response && response.status < 300) {
      toast({
        variant: 'success',
        description: response?.data?.message,
      })

      // login user after successfull registration
      loginUser()
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
          icon: <AlertCircle className="h-5 w-5 text-yellow-500" />,
        })
      }
      e.preventDefault()
    }
    setLoading(false)
  }

  return (
    <>
      <div className={`py-10 md:-mt-36 -mt-20 ${lightMode ? '' : 'bg-black'}`}>
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-6">
          <div className="lg:w-[50%] space-y-6">
            <Card
              className={`border-0 shadow-none ${
                lightMode ? '' : 'bg-slate-800'
              }`}
            >
              <CardHeader className="flex flex-row items-center gap-4">
                <div className="rounded-full bg-yellow-100 p-3">
                  <RotatingIcon>
                    <Atom className="h-6 w-6 text-yellow-400" />
                  </RotatingIcon>
                </div>
                <CardTitle className="text-2xl font-space-grotesk font-normal leading-tight tracking-normal">
                  24
                  <span className="text-[1.1em] font-space-grotesk font-normal m-0.5">
                    /
                  </span>
                  7 AI campaign optimization{' '}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground font-geist font-regular tracking-normal">
                  Autron analyzes and optimizes your ads 24/7, using dayparting,
                  weekly trends, and seasonality to keep your campaigns
                  effective.
                </p>
              </CardContent>
            </Card>

            <Card
              className={`border-0 shadow-none ${
                lightMode ? '' : 'bg-slate-800'
              }`}
            >
              <CardHeader className="flex flex-row items-center gap-4">
                <div className="rounded-full bg-yellow-100 p-3">
                  <RotatingIcon>
                    <RefreshCw className="h-6 w-6 text-yellow-400" />
                  </RotatingIcon>
                </div>
                <CardTitle className="text-2xl font-space-grotesk font-normal leading-tight tracking-normal">
                  Pay for ad sales, not ad spend
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground font-geist font-regular tracking-normal">
                  Gone are the days of agencies taking your money by spending
                  more on your behalf. With Autron, you only pay a commission on
                  actual Autron Ad Sales.
                </p>
              </CardContent>
            </Card>

            <Card
              className={`border-0 shadow-none ${
                lightMode ? '' : 'bg-slate-800'
              }`}
            >
              <CardHeader className="flex flex-row items-center gap-4">
                <div className="rounded-full bg-yellow-100 p-3">
                  <AnimatedRocket className="text-yellow-400" />
                </div>
                <CardTitle className="text-2xl font-space-grotesk font-normal leading-tight tracking-normal">
                  Save time & money
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground font-geist font-regular tracking-normal">
                  Leave behind endless spreadsheets, CPCs, CTRs, CVRs, and the
                  constant analysis of ACOS. Focus on building your business and
                  nurturing relationships while Autron handles the heavy lifting
                  for you.
                </p>
              </CardContent>
            </Card>
          </div>

          <div className="lg:w-[50%]">
            <Card className="mx-auto max-w-2xl">
              <CardHeader>
                <CardTitle className="text-2xl tracking-wide font-space-grotesk font-normal">
                  Sign Up
                </CardTitle>
                <CardDescription className="font-normal">
                  Enter your information to create an account
                </CardDescription>
              </CardHeader>
              <CardContent>
                <form onSubmit={registerClick} className="grid gap-3">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="grid gap-2">
                      <Label htmlFor="first-name">First name</Label>
                      <div className="relative">
                        <User className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="first-name"
                          placeholder="Max"
                          className="pl-8"
                          onChange={(e) => setFirstname(e.target.value)}
                          value={firstname}
                          required
                        />
                      </div>
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="last-name">Last name</Label>
                      <div className="relative">
                        <UserCheck className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
                        <Input
                          id="last-name"
                          placeholder="Robinson"
                          className="pl-8"
                          onChange={(e) => setLastname(e.target.value)}
                          value={lastname}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="company">Company</Label>
                    <div className="relative">
                      <Building className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
                      <Input
                        id="company"
                        placeholder="Acme Inc."
                        className="pl-8"
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="email">Email</Label>
                    <div className="relative">
                      <Mail className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
                      <Input
                        id="email"
                        type="email"
                        placeholder="m@example.com"
                        className="pl-8"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="password">Password</Label>
                    <div className="relative">
                      <Lock className="absolute left-2 top-3 h-4 w-4 text-muted-foreground z-30" />
                      <PasswordInput
                        id="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="password"
                        className="pl-8"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="how-did-you-hear">
                      How did you hear of Autron?
                    </Label>
                    <div className="relative">
                      <Megaphone className="absolute left-2 top-3 h-4 w-4 text-muted-foreground z-10" />
                      <Select
                        onValueChange={(value) => setHearFrom(value)}
                        required
                      >
                        <SelectTrigger className="pl-8">
                          <SelectValue placeholder="Select an option" />
                        </SelectTrigger>
                        <SelectContent>
                          {hearFromOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="why-use">
                      Why do you want to use Autron?
                    </Label>
                    <div className="relative">
                      <MessageCircle className="absolute left-2 top-3 h-4 w-4 text-muted-foreground z-10" />
                      <Select
                        onValueChange={(value) => setReasonToUse(value)}
                        required
                      >
                        <SelectTrigger className="pl-8">
                          <SelectValue placeholder="Select a reason" />
                        </SelectTrigger>
                        <SelectContent>
                          {reasonToUseOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  {managingCompanyPublicId && (
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id="external-management"
                        onCheckedChange={(checked) =>
                          setExternalManagement(checked)
                        }
                        required
                      />
                      <Label htmlFor="external-management" className="text-sm">
                        I agree to{' '}
                        <span className="font-semibold">
                          {managingCompanyName}
                        </span>{' '}
                        managing Autron on my behalf.
                      </Label>
                    </div>
                  )}
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="terms"
                      onCheckedChange={(checked) => setTerms(checked)}
                      required
                    />
                    <Label htmlFor="terms" className="text-sm">
                      I agree to the{' '}
                      <a
                        href={`${process.env.REACT_APP_FRONTEND_APP_BASE_URL}/terms`}
                        className="text-primary hover:underline"
                      >
                        terms of service
                      </a>
                      .
                    </Label>
                  </div>
                  <Button type="submit" className="w-full" disabled={loading}>
                    {loading === true ? (
                      <LoadingSpinner size="sm" role="status"></LoadingSpinner>
                    ) : (
                      'Create an account'
                    )}
                  </Button>
                </form>
                <div className="mt-4 text-center text-sm">
                  Already have an account?{' '}
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_APP_BASE_URL + '/auth/login'
                    }
                    className="underline"
                  >
                    Sign in
                  </a>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register

const RotatingIcon = ({ children }) => {
  const controls = useAnimation()
  const [isRotating, setIsRotating] = useState(true)

  useEffect(() => {
    controls.start({
      rotate: isRotating ? 360 : 0,
      transition: {
        duration: 1.0,
        repeat: isRotating ? Infinity : 0,
        ease: 'linear',
      },
    })

    const timer = setTimeout(() => {
      setIsRotating(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [controls, isRotating])

  return (
    <motion.div animate={controls} className="rounded-full">
      {children}
    </motion.div>
  )
}

const AnimatedRocket = () => {
  const [isAnimating, setIsAnimating] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <motion.div
      initial={{ y: 0, rotate: 0 }}
      animate={isAnimating ? { y: -20, rotate: -20 } : { y: 0, rotate: 0 }}
      transition={{
        duration: 1.0,
        repeat: isAnimating ? Infinity : 0,
        repeatType: 'mirror',
      }}
    >
      <Rocket className="h-6 w-6 text-yellow-400" />
    </motion.div>
  )
}