import React, { useState, useEffect, useContext } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { useHistory } from 'react-router-dom'
import { Card, CardContent } from 'shadcn-components/ui/card'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import ThemeContext from 'services/providers/ThemeContext'

const Dashboard = ({ user }) => {
  const [loading, setLoading] = useState(true)
  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL

  const [iframeUrl, setIframeUrl] = useState(analyticsUrl)
  const queryClient = useQueryClient()
  const history = useHistory()
  const { lightMode } = useContext(ThemeContext)

  const embedUrlParam = {
    resource: { dashboard: 12 },
    params: {
      seller_id: user.primary_seller.selling_partner_id,
      marketplace_id: user.primary_seller.primary_marketplace_id,
    },
  }

  // Function to fit iframe dynamically based on content
  // const fitIframe = useCallback(() => {
  //   const iframes = document.querySelectorAll('iframe.gh-fit');

  //   iframes.forEach((iframe) => {
  //     const win = iframe.contentWindow;
  //     const doc = win.document;
  //     const html = doc.documentElement;
  //     const body = doc.body;

  //     if (body) {
  //       body.style.overflowX = 'scroll';
  //       body.style.overflowY = 'hidden';
  //     }
  //     if (html) {
  //       html.style.overflowX = 'scroll';
  //       html.style.overflowY = 'hidden';
  //       const style = win.getComputedStyle(html);
  //       iframe.width = parseInt(style.getPropertyValue('width'));
  //       iframe.height = parseInt(style.getPropertyValue('height'));
  //     }
  //   });

  //   requestAnimationFrame(fitIframe);
  // }, []);

  // Get Metabase iframeUrl
  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed', embedUrlParam],
    () => apiAnalytics.generateEmbedUrl(embedUrlParam),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          // could handle this better but for now just assume token expired
          history.go(0) // refresh page
        }
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed',
      embedUrlParam,
    ])

    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)
    }
  }, [queryClient, embedUrlParam])

  // Trigger iframe fitting on load
  // useEffect(() => {
  //   if (!loading) {
  //     fitIframe();
  //   }
  // }, [loading, fitIframe]);

  return (
    <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto shadow-none border-none">
      <div className="flex justify-between items-start mb-6">
        <div className="flex flex-col gap-1">
          <h2
            className={`text-3xl tracking-normal font-space-grotesk ${
              lightMode ? 'text-slate-800' : 'text-white'
            }`}
          >
            Dashboard
          </h2>
          <p className="text-muted-foreground font-geist tracking-normal">
            Easily understand your Amazon business performance at a glance.
          </p>
        </div>
      </div>

      <Card>
        <CardContent className="pt-6 overflow-visible">
          {loading && (
            <div className="space-y-4 px-8 py-6">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
            </div>
          )}

          {iframeUrl !== analyticsUrl && (
            <IframeResizer
              src={iframeUrl}
              className="gh-fit"
              style={{
                width: '1px',
                minWidth: '100%',
                minHeight: '62.5rem',
              }}
              hidden={loading}
              onLoad={() => setLoading(false)}
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default Dashboard
