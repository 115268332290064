import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'products'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiProducts = new ApiCore({
  getAll: true,
  url: url,
})

apiProducts.createSingleCost = (
  productPublicId,
  data,
  withCredentials = true
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/${productPublicId}/costs`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProducts.updateSingleCost = (
  productPublicId,
  id,
  data,
  withCredentials = true
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .put(`/${url}/${productPublicId}/costs/${id}`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProducts.deleteSingleCost = (
  productPublicId,
  id,
  withCredentials = true
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .delete(`/${url}/${productPublicId}/costs/${id}`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProducts.createTargets = (productPublicId, data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/${productPublicId}/targets`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProducts.deleteSingleTarget = (
  productPublicId,
  id,
  withCredentials = true
) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .delete(`/${url}/${productPublicId}/targets/${id}`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiProducts.createBulkTargets = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/targets`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiProducts.createBulkCosts = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/costs`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

export default apiProducts
