import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

const url = 'auth'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiAuth = new ApiCore({
  url: url,
})

apiAuth.login = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/login`, data, {
      withCredentials: withCredentials,
      skipAuthRefresh: true,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiAuth.logout = (withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/logout`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiAuth.token_refresh = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/token/refresh`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiAuth.user = (withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/user`, { withCredentials: withCredentials })
    .then(handleResponse)
    .then((response) => response.data)
    .catch(null)
}

apiAuth.forgot_password = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/forgot-password`, data, {
      withCredentials: withCredentials,
      skipAuthRefresh: true,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiAuth.reset_password = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/reset-password`, data, {
      withCredentials: withCredentials,
      skipAuthRefresh: true,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiAuth.verify_email = (withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/verify-email`, {
      withCredentials: withCredentials,
      skipAuthRefresh: true,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiAuth.verification_email = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/verification-email`, data, {
      withCredentials: withCredentials,
      skipAuthRefresh: true,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiAuth
