import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Lock } from 'lucide-react'
import { Button } from 'shadcn-components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { PasswordInput } from 'shadcn-components/ui/passwordInput'
import { Label } from 'shadcn-components/ui/label'

import apiAuth from 'services/api/Auth'
import { setAuthToken } from 'variables/authToken'
import { useToast } from 'shadcn-components/ui/use-toast'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'

const ForgotPassword = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const params = new URLSearchParams(useLocation().search)
  const [authToken] = useState(params.get('auth_token'))
  const doPasswordsMatch = password === confirmPassword
  const { toast } = useToast()
  const continueClick = async (e) => {
    if (authToken) {
      e.preventDefault()
      const auth = { password }
      setLoading(true)
      setAuthToken('Bearer ' + authToken)
      // login via api
      const response = await apiAuth
        .reset_password(JSON.stringify(auth))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        history.push('/auth/login')
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
      setLoading(false)
    } else if (!authToken) {
      toast({
        variant: 'destructive',
        description:
          'Please enter your new password directly from the link in the email.',
      })
      e.preventDefault()
    }
  }

  return (
    <>
      <div className="flex items-center justify-center bg-background mb-10">
        <Card className="w-[400px]">
          <CardHeader>
            <CardTitle className="tracking-normal font-space-grotesk font-normal mb-2">
              Enter a new password
            </CardTitle>
          </CardHeader>
          <form onSubmit={continueClick}>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="password">New password</Label>
                <div className="relative">
                  <Lock className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground z-30" />
                  <PasswordInput
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="password"
                    className="pl-8"
                    required
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirmPassword">Confirm password</Label>
                <div className="relative">
                  <Lock className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground z-30" />
                  <PasswordInput
                    id="password"
                    placeholder="Re-enter your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    autoComplete="password"
                    className="pl-8"
                    required
                  />
                </div>
                {confirmPassword && !doPasswordsMatch && (
                  <p className="text-sm text-destructive">
                    Passwords do not match.
                  </p>
                )}
              </div>
            </CardContent>
            <CardFooter>
              <Button
                type="submit"
                className="w-full"
                disabled={!doPasswordsMatch || loading}
              >
                {loading ? <LoadingSpinner /> : 'Save'}
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  )
}

export default ForgotPassword
