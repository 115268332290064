import React, { useContext } from 'react'

import { Card, CardContent } from 'shadcn-components/ui/card'
import {
  Coins,
  Rocket,
  RefreshCw,
  Wallet,
  Tag,
  Gift,
  ChevronRightIcon,
} from 'lucide-react'
import { Button } from 'shadcn-components/ui/button'
import ThemeContext from 'services/providers/ThemeContext'
import affiliateHeroImg from 'assets/img/autron-affiliate-hero.webp'

const steps = [
  {
    number: 1,
    title: 'Create Affiliate Account',
    description:
      'Sign up for an affiliate account and gain access to your affiliate dashboard.',
  },
  {
    number: 2,
    title: 'Get Your Link',
    description:
      'Your personalized affiliate link ensures you get full credit for every conversion you earn.',
  },
  {
    number: 3,
    title: 'Share and Earn',
    description:
      'Share this link with your audience and start collecting a recurring 25% commission for every conversion!',
  },
]

const Affiliate = () => {
  const { lightMode } = useContext(ThemeContext)

  return (
    <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto font-geist">
      <div className="flex justify-between items-start mb-6">
        <div className="flex flex-col gap-1">
          <h2
            className={`text-3xl tracking-normal font-space-grotesk ${
              lightMode ? 'text-slate-800' : 'text-white'
            }`}
          >
            Earn with Autron
          </h2>
          <p className="text-muted-foreground font-geist font-normal tracking-normal">
            Collaborate with Autron and earn ongoing commissions by promoting
            Autron’s AI-driven advertising solutions to your network.
          </p>
        </div>
      </div>

      <Card className="mb-8">
        <CardContent className="py-10 flex flex-col gap-8">
          <div className="flex flex-col md:flex-row items-center sm:px-8">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
              <h1 className="text-4xl mb-4 font-space-grotesk font-normal">
                Join the Autron Affiliate Program
              </h1>
              <p className="text-lg text-muted-foreground mb-6">
                Partner with Autron and get paid for giving your audience access
                to the latest in AI-powered advertising to crush their Amazon
                goals. Earn <strong>recurring 25% commission</strong> on monthly
                subscriptions.
              </p>
              <Button
                size="lg"
                className="mb-4"
                onClick={() => {
                  window.open(
                    'https://autron.getrewardful.com/signup',
                    '_blank'
                  )
                }}
              >
                Join Now
              </Button>
              <p className="text-sm text-muted-foreground">
                Already an Affiliate?{' '}
                <a
                  href="https://autron.getrewardful.com/login"
                  target="_blank"
                  className="text-primary underline"
                >
                  Login
                </a>
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img
                src={affiliateHeroImg}
                alt="Affiliate Program"
                className="w-full h-auto"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {[
              {
                icon: <Coins className="h-8 w-8 stroke-1" />,
                title: 'Recurring 25% Commission',
                description:
                  'Get 25% commission on all customer payments for the first 24 months. Reap the rewards of compounding!',
                color: 'yellow',
              },
              {
                icon: <Rocket className="h-8 w-8 stroke-1" />,
                title: 'No Earnings Cap',
                description:
                  'We have no caps on how much money you can make. Easily earn thousands of dollars from our monthly plans which range from $99 to $5000.',
                color: 'blue',
              },
              {
                icon: <RefreshCw className="h-8 w-8 stroke-1" />,
                title: '60-Day Conversion Window',
                description:
                  "We provide a 60-day affiliate cookie to ensure you're credited whenever a customer converts.",
                color: 'green',
              },
              {
                icon: <Wallet className="h-8 w-8 stroke-1" />,
                title: 'Get Paid Monthly',
                description:
                  'Payouts are made via PayPal or Wise each month, based on a 60-day conversion window.',
                color: 'purple',
              },
              {
                icon: <Tag className="h-8 w-8 stroke-1" />,
                title: 'Special Deals',
                description:
                  'Get personalized codes and deals for your audience.',
                color: 'red',
              },
              {
                icon: <Gift className="h-8 w-8 stroke-1" />,
                title: 'Partner Perks',
                description:
                  'Top affiliates get early access to new features & tools and favorable Autron pricing.',
                color: 'pink',
              },
            ].map((item, index) => (
              <div key={index} className="border-none p-6">
                <CardContent className="pt-6">
                  <div className={`flex items-center justify-center mb-4`}>
                    {item.icon}
                  </div>
                  <h3 className="text-xl font-normal font-space-grotesk mb-2 text-center">
                    {item.title}
                  </h3>
                  <div className={`h-1 w-16 mx-auto mb-4`}></div>
                  <p className="text-muted-foreground text-center">
                    {item.description}
                  </p>
                </CardContent>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card className="mb-8">
        <CardContent className="py-8">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-normal font-space-grotesk mb-4">
              Ready to get started?
            </h2>
            <p className="text-lg text-muted-foreground">
              It only takes a few minutes!
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {steps.map((step, index) => (
              <div key={index} className="border-none p-6">
                <CardContent className="pt-6 text-center">
                  <div className="w-12 h-12 rounded-full bg-transparent flex items-center justify-center text-2xl font-normal font-space-grotesk border-2 border-primary mx-auto mb-4">
                    {step.number}
                  </div>
                  <h3 className="text-xl font-normal font-space-grotesk mb-2">
                    {step.title}
                  </h3>
                  <p className="text-muted-foreground">{step.description}</p>
                </CardContent>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <Button
              size="lg"
              onClick={() => {
                window.open('https://autron.getrewardful.com/signup', '_blank')
              }}
            >
              Get Started
            </Button>
          </div>
        </CardContent>
      </Card>

      <Card className="mb-8">
        <CardContent className="py-10 flex flex-col gap-8">
          <div className="flex flex-col md:flex-row items-center sm:px-8">
            <div className="w-full mb-8">
              <h2 className="text-3xl font-normal font-space-grotesk mb-2">
                Share with your audience
              </h2>
              <p className="text-lg text-muted-foreground mb-6">
                There are a million and one ways to share Autron online, here
                are some suggestions:
              </p>
              <Button
                size="lg"
                className="mt-2 lg:mt-6"
                onClick={() => {
                  window.open(
                    'https://autron.getrewardful.com/signup',
                    '_blank'
                  )
                }}
              >
                Join Now
              </Button>
            </div>
            <div className="w-full md:w-1/2">
              <ul className="space-y-2">
                {[
                  'Recommend Autron to clients / mentees in conversations',
                  'Link to us from a blog post',
                  'Write or publish a video review of our product',
                  'Collaborate on a case study with us',
                  'Run a webinar / podcast together',
                  'Write a guest post for the Inside Autron blog',
                  'Have us do a guest newsletter to your email list',
                ].map((text, index) => (
                  <li key={index} className="flex">
                    <ChevronRightIcon className="w-6 h-6 mr-4 flex-shrink-0" />
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default Affiliate
