import React, { useState } from 'react'
import apiAuth from 'services/api/Auth'
import { Link } from 'react-router-dom'
import { Mail } from 'lucide-react'

import { Button } from 'shadcn-components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Input } from 'shadcn-components/ui/input'
import { Label } from 'shadcn-components/ui/label'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { useToast } from 'shadcn-components/ui/use-toast'

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const { toast } = useToast()

  const continueClick = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const auth = { email }
    const response = await apiAuth
      .forgot_password(JSON.stringify(auth))
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      toast({
        variant: 'success',
        description: response?.data?.message,
      })
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className="container mx-auto flex items-center justify-center px-4 sm:px-6 mb-8 sm:mb-12">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="tracking-normal font-space-grotesk font-normal mb-2">
              Forgot your password?
            </CardTitle>
            <CardDescription className="font-normal">
              Enter the email address associated with your account, and
              we&apos;ll send you a link to reset your password.
            </CardDescription>
          </CardHeader>
          <form onSubmit={continueClick}>
            <CardContent>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <div className="relative">
                    <Mail className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
                    <Input
                      id="email"
                      placeholder="Enter your email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="pl-10"
                    />
                  </div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex flex-col space-y-4">
              <Button className="w-full" type="submit" disabled={isLoading}>
                {isLoading ? <LoadingSpinner /> : 'Send Reset Link'}
              </Button>
              <div className="flex justify-between w-full text-sm">
                <Link to="/auth/login" className="underline">
                  Return to Login
                </Link>
                <Link to="/auth/register" className="underline">
                  Create Account
                </Link>
              </div>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  )
}

export default ForgotPassword
