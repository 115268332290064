import React, { useState, useEffect } from 'react'

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'

import apiStripe from 'services/api/Stripe'
import { loadStripe } from '@stripe/stripe-js'
import useStripeSetup from 'services/hooks/useStripeSetup'
import { filterByStatuses } from 'services/Utils'

const CheckoutForm = ({ user }) => {
  const { setup } = useStripeSetup()

  const [stripePromise, setStripePromise] = useState(null)

  const fetchClientSecret = async () => {
    const stripeClientReferenceId =
      (window.Rewardful && window.Rewardful.referral) ||
      'checkout_' + user?.public_id + '_' + new Date().getTime()

    const data = {
      customer_id: user.company.stripe_customer_id,
      customer_email: user.email,
      user_public_id: user.public_id,
      company_public_id: user.company.public_id,
      client_reference_id: stripeClientReferenceId,
      subscription_status: filterByStatuses(
        user?.company?.stripe_subscription_items,
        ['canceled']
      ),
    }

    const response = await apiStripe.createEmbeddedSession(data)
    return response.data.session.client_secret
  }

  const options = { fetchClientSecret }

  // get publishable key
  useEffect(() => {
    if (setup) {
      const pkStripe = loadStripe(setup.publishable_key)
      if (pkStripe) {
        setStripePromise(pkStripe)
      }
    }
  }, [setup])

  return (
    <div id="checkout" className='p-2'>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default CheckoutForm
