import React, { useEffect, useState, useRef, useContext } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Footer from 'components/Footer/Footer.js'

import routes from 'routes.js'


import { filterByStatuses } from 'services/Utils'
import ThemeContext from 'services/providers/ThemeContext'

var ps

const Admin = ({ props, user }) => {
  const [activeColor] = useState('primary')
  const [, setSidebarMini] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const mainPanel = useRef()
  const location = useLocation()

  const getRoutes = (routes, user) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views, user)
      }

      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component user={user} />}
            key={key}
          />
        )
      }

      return null
    })
  }

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          routes[i].layout + routes[i].path.indexOf(window.location.pathname) !=
          -1
        ) {
          return routes[i].name
        }
      }
    }
    return null // Return null if no active route is found
  }

  const handleOnboardingRedirect = (user, pathname) => {
    const redirectPathname = '/admin/onboarding'
    const paymentPath = '/admin/account/billing'

    if (!pathname.includes(redirectPathname)) {
      const { stripe_customer_id, stripe_subscription_items } = user.company

      const isSubscribed =
        stripe_customer_id !== null &&
        filterByStatuses(stripe_subscription_items, ['active', 'trialing']) !==
          null

      const isAdmin = user.admin === 'True' ? true : false
      if (
        [...user.company.sellers, ...user.company.managed_sellers]?.length > 0
      ) {
        if (!isAdmin) {
          if (!isSubscribed) {
            if (pathname !== paymentPath) {
              return <Redirect from={pathname} to={paymentPath} />
            }
          }
        }

        let primarySeller = user.primary_seller
        let onlyManagedSellers =
          user.company.sellers?.length === 0 &&
          user.company.managed_sellers?.length > 0
            ? true
            : false
        if (primarySeller?.profiles?.length > 0) {
          if (primarySeller.ingest_completed_at) {
            if (
              primarySeller?.company.managing_company?.public_id &&
              primarySeller?.company.managing_company?.public_id !==
                user.company.public_id
            ) {
              // redirect to onboarding managed by external company
              if (pathname !== redirectPathname) {
                return <Redirect from={pathname} to={redirectPathname} />
              }
            }
          } else {
            // redirect to onboarding data syncing
            if (pathname !== redirectPathname) {
              return <Redirect from={pathname} to={redirectPathname} />
            }
          }
        } else {
          if (onlyManagedSellers) {
            if (pathname !== redirectPathname) {
              return <Redirect from={pathname} to={redirectPathname} />
            }
          } else {
            const sellerPath =
              '/admin/onboarding/seller/' +
              (primarySeller.public_id ? primarySeller.public_id : '')
            if (pathname !== sellerPath) {
              return <Redirect from={pathname} to={sellerPath} />
            }
          }
        }
      } else {
        const { public_id } = user.company.managing_company
        if (public_id) {
          if (pathname !== redirectPathname) {
            return <Redirect from={pathname} to={redirectPathname} />
          }
        } else {
          if (!isAdmin) {
            if (!isSubscribed) {
              if (pathname !== paymentPath) {
                return <Redirect from={pathname} to={paymentPath} />
              }
            } else {
              if (pathname !== redirectPathname) {
                return <Redirect from={pathname} to={redirectPathname} />
              }
            }
          }
        }
      }
    }

    return null
  }
  // Filter routes for sidebar based on user conditions
  const sidebarRoutes = routes.filter((route) => {
    return true
  })

  const { lightMode } = useContext(ThemeContext)
  return (
    <div className="flex flex-col w-full">
      <AdminNavbar
        {...props}
        brandText={getActiveRoute(routes)}
        sidebarOpened={sidebarOpened}
        user={user}
        routes={routes}
      />
      <div className={`${lightMode ? '' : 'bg-black'} min-h-[80vh]`}>
        <Switch>
          {/* First process any onboarding redirects */}
          {handleOnboardingRedirect(user, props.location.pathname)}

          {/* Then, match the routes */}
          {getRoutes(routes, user)}

          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
      </div>
      <Footer />
    </div>
  )
}

export default Admin