import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'leads/ppc-audit'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiLeads = new ApiCore({
  getSingle: true,
  url: url,
})

apiLeads.createLeads = async (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return await axios
    .post(`/${url}/`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiLeads.matchLeads = async (withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return await axios
    .get(`/${url}/match`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiLeads
