import React, { useState, useEffect } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { useHistory } from 'react-router-dom'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Alert, AlertTitle, AlertDescription } from 'shadcn-components/ui/alert'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { LockIcon } from 'lucide-react'

const InsightsDashboard = ({ insight, seller }) => {
  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL

  const [iframeUrl, setIframeUrl] = useState(
    analyticsUrl // for the auto height to work, it appears a default url is needed
  )
  const queryClient = useQueryClient()
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const embedUrlParam = {
    resource: { dashboard: insight.dashboardId },
    params: {
      seller_id: seller.selling_partner_id,
      marketplace_id: seller.primary_marketplace_id,
    },
  }

  // Get Metabase iframeUrl
  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed', embedUrlParam],
    () => apiAnalytics.generateEmbedUrl(embedUrlParam),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          // could handle this better but for now just assume token expired
          history.go(0) // refresh page
        }
      },
      enabled: insight.dashboardId !== null, // Only run query if dashboardId is not null
    }
  )

  useEffect(() => {
    if (insight.dashboardId !== null) {
      const iframeUrlCache = queryClient.getQueryData([
        'POST/analytics/embed',
        embedUrlParam,
      ])
      if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
        setIframeUrl(iframeUrlCache.data.iframe_url)
      }
    }
  }, [queryClient, embedUrlParam, insight.dashboardId])

  return (
    <>
      <Card className="">
        <CardHeader className="px-8 py-6">
          <CardTitle className="font-space-grotesk font-normal">
            {insight.name}
          </CardTitle>
          <div className="pb-4">
            <CardDescription className="font-normal">
              {insight.description}
            </CardDescription>
            {insight.description2 && (
              <CardDescription
                className="italic font-normal"
                dangerouslySetInnerHTML={{ __html: insight.description2 }}
              />
            )}
          </div>
          {insight.dashboardId === null ? (
            <Alert>
              <LockIcon className="h-4 w-4" />
              <AlertTitle>Upgrade</AlertTitle>
              <AlertDescription>
                Unlock bespoke reporting features to make smarter decisions and
                enhance your strategy.{' '}
                <a
                  href="mailto:hello@autron.ai?subject=Details%20and%20Pricing%20for%20Custom%20Dashboards"
                  target="_blank"
                  className="alert-link"
                >
                  <span className="hover:underline font-semibold">
                    Contact the Autron team today
                  </span>
                </a>{' '}
                for more details and pricing.
              </AlertDescription>
            </Alert>
          ) : (
            <></>
          )}
        </CardHeader>

        <CardContent className="p-0">
          {loading && insight.dashboardId !== null && (
            <div className="space-y-4 px-8 py-6">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
              <div className="grid grid-cols-3 gap-4 pt-4">
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
                <Skeleton className="h-40 w-full" />
              </div>
            </div>
          )}

          {iframeUrl !== analyticsUrl && (
            <IframeResizer
              src={iframeUrl}
              style={{ width: '1px', minWidth: '100%', height: '112.5em' }}
              className={loading ? 'hidden' : ''}
              onLoad={() => setLoading(false)}
            />
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default InsightsDashboard