import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Input } from 'shadcn-components/ui/input'
import { Button } from 'shadcn-components/ui/button'
import { Label } from 'shadcn-components/ui/label'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { PasswordInput } from 'shadcn-components/ui/passwordInput'
import { useToast } from 'shadcn-components/ui/use-toast'
import { AtSign, Lock } from 'lucide-react'

import { Link } from 'react-router-dom'
import apiAuth from 'services/api/Auth'
import { setAuthToken } from 'variables/authToken'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { toast } = useToast()

  // post login data to api if everything validated
  const { data } = useQuery(
    'GET/auth/user',
    () =>
      // eslint-disable-line no-unused-vars
      apiAuth.user(),
    {
      retry: false,
      //staleTime: 60 * 1000 * 1, // 1 minutes
      onSuccess: (data) => {
        if (data) {
          history.push('/admin/dashboard')
        }
      },
    }
  )

  // post login data to api if everything validated
  const loginClick = async (e) => {
    e.preventDefault()
    setLoading(true)
    const auth = { email, password }
    // login via api
    const response = await apiAuth.login(JSON.stringify(auth)).then((res) => {
      return res
    })
    if (response?.status < 300) {
      setAuthToken(response.data.Authorization)
      console.log({ status: response.status })
      history.push('/admin')
    } else {
      toast({
        variant: 'destructive',
        title: 'Oops!',
        description: response?.data?.message,
      })
    }
    setLoading(false)
  }

  return (
    <>
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl tracking-normal font-space-grotesk font-normal">
            Login
          </CardTitle>
          <CardDescription className="font-normal">
            Enter your email below to login to your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={loginClick} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <div className="relative">
                <AtSign className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  className="pl-8"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">Password</Label>
                <Link
                  to="/auth/forgot-password"
                  className="ml-auto inline-block text-sm underline"
                >
                  Forgot your password?
                </Link>
              </div>
              <div className="relative">
                <Lock className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground z-30" />
                <PasswordInput
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="password"
                  className="pl-8"
                  required
                />
              </div>
            </div>
            <Button type="submit" className="w-full" disabled={loading}>
              {loading === true ? <LoadingSpinner></LoadingSpinner> : 'Login'}
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            Don&apos;t have an account?{' '}
            <Link to="/auth/register" className="underline">
              Sign up
            </Link>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default Login
