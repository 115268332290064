import { useToast } from 'shadcn-components/ui/use-toast'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import apiAuth from 'services/api/Auth'
import { setAuthToken } from 'variables/authToken'

const VerifyEmail = () => {
  const {toast} = useToast()
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  const [authToken] = useState(params.get('auth_token'))

  useEffect(() => {
    const verifyEmail = async () => {
      if (authToken) {
        setAuthToken('Bearer ' + authToken)
        const response = await apiAuth.verify_email().then((res) => {
          return res
        })
        if (response && response.status < 300) {
          setAuthToken(response.data.Authorization)
          toast({
            variant: 'success',
            description: response?.data?.message,
          })
          history.push('/admin')
        } else {
          if (response) {
            toast({
              variant: 'destructive',
              description: response?.data?.message,
            })
          }
          history.push('/auth/login')
        }
      } else {
        history.push('/auth/login')
      }
    }
    verifyEmail()
  }, [history, authToken]) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}

export default VerifyEmail
