import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'stripe'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiStripe = new ApiCore({
  url: url,
})

apiStripe.setup = (withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/setup`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.createCheckoutSession = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/checkout-session`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.createEmbeddedSession = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/embedded-session`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.getSubscriptionItem = (customerId, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/subscription-item/${customerId}`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.getCheckoutSession = (id, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/checkout-session/${id}`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.createCustomerPortal = (withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/customer-portal`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.getCustomer = (id, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/customer/${id}`, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiStripe.getCustomerUpcomingInvoices = (id, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .get(`/${url}/customer/${id}/upcoming-invoices`, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiStripe
