import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useHistory, Link } from 'react-router-dom'
import apiAuth from 'services/api/Auth'
import apiSellers from 'services/api/Seller'
import apiUsers from 'services/api/User'
import { setAuthToken } from 'variables/authToken'
import { useQueryClient, QueryCache } from 'react-query'
import { useAsyncDebounce } from 'react-table'
import autron_logo_light_mode from 'assets/img/autron_new_logo_light_mode.png'
import autron_logo_dark_mode from 'assets/img/autron_new_logo_dark_mode.png'
import autron_icon_light_mode from 'assets/img/autron_icon_light_mode.png'
import autron_icon_dark_mode from 'assets/img/autron_icon_dark_mode.png'
import { NavLink } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'shadcn-components/ui/dialog'

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from 'shadcn-components/ui/avatar'
import { Button } from 'shadcn-components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import { RadioGroup, RadioGroupItem } from 'shadcn-components/ui/radio-group'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'shadcn-components/ui/popover'
import { Card } from 'shadcn-components/ui/card'
import { Check, ChevronsUpDown, Search, Sun, Menu, Moon } from 'lucide-react'
import { Textarea } from 'shadcn-components/ui/textarea'

import { Fragment } from 'react'
import { inputFieldChange, filterByStatuses } from 'services/Utils'
import { segmentReset } from 'services/Segment'
import AlertCountdown from 'components/AlertNotification/AlertCountdown'
import ThemeContext from 'services/providers/ThemeContext'
import { useToast } from 'shadcn-components/ui/use-toast'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { Separator } from 'shadcn-components/ui/separator'

const useRange = (num, inMin, inMax, outMin, outMax) => {
  const mappedValue = useMemo(() => {
    const newValue =
      ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
    const largest = Math.max(outMin, outMax)
    const smallest = Math.min(outMin, outMax)
    return Math.min(Math.max(newValue, smallest), largest)
  }, [num, inMin, inMax, outMin, outMax])

  return mappedValue
}

const AdminNavbar = ({ brandText, sidebarOpened, user, routes }) => {
  const { toast } = useToast()
  const [scrollY, setScrollY] = useState(0)

  // Custom scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY || window.pageYOffset)
    }

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const navX = useRange(scrollY, 0, 40, 25, 172)
  const logoScale = useRange(scrollY, 0, 40, 1, 0.8)
  const logoY = useRange(scrollY, 0, 40, 0, 8)

  const queryClient = useQueryClient()
  const queryCache = new QueryCache()
  const { lightMode, setLightMode } = useContext(ThemeContext)
  const [activeItem, setActiveItem] = useState('Overview')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const [primarySeller, setPrimarySeller] = useState({})
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [color, setColor] = useState('navbar-transparent')
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [feedback, setFeedback] = useState('')
  const [feedbackFocus, setFeedbackFocus] = useState(false)
  const [feedbackValidationState, setFeedbackValidationState] = useState('')
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const autronLogo = lightMode ? autron_logo_light_mode : autron_icon_dark_mode
  const [logo, setLogo] = useState(autronLogo)
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false)
  const [hasAlert, setHasAlert] = useState(false)

  const regionAbbreviations = {
    'North America': 'NA',
    Europe: 'EU',
    'Far East': 'FE',
  }

  const maxVisibleSellers = 5

  const [sellerSearchFocus, setSellerSearchFocus] = useState(false)
  const [sellerSearchString, setSellerSearchString] = useState('')
  const [visibleSellers, setVisibleSellers] = useState(maxVisibleSellers)
  const [filteredSellers, setFilteredSellers] = useState([
    ...user.company.sellers,
    ...user.company.managed_sellers,
  ])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('simpleIda')
  const [search, setSearch] = useState('')
  const [selectedMenuItem, setSelectedMenuItem] = useState('')
  const [isCondensed, setIsCondensed] = useState(false)
  const [theme, setTheme] = useState('light')
  const [searchResultFlag, setSearchResultFlag] = useState(true)
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }
  const navbarRoutes = routes.filter((route) => route.sidebar === true)

  useEffect(() => {
    const checkAlert = () => {
      const alertElement = document.querySelector('[role="trialAlert"]')
      setHasAlert(!!alertElement)
    }

    checkAlert()

    const observer = new MutationObserver(checkAlert)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    if (window.scrollY > 5) {
      setIsCondensed(true)
    } else {
      setIsCondensed(false)
    }
  }, [])

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const onSellerSearchChange = useAsyncDebounce(async (s) => {
    let sellers = [...user.company.sellers, ...user.company.managed_sellers] // default dropdown items
    setSearchResultFlag(true) // hide the "No match found message"

    if (user.admin !== 'True') {
      // non autron admin user, perform search in managed_sellers
      if (s) {
        sellers = sellers.filter(
          (seller) => seller.name?.toLowerCase()?.indexOf(s?.toLowerCase()) > -1
        )
      }
    } else if (user.admin === 'True' && s.trim().length !== 0) {
      // clear the results set first
      setFilteredSellers([])

      // autron admin user, search ALL sellers by requesting on /sellers/search api
      // console.log('admin search: ', s);
      setLoading(true)
      const response = await apiSellers.search(s).then((apiResponse) => {
        return apiResponse
      })

      if (response && response.status === 200) {
        // console.log('admin search result: ', [ ...response.data.data]);
        sellers = [...response.data.data]
      }
      setLoading(false)
    }

    // show message when no matching seller is found
    sellers.length !== 0
      ? setSearchResultFlag(true)
      : setSearchResultFlag(false)
    setFilteredSellers(sellers)
    setVisibleSellers(maxVisibleSellers)
  }, 500)

  const feedbackToggle = () => setFeedbackDialogOpen((prevState) => !prevState)

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor('navbar-transparent')
    } else {
      setColor('bg-white')
    }
    setCollapseOpen(!collapseOpen)
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = useCallback(() => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white')
    } else {
      setColor('navbar-transparent')
    }
  }, [collapseOpen])

  // function that handles change of theme
  const handleTheme = () => {
    if (lightMode) {
      localStorage.setItem('theme', 'dark')
      setLightMode(false)
    } else {
      localStorage.setItem('theme', 'light')
      setLightMode(true)
    }
  }
  // logout user via api
  const logoutClick = async (e) => {
    const response = await apiAuth.logout().then((res) => {
      return res
    })
    if (response && response.status < 300) {
      setAuthToken('')
      queryCache.clear()
      segmentReset()
      toast({
        variant: 'success',
        description: response?.data?.message,
      })
      history.push('/auth/login')
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
      e.preventDefault()
    }
  }

  // send user feedback
  const sendFeedbackClick = async (e) => {
    e.preventDefault()
    setLoading(true)
    const feedback_data = {
      template: 'generic_email.html',
      subject: 'Feedback - '.concat(history.location.pathname),
      to_email: 'hello@autron.ai',
      message: feedback,
    }
    // send feedback email via api
    const response = await apiUsers
      .send_email(user.public_id, feedback_data)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      toast({
        variant: 'success',
        description: 'Thanks for your feedback, '
          .concat(user.firstname)
          .concat('!'),
      })
      setFeedback('')
      setFeedbackValidationState('')
      feedbackToggle()
    } else {
      if (response) {
        toast({
          variant: 'destructive',
          description: response?.data?.message,
        })
      }
    }
    setLoading(false)
  }

  // set user primary seller and marketplace
  const primarySellerClick = async (value) => {
    // If we successfully found the input, proceed
    const [sellerPublicId, marketplaceId] = value.split(',')
    const seller = {
      primary_marketplace_id: marketplaceId,
    }

    if (seller) {
      const response = await apiSellers
        .primary(sellerPublicId, seller)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        //queryClient.invalidateQueries("GET/auth/user")
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        window.location.replace('/admin/dashboard') // ideally we would handle this with invalidateQueries
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
    }
  }
  const completeOnboardingClick = async (e, seller) => {
    if (seller.public_id === primarySeller.public_id) {
      history.push('/admin/onboarding/seller/' + seller.public_id)
    } else {
      const response = await apiSellers
        .primary(seller.public_id, seller)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        window.location.replace('/admin/onboarding/seller/' + seller.public_id) // ideally we would handle this with invalidateQueries
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    const matchingRoute = navbarRoutes.find((route) =>
      currentPath.startsWith(route.layout + route.path.split('/:')[0])
    )
    if (matchingRoute) {
      setSelectedMenuItem(matchingRoute.name)
    }
  }, [window.location.pathname, navbarRoutes])

  return (
    <>
      <AlertCountdown />

      <Card
        className={`sticky ${
          hasAlert ? 'top-[2.30rem] lg:top-0' : 'top-0'
        } lg:relative flex items-center justify-between pt-4 px-6 pb-4 lg:pb-0 z-[60] rounded-none border-t-0 border-r-0 border-l-0 lg:border-b-0 shadow-none tracking-wide`}
      >
        <div
          className={`flex items-center space-x-4 ${
            lightMode ? 'bg-white' : ''
          }`}
        >
          <a
            href="/"
            className={`hidden sm:flex items-center hover:cursor-pointer fixed ${
              hasAlert ? 'top-12' : 'top-3'
            } left-5 ${lightMode ? 'bg-white' : ''}`}
            style={{
              ...(window.innerWidth >= 1024 && {
                transform: `scale(${logoScale}) translateY(-${logoY}px)`,
              }),
            }}
          >
            <img
              src={lightMode ? autron_logo_light_mode : autron_logo_dark_mode}
              alt="Autron Logo"
              width={128}
              className="object-contain translate-y-[7px]"
            />
          </a>

          <a
            href="/"
            className={`sm:hidden flex justify-center items-center hover:cursor-pointer fixed ${
              hasAlert ? 'top-[3.5rem]' : 'top-3'
            } left-1 top-4 ${lightMode ? 'bg-white' : ''}`}
          >
            <img
              src={lightMode ? autron_icon_light_mode : autron_icon_dark_mode}
              alt="Autron Logo"
              width={42}
              className="object-contain"
            />
          </a>

          <div className={`pl-12 sm:pl-36`}>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="max-w-[250px] justify-between pl-3 pr-2" // Changed from w-full sm:w-[200px]
                >
                  <div className="flex items-center flex-1 min-w-0">
                    {' '}
                    <div className="w-5 h-5 rounded-full mr-2 flex-shrink-0 bg-gradient-to-r from-lime-400 to-yellow-400" />
                    <div className="flex-1 min-w-0">
                      {' '}
                      <div className="flex gap-1 items-start font-geist font-medium truncate">
                        {' '}
                        <span className="truncate">
                          {primarySeller.name || ''}
                        </span>
                        <span className="text-muted-foreground flex-shrink-0">
                          (
                          {primarySeller.primary_marketplace_country_code?.toUpperCase()}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                  <ChevronsUpDown className="ml-2 h-4 w-4 flex-shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0 z-[10000] ">
                {/* spawn the searchbox if the current user is admin OR if the managed_sellers is more than visibleSellers */}
                {user.admin === 'True' ||
                [...user.company.sellers, ...user.company.managed_sellers]
                  ?.length > visibleSellers ||
                visibleSellers > maxVisibleSellers ? (
                  <div
                    className="flex items-center border-b px-3"
                    cmdk-input-wrapper=""
                  >
                    <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                    <input
                      placeholder="Search seller..."
                      value={sellerSearchString || ''}
                      onChange={(e) => {
                        setSellerSearchString(e.target.value)
                        onSellerSearchChange(e.target.value)
                      }}
                      className="flex h-11 w-full bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 border-0 focus:ring-0 focus:ring-offset-0"
                      style={{ boxShadow: 'none' }}
                    />
                  </div>
                ) : null}

                {/* spinner while request is being fetched */}
                {loading === true ? (
                  <div className="my-4 w-full flex justify-center items-center">
                    <LoadingSpinner className="w-4 h-4" />
                  </div>
                ) : (
                  <ul className="max-h-[300px] overflow-auto p-1">
                    {searchResultFlag === false && (
                      <li className="py-2 px-2 text-sm text-muted-foreground">
                        No seller found.
                      </li>
                    )}
                    {filteredSellers
                      ?.slice(0, visibleSellers)
                      ?.map((seller, i) => (
                        <li key={i} className="relative px-2 py-1.5">
                          <div className="flex flex-col">
                            <div className="flex items-center">
                              <span className="font-medium text-sm">
                                {seller.name}
                                <span className="text-muted-foreground ml-1">
                                  (
                                  {
                                    regionAbbreviations[
                                      seller.marketplace_region
                                    ]
                                  }
                                  )
                                </span>
                              </span>
                            </div>

                            <div>
                              {seller.marketplaces?.length > 0 &&
                              seller?.ingest_completed_at ? (
                                <div className="space-y-0.5">
                                  {seller.marketplaces.map((marketplace, j) => (
                                    <div
                                      key={j}
                                      onClick={() =>
                                        primarySellerClick(
                                          `${seller.public_id},${marketplace.marketplace_id}`
                                        )
                                      }
                                      className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-4 pr-2 text-sm outline-none hover:bg-accent hover:text-accent-foreground"
                                    >
                                      <RadioGroup
                                        value={`${seller.public_id},${seller.primary_marketplace_id}`}
                                        onValueChange={(value) =>
                                          primarySellerClick(value)
                                        }
                                        className="flex items-center space-x-2 w-full"
                                      >
                                        <div className="flex items-center space-x-2">
                                          <RadioGroupItem
                                            value={`${seller.public_id},${marketplace.marketplace_id}`}
                                            checked={
                                              seller.primary_marketplace_id ===
                                                marketplace.marketplace_id &&
                                              seller.public_id ===
                                                primarySeller.public_id
                                            }
                                            className="h-3.5 w-3.5"
                                          />
                                          <span className="text-sm text-muted-foreground font-medium">
                                            {marketplace.country}
                                          </span>
                                        </div>
                                      </RadioGroup>
                                    </div>
                                  ))}
                                </div>
                              ) : user.company.managed_sellers.filter(
                                  (s) => s.public_id === seller.public_id
                                ).length > 0 ? (
                                <div className="py-1.5 pl-4 pr-2">
                                  <span className="text-sm text-muted-foreground/80 italic text-nowrap">
                                    Integration incomplete
                                  </span>
                                </div>
                              ) : (
                                <button
                                  className="relative flex w-full items-center py-1.5 pl-4 pr-2 text-sm text-primary hover:text-primary/80 hover:bg-accent rounded-sm"
                                  onClick={(e) =>
                                    completeOnboardingClick(e, seller)
                                  }
                                >
                                  <span className=" text-nowrap">
                                    Finish Amazon Setup
                                  </span>
                                  <svg
                                    className="w-3.5 h-3.5 ml-1.5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M9 5l7 7-7 7"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className={`flex items-center space-x-4 z-50`}>
          <Dialog
            open={feedbackDialogOpen}
            onOpenChange={setFeedbackDialogOpen}
          >
            <DialogTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="text-muted-foreground transition-colors hover:text-foreground border border-gray-300 px-4 py-2 hidden lg:block"
              >
                Feedback
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] max-w-[300px] rounded-lg">
              <DialogHeader>
                <DialogTitle className="font-geist font-normal tracking-wide">
                  Your Feedback
                </DialogTitle>
              </DialogHeader>
              <form onSubmit={sendFeedbackClick} className="grid gap-4">
                <Textarea
                  placeholder="Help us improve this page."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="h-24 font-geist font-medium"
                  required
                />
                <Button type="submit" disabled={loading}>
                  {loading ? <LoadingSpinner /> : 'Send Feedback'}
                </Button>
              </form>
            </DialogContent>
          </Dialog>
          <a
            href="https://news.autron.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="ghost"
              size="sm"
              className="text-muted-foreground transition-colors hover:text-foreground hidden lg:block"
            >
              News
            </Button>
          </a>
          <a
            href={process.env.REACT_APP_HELP_CENTER_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="ghost"
              size="sm"
              className="text-muted-foreground transition-colors hover:text-foreground hidden lg:block"
            >
              Help
            </Button>
          </a>
          <Button
            variant="ghost"
            size="icon"
            className="text-muted-foreground hover:text-foreground hidden md:flex justify-center items-center"
            onClick={handleTheme}
          >
            {lightMode ? (
              <Sun className="h-5 w-5" />
            ) : (
              <Moon className="h-5 w-5" />
            )}
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="hover:text-foreground hidden sm:block"
              >
                <Avatar>
                  <AvatarImage
                    src="/placeholder.svg?height=40&width=40"
                    alt="User"
                  />
                  <AvatarFallback className="font-geist font-medium">
                    {user.firstname[0] + user.lastname[0]}
                  </AvatarFallback>
                </Avatar>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="z-[10000] font-geist font-medium">
              <DropdownMenuItem asChild>
                <Link to="/admin/account">Account</Link>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={(e) => logoutClick(e)}>
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu
            open={menuDropdownOpen}
            onOpenChange={setMenuDropdownOpen}
            className="font-geist font-medium"
          >
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="flex justify-center items-center lg:hidden"
              >
                <Menu className="h-6 w-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-[200px] lg:hidden flex flex-col z-[10000] font-geist font-medium"
            >
              <div className="flex flex-col items-start">
                {navbarRoutes.map((prop, key) => {
                  return (
                    <NavLink
                      key={key}
                      to={prop.layout + prop.path.split('/:')[0]}
                      className="w-full cursor-pointer hover:bg-foreground-muted"
                    >
                      <DropdownMenuItem
                        key={key}
                        variant="ghost"
                        size="sm"
                        className={`w-full ${
                          selectedMenuItem === prop.name
                            ? 'text-foreground transition-colors hover:text-foreground'
                            : 'text-muted-foreground transition-colors hover:text-foreground'
                        } text-sm text-[14px] cursor-pointer font-geist font-medium tracking-wide`}
                      >
                        {prop.name}
                      </DropdownMenuItem>
                    </NavLink>
                  )
                })}
              </div>
              <Separator className="mt-2 mb-2" />
              <div>
                <DropdownMenuItem
                  onSelect={(e) => e.preventDefault()}
                  className="md:hidden cursor-pointer"
                >
                  <div
                    className="flex items-center justify-between w-full"
                    onClick={handleTheme}
                  >
                    <span
                      className={`w-full ${
                        selectedMenuItem === 'Theme'
                          ? 'text-foreground transition-colors hover:text-foreground'
                          : 'text-muted-foreground transition-colors hover:text-foreground'
                      } text-sm`}
                    >
                      Theme
                    </span>
                    <Button variant="ghost" className="ml-2 p-0 mr-2 h-2">
                      {theme === 'light' ? (
                        <Sun className="h-4 w-4" />
                      ) : (
                        <Moon className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                </DropdownMenuItem>
                <Dialog
                  open={feedbackDialogOpen}
                  onOpenChange={setFeedbackDialogOpen}
                >
                  <DialogTrigger asChild>
                    <DropdownMenuItem
                      className={`w-full ${
                        selectedMenuItem === 'Feedback'
                          ? 'text-foreground transition-colors hover:text-foreground'
                          : 'text-muted-foreground transition-colors hover:text-foreground'
                      } text-sm cursor-pointer`}
                    >
                      Feedback
                    </DropdownMenuItem>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle className="font-geist font-medium tracking-wide">
                        Your Feedback
                      </DialogTitle>
                    </DialogHeader>
                    <form onSubmit={sendFeedbackClick} className="grid gap-4">
                      <Textarea
                        placeholder="Help us improve this page."
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        className="h-24"
                        required
                      />
                      <Button type="submit" disabled={loading}>
                        {loading ? <LoadingSpinner /> : 'Send Feedback'}
                      </Button>
                    </form>
                  </DialogContent>
                </Dialog>
                <a
                  href="https://news.autron.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DropdownMenuItem
                    className={`w-full ${
                      selectedMenuItem === 'News'
                        ? 'text-foreground transition-colors hover:text-foreground'
                        : 'text-muted-foreground transition-colors hover:text-foreground'
                    } text-sm cursor-pointer`}
                  >
                    News
                  </DropdownMenuItem>
                </a>
                <a
                  href={process.env.REACT_APP_HELP_CENTER_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DropdownMenuItem
                    className={`w-full ${
                      selectedMenuItem === 'News'
                        ? 'text-foreground transition-colors hover:text-foreground'
                        : 'text-muted-foreground transition-colors hover:text-foreground'
                    } text-sm cursor-pointer`}
                  >
                    Help
                  </DropdownMenuItem>
                </a>
                <DropdownMenuItem
                  className="sm:hidden"
                  onClick={(e) => logoutClick(e)}
                >
                  Log out
                </DropdownMenuItem>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </Card>
      <Card
        className={`hidden sticky ${
          hasAlert ? 'top-9' : 'top-0'
        } border-t-0 border-r-0 border-l-0 shadow-none rounded-none w-full h-14 lg:flex lg:items-center z-50`}
      >
        <div
          className={`flex space-x-2`}
          style={{
            transform: `translateX(${navX}px)`,
          }}
        >
          {navbarRoutes.map((prop, key) => {
            return (
              <NavLink
                to={prop.layout + prop.path.split('/:')[0]}
                onClick={() => setSelectedMenuItem(prop.name)}
                key={key}
              >
                <Button
                  key={key}
                  variant="ghost"
                  size="sm"
                  className={`${
                    selectedMenuItem === prop.name
                      ? 'text-foreground transition-colors hover:text-foreground'
                      : 'text-muted-foreground transition-colors hover:text-foreground'
                  } text-sm text-[14px] font-geist font-medium tracking-wide`}
                >
                  {prop.name}
                </Button>
              </NavLink>
            )
          })}
        </div>
      </Card>
    </>
  )
}

export default AdminNavbar
