import React, { useState, useContext } from 'react'

import { Card, CardContent, CardFooter } from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import {
  Globe2,
  ShoppingCart,
  LayoutGrid,
  Coins,
  Rocket,
  BarChart3,
} from 'lucide-react'

import apiPartners from 'services/api/Partner'
import myosLogoImg from 'assets/img/myos-logo-reverse.svg'
import myosLogoLightMode from 'assets/img/myos-logo-light-mode.svg'
import accelClubLogoImg from 'assets/img/accel-club-logo.jpeg'
import accelClubDistributionOfferImg from 'assets/img/accel-club-distribution-offer.jpg'
import ThemeContext from 'services/providers/ThemeContext'
import { useToast } from 'shadcn-components/ui/use-toast'
import { Separator } from 'shadcn-components/ui/separator'

const Partners = ({ user }) => {
  const { toast } = useToast()

  const [fundMeLoading, setFundMeLoading] = useState(false)
  const [accelClubLoading, setAccelClubLoading] = useState(false)
  const { lightMode } = useContext(ThemeContext)

  const handlePartnerBtnClick = async (partnerName, setLoading) => {
    setLoading(true)
    const data = { partner_name: partnerName }

    try {
      const response = await apiPartners.generatePartnerLead(data)

      if (response && response.status < 300) {
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        // Uncomment the following line if you want to redirect based on the response
        // if (response.data.link) window.location.href = response.data.link;
      } else {
        toast({
          variant: 'destructive',
          desciption: response?.data?.message || 'An error occurred',
        })
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        desciption: 'An error occurred',
      })
    } finally {
      setLoading(false)
    }
  }

  const fundMeBtnClick = (e) => handlePartnerBtnClick('myos', setFundMeLoading)
  const accelClubBtnClick = (e) =>
    handlePartnerBtnClick('accel_club', setAccelClubLoading)

  return (
    <>
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto">
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-1">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Partners
            </h2>
            <p className="text-muted-foreground font-geist font-normal tracking-normal">
              Collaborate with key partners to drive growth and success for your
              business.
            </p>
          </div>
        </div>

        <Card className="mb-8">
          <CardContent className="px-6 py-4">
            <div className="flex flex-col lg:flex-row items-center mb-8 p-8">
              <div className="lg:w-1/4 mb-4 lg:mb-0">
                <img
                  src={accelClubLogoImg}
                  alt="Accel Club logo"
                  width={200}
                  height={100}
                  className="object-contain"
                />
              </div>
              <div className="lg:w-3/4">
                <p className="text-muted-foreground mb-4 text-xl">
                  We're partnering with Accel Club to help you tap into the $100
                  billion EU & UK market, expanding your brand in a region
                  growing 43% faster than Amazon in North America.
                </p>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 gap-8 p-8">
              <div>
                <div className="flex items-center mb-4">
                  <Globe2 className="w-8 h-8 mr-2 stroke-1" />
                  <h3 className="text-xl font-space-grotesk font-normal">
                    European Expansion
                  </h3>
                </div>
                <Separator className="h-1 w-20 mb-4" />
                <p className="text-muted-foreground">
                  Expand risk-free with no costs or long-term contracts. We
                  manage all operations, allowing you to maintain focus on your
                  primary market. See sales in just 4 months and earn a share of
                  the profit monthly, with the flexibility to take back control
                  whenever you're ready.
                </p>
              </div>
              <div>
                <img
                  src={accelClubDistributionOfferImg}
                  alt="Accel Club distribution offer"
                  width={600}
                  height={350}
                  className="object-cover rounded-lg"
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="px-14">
            <Button onClick={accelClubBtnClick} disabled={accelClubLoading}>
              {accelClubLoading ? (
                <LoadingSpinner className="mr-2" />
              ) : (
                <Rocket className="w-5 h-5 mr-2" />
              )}
              Express interest
            </Button>
          </CardFooter>
        </Card>

        <Card>
          <CardContent className="px-6 py-4 flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row lg:gap-20 items-center mb-8 p-8">
              <div className="lg:w-1/4 mb-4 lg:mb-0 px-0 py-4">
                <img
                  src={lightMode ? myosLogoLightMode : myosLogoImg}
                  alt="Myos logo"
                  width={350}
                  height={150}
                  className="object-contain"
                />
              </div>
              <div className="flex items-center justify-center">
                <p className="text-muted-foreground text-xl">
                  Autron's teamed up with Myos to offer growth capital at a
                  preferred rate, designed specifically for e-commerce
                  businesses.
                </p>
              </div>
            </div>
            <div className="grid md:grid-cols-3 gap-8 p-8">
              <div>
                <div className="flex items-center mb-4">
                  <ShoppingCart className="w-8 h-8 stroke-1 mr-2" />
                  <h3 className="text-xl font-space-grotesk font-normal">
                    Purchase Finance
                  </h3>
                </div>
                <Separator className="h-1 w-20 mb-4" />
                <p className="text-muted-foreground">
                  We will take care of the deposit and balance payment of your
                  order. If you have already paid for the goods, you will get
                  the money back from us.
                </p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <LayoutGrid className="w-8 h-8 mr-2 stroke-1" />
                  <h3 className="text-xl font-space-grotesk font-normal">
                    Stock Finance
                  </h3>
                </div>
                <Separator className="h-1 w-20 mb-4" />
                <p className="text-muted-foreground">
                  Leverage your current inventory and fuel your growth. You
                  assign a portion of your stock as collateral and get capital
                  in return.
                </p>
              </div>
              <div>
                <div className="flex items-center mb-4">
                  <Coins className="w-8 h-8 mr-2 stroke-1" />
                  <h3 className="text-xl font-space-grotesk font-normal">
                    Cross Finance
                  </h3>
                </div>
                <Separator className="h-1 w-20 mb-4" />
                <p className="text-muted-foreground">
                  We offer you flexible use of funds based on your current
                  products - an alternative to factoring.
                </p>
              </div>
            </div>
          </CardContent>
          <CardFooter className="py-8 px-14">
            <Button onClick={fundMeBtnClick} disabled={fundMeLoading}>
              {fundMeLoading ? (
                <LoadingSpinner className="mr-2" />
              ) : (
                <BarChart3 className="w-5 h-5 mr-2" />
              )}
              Get a free quote
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  )
}

export default Partners
