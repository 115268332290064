import React, { createContext, useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import useStripeSetup from '../hooks/useStripeSetup'

const StripeContext = createContext(null)

export const StripeProvider = ({ children }) => {
  const [stripe, setStripe] = useState(null)
  const { setup, isLoading } = useStripeSetup()

  useEffect(() => {
    // This effect should depend on `setup`, not on `stripe`
    if (setup?.publishable_key && !stripe) {
      loadStripe(setup.publishable_key)
        .then(setStripe)
        .catch((error) => {
          console.error('Error loading Stripe:', error)
        })
    }
  }, [setup])

  return (
    <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>
  )
}

export default StripeContext
