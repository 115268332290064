import React, { useState, useEffect, Fragment, useContext } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { Link, useLocation } from 'react-router-dom'

import { Card, CardTitle, CardContent } from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import ThemeContext from 'services/providers/ThemeContext'

const PPCAuditResult = () => {
  const params = new URLSearchParams(useLocation().search)
  const [loading, setLoading] = useState(true)
  const { lightMode } = useContext(ThemeContext)
  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL

  const [iframeUrl, setIframeUrl] = useState(analyticsUrl)

  const queryClient = useQueryClient()
  const encoded_public_id = params.get('encoded_public_id')
  const [error, setError] = useState(
    encoded_public_id ? '' : 'No Results Found'
  )

  // Use React Query to fetch the iframeUrl if sellerId and marketplaceId are available
  const embedUrlParam = {
    resource: { dashboard: 13 },
    params: {
      encoded_public_id: encoded_public_id,
    },
  }

  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed/ppc-audit', embedUrlParam],
    () => apiAnalytics.generateEmbedUrlPPCAudit(embedUrlParam),
    {
      enabled: !!encoded_public_id, // Only run this query if sellerId and marketplaceId are available
      staleTime: 10 * 60 * 1000,
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          setError('Failed to load dashboard. Please try again later.')
        }
      },
      onError: () => {
        setError('Failed to load dashboard. Please try again later.')
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed/ppc-audit',
      embedUrlParam,
    ])

    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)
    }
  }, [queryClient, embedUrlParam])

  return (
    <>
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto shadow-none border-none min-h-screen">
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-1">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              {' '}
              Amazon PPC Audit Report
            </h2>
            <p className="text-muted-foreground font-geist font-normal tracking-normal">
              Gain Clear Insights into Your Amazon PPC Performance with Our
              Comprehensive Audit Report{' '}
            </p>
          </div>
        </div>

        <Card>
          <CardContent
            className={`pt-6 ${
              error
                ? 'flex flex-col items-center justify-center min-h-[400px]'
                : ''
            }`}
          >
            {error && (
              <div className="text-center">
                <CardTitle className="text-2xl mb-4 font-space-grotesk tracking-normal font-normal">
                  No Results Found
                </CardTitle>
                <p className="mb-6 text-muted-foreground">
                  Please click the link in the email or request a PPC Audit
                  below
                </p>
                <Button asChild>
                  <Link to="/ppc-audit">Get PPC Audit</Link>
                </Button>
              </div>
            )}
            {loading && !error && (
              <div className="space-y-4 px-8 py-12 w-full">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-4 w-1/2" />
                <div className="grid grid-cols-3 gap-4 pt-4">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
                <div className="grid grid-cols-3 gap-4 pt-4">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
                <div className="grid grid-cols-3 gap-4 pt-4">
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                  <Skeleton className="h-40 w-full" />
                </div>
              </div>
            )}

            {iframeUrl !== analyticsUrl && (
              <IframeResizer
                src={iframeUrl}
                style={{
                  width: '1px',
                  minWidth: '100%',
                  minHeight: '62.5em',
                }}
                onLoad={() => setLoading(false)}
              />
            )}
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default PPCAuditResult
