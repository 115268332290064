import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'analytics'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiAnalytics = new ApiCore({
  url: url,
})

apiAnalytics.generateEmbedUrl = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/embed`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiAnalytics.generateEmbedUrlPPCAudit = (data, withCredentials = true) =>{
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/embed/ppc-audit`,data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiAnalytics.card = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/card`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

apiAnalytics.fieldValues = (data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/field/values`, data, { withCredentials: withCredentials })
    .then(handleResponse)
    .catch(handleError)
}

export default apiAnalytics
