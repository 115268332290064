import React, { useContext } from 'react'
import ThemeContext from 'services/providers/ThemeContext'
import { Card } from 'shadcn-components/ui/card'

export default function Footer() {
  const { lightMode } = useContext(ThemeContext)
  console.log({ lightMode: lightMode })
  return (
    <Card
      className={`pt-36 pb-14 md:px-8 ${
        lightMode === true ? 'bg-white' : 'bg-black'
      } shadow-none border-none rounded-none`}
    >
      <div className="flex flex-col items-center justify-between gap-6">
        <ul className="flex flex-wrap items-center gap-6 md:gap-7">
          <li>
            <a
              href={process.env.REACT_APP_FRONTEND_APP_BASE_URL}
              className="text-muted-foreground hover:text-foreground transition-colors"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_FRONTEND_APP_BASE_URL}/blog`}
              className="text-muted-foreground hover:text-foreground transition-colors"
            >
              Blog
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_HELP_CENTER_URL}
              className="text-muted-foreground hover:text-foreground transition-colors"
            >
              Help Center
            </a>
          </li>
        </ul>
        <p className="text-muted-foreground hover:text-foreground transition-colors cursor-pointer">
          © {new Date().getFullYear()} Autron, Inc.
        </p>
      </div>
    </Card>
  )
}
