import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { generateRandomString } from 'services/Utils'
import { getAuthToken } from 'variables/authToken'
import { sellerAndVendorCentralUrls } from 'services/Utils'
import { CardTitle, CardDescription } from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Link } from 'lucide-react'
import { useToast } from 'shadcn-components/ui/use-toast'

const OnboardingSellerStep1 = forwardRef((props, ref) => {
  const { toast } = useToast()
  const params = new URLSearchParams(useLocation().search)
  const [status] = useState(params.get('status'))
  const [message] = useState(params.get('message'))

  const [spapiAccess] = useState(props.spapiAccess)
  const [marketplaceId, setMarketplaceId] = useState(props.marketplaceId)
  const [publicId, setPublicId] = useState(props.publicId)
  const history = useHistory()

  useEffect(() => {
    setMarketplaceId(props.marketplaceId)
    setPublicId(props.publicId)
  }, [props])

  useEffect(() => {
    if (status && message) {
      if (status === 'fail') {
        toast({ variant: 'destructive', description: message })
      }
      if (status === 'success') {
        toast({ variant: 'success', description: message })
      }
      // don't want noti to fire again incase of refresh
      params.delete('status')
      params.delete('message')
      history.replace({ search: params.toString() })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const startAmazonSellingPartnerOAuth = async () => {
    let baseUrl
    // Use authToken as state and thus backend api auth on redirect
    const authToken = getAuthToken()

    // Inspired by https://github.com/aaronpk/pkce-vanilla-js/blob/master/index.html
    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var code_verifier = generateRandomString()

    // https://github.com/amzn/selling-partner-api-docs/blob/main/guides/en-US/developer-guide/SellingPartnerApiDeveloperGuide.md#seller-central-urls
    // this should be in marketplace model not hardcoded in frontend...
    baseUrl =
      sellerAndVendorCentralUrls(marketplaceId, 'seller') +
      '/apps/authorize/consent' // TODO support 'vendor'

    // Build the amazon oauth URL
    var url = new URL(baseUrl)
    url.searchParams.append(
      'application_id',
      process.env.REACT_APP_AMAZON_SP_API_APP_ID
    )
    if (process.env.NODE_ENV === 'development') {
      url.searchParams.append(
        'redirect_uri',
        'https://api.autron.ai/auth/amazon-sp-api/cb'
      ) // use this for testing change to http://127.0.0.1:5000 after amazon redirect
    } else {
      url.searchParams.append(
        'redirect_uri',
        [
          process.env.REACT_APP_BACKEND_API_BASE_URL,
          '/auth/amazon-sp-api/cb',
        ].join('')
      )
    }
    url.searchParams.append(
      'state',
      [authToken, publicId, code_verifier].join(' ')
    )
    if (process.env.NODE_ENV === 'development')
      url.searchParams.append('version', 'beta') // use to test draft or pending app

    // Redirect to the amazon authorization server
    window.location = url
  }

  const isValidated = () => {
    if (spapiAccess || status) {
      toast({
        variant: 'success',
        description: 'Successfully authorized as Amazon Selling Partner',
      })
      return true
    } else {
      toast({
        variant: 'destructive',
        title: 'Oops!',
        description:
          'Amazon Selling Partner authorization failed, please try again.',
      })
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
  }))

  return (
    <>
      <div className="flex flex-col gap-4 p-4">
        <CardTitle className="text-2xl text-center font-space-grotesk font-normal">
          Authorize as Amazon Selling Partner
        </CardTitle>
        <CardDescription className="text-base text-center font-normal">
          Grant Autron permission to help manage your Amazon business.
        </CardDescription>
        <div className="mx-auto w-full flex justify-center">
          <Button
            disabled={spapiAccess}
            className=""
            color="primary"
            onClick={(e) => startAmazonSellingPartnerOAuth()}
          >
            {spapiAccess ? (
              <div className="flex justify-center items-center gap-2">
                <Link className="h-3 w-3 inline" /> Authorized as Selling
                Partner
              </div>
            ) : (
              <div>Authorize Selling Partner</div>
            )}
          </Button>
        </div>
        {spapiAccess ? (
          <div className="w-full flex justify-center items-center">
            <p className="mb-0">
              <span
                className="underline cursor-pointer"
                onClick={(e) => startAmazonSellingPartnerOAuth()}
              >
                Need to reauthorize?
              </span>
            </p>
          </div>
        ) : null}
      </div>
    </>
  )
})

export default OnboardingSellerStep1
