import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Button } from 'shadcn-components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from 'shadcn-components/ui/sheet'
import { Card } from 'shadcn-components/ui/card'
import ThemeContext from 'services/providers/ThemeContext'
import { Menu } from 'lucide-react'
import { Link } from 'react-router-dom'
import autron_logo_light_mode from 'assets/img/autron_new_logo_light_mode.png'
import autron_logo_dark_mode from 'assets/img/autron_new_logo_dark_mode.png'

const MainNavbar = () => {
  const { lightMode } = useContext(ThemeContext)
  const autronLogo = lightMode ? autron_logo_light_mode : autron_logo_dark_mode
  const [logo, setLogo] = useState(autronLogo)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Card
        className={`flex items-center justify-between px-4 py-4 rounded-none  border-t-0 border-r-0 border-l-0 sticky top-0 z-50 md:px-8`}
      >
        <a
          href={process.env.REACT_APP_FRONTEND_APP_BASE_URL}
          className={`flex items-center hover:cursor-pointer left-5 top-3 ${
            lightMode ? 'bg-white' : ''
          }`}
        >
          <img
            src={lightMode ? autron_logo_light_mode : autron_logo_dark_mode}
            alt="Autron Logo"
            width={128}
            className="object-contain"
          />
        </a>
        <div className="hidden md:flex items-center space-x-8">
          <a
            href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/pricing'}
            className="text-base font-geist font-medium tracking-normal"
          >
            Pricing
          </a>
          <a
            href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/blog'}
            className="text-base font-geist font-medium tracking-normal"
          >
            Blog
          </a>
          <a
            href={process.env.REACT_APP_HELP_CENTER_URL}
            className="text-base font-geist font-medium tracking-normal"
          >
            Help
          </a>
          <a
            href="/auth/login"
            className="text-base font-geist font-medium tracking-normal"
          >
            Login
          </a>
          <Button
            asChild
            className="text-base font-geist font-medium tracking-normal"
          >
            <a href="/auth/register">Get Started</a>
          </Button>
        </div>
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild className="md:hidden">
            <Button variant="outline" size="icon">
              <Menu className="h-6 w-6" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="right" className="w-[300px] sm:w-[400px]">
            <nav className="flex flex-col gap-4">
              <a
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/pricing'}
                className="text-base font-geist font-medium tracking-normal text-gray-700 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                Pricing
              </a>
              <a
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/blog'}
                className="text-base font-geist font-medium tracking-normal text-gray-700 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                Blog
              </a>
              <a
                href={process.env.REACT_APP_HELP_CENTER_URL}
                className="text-base font-geist font-medium tracking-normal text-gray-700 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                Help
              </a>
              <Link
                to="/auth/login"
                className="text-base font-geist font-medium tracking-normal text-gray-700 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                Login
              </Link>
              <Button asChild onClick={() => setIsOpen(false)}>
                <Link
                  to="/auth/register"
                  className="font-geist font-medium tracking-normal"
                >
                  Get Started
                </Link>
              </Button>
            </nav>
          </SheetContent>
        </Sheet>
      </Card>
    </>
  )
}

export default MainNavbar
