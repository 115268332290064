import React, { forwardRef, useImperativeHandle } from 'react'
import { CheckCircle2 } from 'lucide-react'

const OnboardingSellerStep3 = forwardRef((props, ref) => {
  const isValidated = () => {
    if (true) {
      return true
    } else {
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
    state: {},
  }))

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-3 p-4">
        <div className="relative">
          <CheckCircle2 className="w-16 h-16 text-lime-300" />
        </div>
        <h3 className="text-2xl font-space-grotesk text-center">Good job!</h3>
        <p className="text-center text-muted-foreground font-normal">
          Your Amazon account is successfully connected.
        </p>
      </div>
    </>
  )
})

export default OnboardingSellerStep3
