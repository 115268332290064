export const segmentPage = (history, prevPath) => {
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname
      if (window.analytics && process.env.REACT_APP_SEGMENT_TRACKING) {
        window.analytics.page()
      }
    }
  })
}

export const segmentIdentify = (user) => {
  if (window.analytics && process.env.REACT_APP_SEGMENT_TRACKING) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    window.analytics.identify(user.public_id, {
      // reserved traits
      first_name: user.firstname,
      last_name: user.lastname,
      name: String(user.firstname) + ' ' + String(user.lastname), // for intercom
      user_hash: user.intercom_identity_verification_hash, // for intercom
      company: {
        id: user.company.public_id,
        name: user.company.name,
      },
      email: user.email,
      phone: user.phone,
      created_at: user.registered_at,
      // custom traits (always use snake case!)
      email_verified: user.email_verified,
      timezone: tz ? tz : '', // for customer.io
      managing_company: {
        id: user.company.managing_company.public_id
          ? user.company.managing_company.public_id
          : '',
        name: user.company.managing_company.name
          ? user.company.managing_company.name
          : '',
      },
      seller: {
        name: user.primary_seller ? user.primary_seller.name : '',
        id: user.primary_seller ? user.primary_seller.selling_partner_id : '',
        marketplace_id: user.primary_seller
          ? user.primary_seller.primary_marketplace_id
          : '',
        marketplace: user.primary_seller
          ? user.primary_seller.primary_marketplace_country
          : '',
      },
    })
  }
}

export const segmentReset = () => {
  // since can't do this on backend
  if (window.analytics && process.env.REACT_APP_SEGMENT_TRACKING) {
    window.analytics.reset()
  }
}

export const segmentCheckoutStarted = (user, data) => {
  if (window.analytics && process.env.REACT_APP_SEGMENT_TRACKING) {
    window.analytics.track(
      'Checkout Started',
      {
        price_id: data.price_id,
        usage_type: data.usage_type,
        customer_id: data.customer_id,
        customer_email: data.customer_email,
      },
      {
        context: {
          groupId: user.company.public_id,
        },
      }
    )
  }
}

export const segmentCheckoutCancelled = (user) => {
  if (window.analytics && process.env.REACT_APP_SEGMENT_TRACKING) {
    window.analytics.track(
      'Checkout Cancelled',
      {
        customer_id: user.company.stripe_customer_id,
        customer_email: user.email,
      },
      {
        context: {
          groupId: user.company.public_id,
        },
      }
    )
  }
}
