import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import apiAuth from 'services/api/Auth'
import apiStripe from 'services/api/Stripe'
import { X } from 'lucide-react'

const AlertCountdown = () => {
  const [daysLeft, setDaysLeft] = useState(null)
  const [subscriptionItem, setSubscriptionItem] = useState(null)
  const [isVisible, setIsVisible] = useState(true)

  useQuery('GET/auth/user', () => apiAuth.user(), {
    retry: false,
    onSuccess: (user) => {
      if (user.company.stripe_customer_id) {
        getSubscriptionItem(user)
      } else {
        setSubscriptionItem(null)
      }
    },
  })

  const getSubscriptionItem = async (user) => {
    while (!subscriptionItem) {
      try {
        const { data } = await apiStripe.getSubscriptionItem(
          user.company.stripe_customer_id
        )
        if (data) {
          setSubscriptionItem(data)
          return
        }
      } catch (error) {
        setDaysLeft(null)
        setSubscriptionItem(null)
      }
      // Wait for four seconds before retrying
      await new Promise((resolve) => setTimeout(resolve, 4000))
    }
  }

  useEffect(() => {
    if (!subscriptionItem) {
      setDaysLeft(null)
      return
    }

    if (subscriptionItem.subscription_status === 'trialing') {
      const currentDate = new Date()

      const subscriptionTrialEnd = new Date(
        subscriptionItem.subscription_trial_end
      )

      // Compute the time difference in milliseconds
      const timeDifference =
        subscriptionTrialEnd.getTime() - currentDate.getTime()

      // Compute the difference in days
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

      setDaysLeft(daysDifference)
    }
  }, [subscriptionItem])

  const handleClose = () => {
    setIsVisible(false)
  }

  return (
    <>
      {daysLeft >= 0 && daysLeft != null && isVisible && (
        <div
          className={`dark:bg-yellow-300 bg-yellow-200 sticky top-0 font-geist font-normal text-slate-800 text-center py-[0.40rem] transition-all duration-500 ease-in-out ${
            daysLeft >= 0 && daysLeft != null && isVisible
              ? 'translate-y-0'
              : '-translate-y-full'
          } hover:bg-yellow-300 cursor-pointer shadow-md z-[10000] flex justify-center gap-3`}
          role="trialAlert"
          aria-live="polite"
        >
          <p className="text-center">
            Your trial ends in{' '}
            <span className="font-semibold">
              {daysLeft} {daysLeft === 1 ? 'day' : 'days'}
            </span>
          </p>
          <div className="p-1 hover:bg-yellow-200 rounded-md">
            <X
              className="h-4 w-4 text-slate-800 cursor-pointer"
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AlertCountdown
