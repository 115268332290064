import React, { useState } from 'react'
import { useQuery } from 'react-query'

import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
} from 'shadcn-components/ui/card'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'

import CheckoutForm from './CheckoutForm'

import apiAuth from 'services/api/Auth'
import { filterByStatuses } from 'services/Utils'
const Payment = () => {
  const [user, setUser] = useState(null)

  useQuery('GET/auth/user', () => apiAuth.user(), {
    onSuccess: (data) => {
      setUser(data)
    },
  })

  return (
    <>
      <Card className="mt-5 lg:mt-0 border-none shadow-none">
        <div>
          <CardHeader className="pt-0 pb-3 px-3 sm:px-6">
            <CardTitle className="text-2xl font-normal font-space-grotesk tracking-normal">
              Ready to turbocharge your Amazon Advertising?
            </CardTitle>
          </CardHeader>
          <CardContent className="px-3 sm:px-6">
            {user ? (
              <>
                <p className="text-gray-600 mb-4">
                  {filterByStatuses(user?.company?.stripe_subscription_items, [
                    'canceled',
                  ]) !== null
                    ? "Before we continue, we'll need to update your payment details to resume your Pro subscription. A payment is due today, and you'll be billed monthly based on your usage. We'll notify you a few days before each billing cycle."
                    : "Before we begin, we'll need your card for billing purposes. You won't be charged during the trial, and we'll send you a reminder a few days before the trial ends about the upcoming renewal date."}
                </p>
                <CheckoutForm user={user} />
              </>
            ) : (
              <div className="flex justify-center items-center h-40">
                <LoadingSpinner className="h-4 w-4" aria-label="Loading" />
              </div>
            )}
          </CardContent>
        </div>
      </Card>
    </>
  )
}

export default Payment
