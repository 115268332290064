import React from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import AuthRoute from 'components/AuthRoute/AuthRoute.js'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop.js'

import MainLayout from 'layouts/Main/Main.js'
import AuthLayout from 'layouts/Auth/Auth.js'
import AdminLayout from 'layouts/Admin/Admin.js'
import { segmentPage } from 'services/Segment'

export const history = createBrowserHistory()
let prevPath = null

// notify Segment of client-side page updates
segmentPage(history, prevPath)

export const AppRoutes = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <AuthRoute path="/admin" layout={<AdminLayout />} />
          <Route path="/" render={(props) => <MainLayout {...props} />} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}
