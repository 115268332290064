import axios from 'axios'
import { handleResponse, handleError } from './utilities/Response'
import { ApiCore } from './utilities/Core'
import { setAuthorizationHeader } from './utilities/Provider'

// User API
const url = 'users'

// plural and single may be used for message logic if needed in the ApiCore class.

const apiUsers = new ApiCore({
  getAll: true,
  getSingle: true,
  createSingle: true,
  createSingleCredentials: false,
  updateSingle: true,
  url: url,
})

apiUsers.send_email = (id, data, withCredentials = true) => {
  setAuthorizationHeader(withCredentials)
  return axios
    .post(`/${url}/${id}/send-email`, data, {
      withCredentials: withCredentials,
    })
    .then(handleResponse)
    .catch(handleError)
}

export default apiUsers
