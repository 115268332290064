import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from 'shadcn-components/ui/card'
import { Label } from 'shadcn-components/ui/label'
import { Button } from 'shadcn-components/ui/button'
import { Textarea } from 'shadcn-components/ui/textarea'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { inputFieldChange, cartesian } from 'services/Utils'
import { useQueryClient } from 'react-query'
import apiProducts from 'services/api/Product'
import { useToast } from 'shadcn-components/ui/use-toast'
import MultiSelect from 'shadcn-components/ui/multiselect'

const ProductTarget = ({
  productTarget,
  productPublicId,
  setShowComponent,
}) => {
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)

  const queryClient = useQueryClient()

  const types = [
    {
      label: 'Keyword',
      value: 'keyword',
    },
    {
      label: 'Product',
      value: 'product',
    },
    {
      label: 'Category',
      value: 'category',
    },
  ]

  const matchTypes = [
    {
      label: 'Exact',
      value: 'exact',
    },
    {
      label: 'Phrase',
      value: 'phrase',
    },
    {
      label: 'Broad',
      value: 'broad',
    },
  ]

  const [type, setType] = useState(productTarget?.type)
  const [matchType, setMatchType] = useState(productTarget?.match_type)
  const [target, setTarget] = useState(productTarget?.target)

  const [typeValidation, setTypeValidation] = useState()
  const [matchTypeValidation, setMatchTypeValidation] = useState()
  const [targetValidation, setTargetValidation] = useState()

  const [typeFocus, setTypeFocus] = useState(false)
  const [matchTypeFocus, setMatchTypeFocus] = useState(false)
  const [targetFocus, setTargetFocus] = useState(false)

  useEffect(() => {
    setType(productTarget?.type)
    setMatchType(
      productTarget?.match_type ? productTarget?.match_type : matchTypes
    )
    setTarget(productTarget?.target)
    setTypeValidation(productTarget?.type ? 'has-success' : '')
    setMatchTypeValidation(productTarget?.match_type ? 'has-success' : '')
    setTargetValidation(productTarget?.target ? 'has-success' : '')
  }, [productTarget])

  const formattedTargets = (targetString) => {
    return [
      ...new Set(
        targetString
          .toLowerCase()
          .split('\n')
          .map((s) => s.trim())
      ),
    ]
  }

  const saveClick = async (e) => {
    if (
      typeValidation === 'has-success' &&
      (type.value === 'keyword'
        ? matchTypeValidation === 'has-success'
        : true) &&
      targetValidation === 'has-success'
    ) {
      setLoading(true)
      const targetData = cartesian(
        [productPublicId],
        [type.value],
        formattedTargets(target),
        type.value === 'keyword'
          ? [
              matchType
                ?.map((m) => m.value)
                .sort(
                  (a, b) =>
                    matchTypes.map((m) => m.value).indexOf(a) -
                    matchTypes.map((m) => m.value).indexOf(b)
                )
                .toString(),
            ]
          : type.value === 'product'
          ? ['asinSameAs']
          : type.value === 'category'
          ? ['asinCategorySameAs']
          : [null],
        ['enabled']
      ).map((t) => ({
        product_public_id: t[0],
        type: t[1],
        target: t[1] === 'product' ? t[2].toUpperCase() : t[2],
        match_type: t[3], //...(t[3] && {match_type: t[3]}),
        state: t[4],
      }))
      // create product target via api
      const response = await apiProducts
        .createTargets(productPublicId, targetData)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/sellers/') // overkill invalidating effectively with wildcard?
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        setShowComponent(false)
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            description: response?.data?.message,
          })
        }
      }
      setLoading(false)
    } else {
      if (typeValidation === '') {
        setTypeValidation('has-danger')
      }
      if (matchTypeValidation === '' && typeValidation === 'keyword') {
        setMatchTypeValidation('has-danger')
      }
      if (targetValidation === '') {
        setTargetValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  const handleSelect = useCallback((option) => {
    setMatchType((prev) => [...prev, option])
  }, [])

  const handleUnselect = useCallback((option) => {
    setMatchType((prev) => prev.filter((p) => p.value !== option.value))
  }, [])

  return (
    <>
      <Card className="w-full lg:w-[700px]">
        <CardContent className="p-6 space-y-4">
          <CardHeader className="pl-0 pt-0 pb-0">
            <h4 className="text-2xl font-space-grotesk font-normal">
              Targeting
            </h4>
          </CardHeader>
          <CardContent className="pl-0 pr-0">
            <div className="space-y-6">
              <div className="flex flex-col gap-2">
                <Label htmlFor="type">Type</Label>
                <div className="col-span-2">
                  <Select
                    value={type?.value}
                    onValueChange={(value) => {
                      const selectedType = types.find((t) => t.value === value)
                      inputFieldChange(
                        selectedType,
                        setType,
                        setTypeValidation,
                        'select_length',
                        1
                      )
                    }}
                  >
                    <SelectTrigger
                      className={
                        typeValidation === 'invalid' ? 'border-red-400' : ''
                      }
                    >
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      {types.map((t) => (
                        <SelectItem key={t.value} value={t.value}>
                          {t.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {typeValidation === 'invalid' && (
                    <p className="text-red-400 text-sm mt-1">
                      This field is required.
                    </p>
                  )}
                </div>
              </div>

              {type?.value === 'keyword' && (
                <div className="flex flex-col gap-2">
                  <Label htmlFor="matchType">Match Type</Label>
                  <MultiSelect
                    options={matchTypes}
                    selectedOptions={matchType}
                    onOptionSelect={handleSelect}
                    onOptionUnselect={handleUnselect}
                    placeholder="Select keywords..."
                  />
                </div>
              )}

              <div className="flex flex-col gap-2">
                <Label htmlFor="targets">Targets</Label>
                <div className="col-span-2">
                  <Textarea
                    id="targets"
                    value={target}
                    placeholder={
                      'Enter your targets and separate each item with a new line' +
                      (type?.value === 'keyword'
                        ? ' e.g yoga mat'
                        : type?.value === 'product'
                        ? ' e.g B01IZDFWY2'
                        : type?.value === 'category'
                        ? ' e.g 3422301'
                        : '')
                    }
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setTarget,
                        setTargetValidation,
                        'length',
                        1
                      )
                    }
                    className={`min-h-[100px] ${
                      targetValidation === 'invalid' ? 'border-red-400' : ''
                    }`}
                  />
                  {targetValidation === 'invalid' && (
                    <p className="text-red-400 text-sm mt-1">
                      This field is required.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-start space-x-2 pl-0">
            <Button
              onClick={(e) => saveClick(e)}
              disabled={loading}
              className="min-w-[116px]"
            >
              {loading ? (
                <LoadingSpinner className="w-4 h-4 mr-2" />
              ) : (
                'Save Targets'
              )}
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowComponent(false)}
              className="px-8"
            >
              Cancel
            </Button>
          </CardFooter>
        </CardContent>
      </Card>
    </>
  )
}

export default ProductTarget
