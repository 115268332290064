import React, { useState, useEffect, useContext } from 'react'
import {
  Card,
  CardContent,
} from 'shadcn-components/ui/card'
import { Label } from 'shadcn-components/ui/label'
import { Input } from 'shadcn-components/ui/input'
import { Button } from 'shadcn-components/ui/button'
import { Switch } from 'shadcn-components/ui/switch'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { Alert, AlertDescription } from 'shadcn-components/ui/alert'
import { useToast } from 'shadcn-components/ui/use-toast'

import { inputFieldChange } from 'services/Utils'

import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { useHistory } from 'react-router-dom'
import apiReviewAutomation from 'services/api/ReviewAutomation'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import { ExternalLink } from 'lucide-react'
import ThemeContext from 'services/providers/ThemeContext'

const Review = ({ user }) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const history = useHistory()
  const { lightMode } = useContext(ThemeContext)
  const [loading, setLoading] = useState(true)
  const [loadingSwitch, setLoadingSwitch] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [reviewState, setReviewState] = useState(false)
  const [solicitationDelay, setSolicitationDelay] = useState(0)
  const [solicitationDelayValidation, setSolicitationDelayValidation] =
    useState('')
  const [publicId, setPublicId] = useState('')
  const minSolicitationDelay = 5
  const maxSolicitationDelay = 30

  const toogleReviewState = async () => {
    const marketplace_id = user.primary_seller.primary_marketplace_id
    const selling_partner_id = user.primary_seller.selling_partner_id

    const profileId = user.primary_seller.profiles.find(
      (item) =>
        item.account_info_marketplace_string_id === marketplace_id &&
        item.account_info_id === selling_partner_id
    )?.profile_id

    const payload = {
      seller_selling_partner_id: selling_partner_id,
      marketplace_marketplace_id: marketplace_id,
      profile_profile_id: profileId,
      state: reviewState ? 'disabled' : 'enabled',
    }

    setLoadingSwitch(true)
    const response = await apiReviewAutomation.setState(payload, true)
    try {
      const response = await apiReviewAutomation.review(payload)

      if (response && response.status === 200) {
        setReviewState(payload.state === 'disabled' ? false : true)
        setLoadingSwitch(false)
      } else {
        toast({
          variant: 'destructive',
          description: response
            ? response.data.message
            : 'Failed to process request.',
        })
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        description: 'Failed to process request due to an error.',
      })
    }
  }

  const updateClick = async () => {
    try {
      const payload = {
        solicitation_delay: solicitationDelay,
        public_id: publicId,
      }
      setLoadingButton(true)
      const response = await apiReviewAutomation.setSolicitationDelay(payload)
      if (response && response.status === 200) {
        const successful = response.data.status === 'success'
        setLoadingButton(false)
        if (successful) {
          toast({
            variant: 'success',
            description: response
              ? response.data.message
              : 'Failed to process request.',
          })
        } else {
          toast({
            variant: 'destructive',
            description: response
              ? response.data.message
              : 'Failed to process request.',
          })
        }
      } else {
        toast({
          variant: 'destructive',
          description: response
            ? response.data.message
            : 'Failed to process request.',
        })
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        description: 'Failed to process request due to an error.',
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const marketplace_id = user.primary_seller.primary_marketplace_id
      const selling_partner_id = user.primary_seller.selling_partner_id

      const profileId = user.primary_seller.profiles.find(
        (item) =>
          item.account_info_marketplace_string_id === marketplace_id &&
          item.account_info_id === selling_partner_id
      )?.profile_id

      const payload = {
        seller_selling_partner_id: selling_partner_id,
        marketplace_marketplace_id: marketplace_id,
        profile_profile_id: profileId,
      }

      try {
        const response = await apiReviewAutomation.review(payload)

        if (response && response.status === 200) {
          setPublicId(response.data.public_id)
          setSolicitationDelay(
            response.data.solicitation_delay || minSolicitationDelay
          )
          setReviewState(response.data.state === 'enabled')
          setLoadingSwitch(false)
        } else {
          toast({
            variant: 'destructive',
            description: response
              ? response.data.message
              : 'Failed to process request.',
          })
        }
      } catch (error) {
        toast({
          variant: 'destructive',
          description: 'Failed to process request due to an error.',
        })
      }
    }

    fetchData()
  }, [reviewState])

  // for dashboard
  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL
  const [iframeUrl, setIframeUrl] = useState(analyticsUrl)
  const embedUrlParam = {
    resource: { dashboard: 14 },
    params: {
      seller_id: user.primary_seller.selling_partner_id,
      marketplace_id: user.primary_seller.primary_marketplace_id,
    },
  }

  // Get Metabase iframeUrl
  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed', embedUrlParam],
    () => apiAnalytics.generateEmbedUrl(embedUrlParam),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          // could handle this better but for now just assume token expired
          history.go(0) // refresh page
        }
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed',
      embedUrlParam,
    ])

    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)
    }
  }, [queryClient, embedUrlParam])

  return (
    <>
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto shadow-none border-none">
        <div className="flex justify-between items-start mb-6">
          <div className="flex flex-col gap-1">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Review Automation
            </h2>
            <p className="text-muted-foreground font-geist tracking-normal">
              {
                "Automate product review requests following Amazon's terms of service. "
              }
              <a
                href="https://help.autron.ai/en/articles/9910134-automate-amazon-product-review-requests"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-slate-600 underline"
              >
                Learn more
                <ExternalLink className="ml-1 h-4 w-4 -translate-y-[.10rem] inline" />
              </a>
            </p>
          </div>
        </div>

        <Card>
          <CardContent className="pt-6 overflow-visible">
            <div className="mb-6">
              <p className="text-muted-foreground mb-2 text-sm">
                Boost your product reviews with an Amazon-approved solution.
                Stay compliant with Amazon's terms of service while effortlessly
                increasing your reviews. Set it and forget it, eliminating the
                hassle of manually requesting reviews on Seller Central.
              </p>
              <p className="text-muted-foreground mb-4 italic text-sm">
                Note: Amazon allows review requests only between 5 and 30 days
                after the delivery date, so expect a lag between orders and
                review requests.
              </p>
              <div className="flex items-center space-x-2">
                <Label htmlFor="review-automation">Review Automation</Label>
                {loadingSwitch ? (
                  <LoadingSpinner className="h-4 w-4" />
                ) : (
                  <Switch
                    id="review-automation"
                    checked={reviewState}
                    onCheckedChange={toogleReviewState}
                  />
                )}
              </div>
            </div>

            {reviewState && (
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Label htmlFor="solicitation-delay">Request reviews</Label>
                  <Input
                    id="solicitation-delay"
                    type="number"
                    min={minSolicitationDelay}
                    max={maxSolicitationDelay}
                    value={solicitationDelay}
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setSolicitationDelay,
                        setSolicitationDelayValidation,
                        'positiveNumberGTE',
                        minSolicitationDelay
                      )
                    }}
                    className="w-20"
                  />
                  <span className="text-sm font-medium">
                    days after delivery.
                  </span>
                  <Button
                    variant="outline"
                    onClick={updateClick}
                    disabled={
                      loadingButton ||
                      solicitationDelayValidation === 'has-danger'
                    }
                  >
                    Update
                  </Button>
                </div>
                {solicitationDelayValidation === 'has-danger' && (
                  <Alert variant="destructive">
                    <AlertDescription>
                      This field is required and must be between{' '}
                      {minSolicitationDelay} and {maxSolicitationDelay}.
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            )}

            {reviewState && iframeUrl !== analyticsUrl && (
              <div className="mt-8">
                {loading && (
                  <div className="space-y-4 px-8 py-6">
                    <Skeleton className="h-4 w-full" />
                    <Skeleton className="h-4 w-3/4" />
                    <Skeleton className="h-4 w-1/2" />
                    <div className="grid grid-cols-3 gap-4 pt-4">
                      <Skeleton className="h-40 w-full" />
                      <Skeleton className="h-40 w-full" />
                      <Skeleton className="h-40 w-full" />
                    </div>
                    <div className="grid grid-cols-3 gap-4 pt-4">
                      <Skeleton className="h-40 w-full" />
                      <Skeleton className="h-40 w-full" />
                      <Skeleton className="h-40 w-full" />
                    </div>
                    <div className="grid grid-cols-3 gap-4 pt-4">
                      <Skeleton className="h-40 w-full" />
                      <Skeleton className="h-40 w-full" />
                      <Skeleton className="h-40 w-full" />
                    </div>
                  </div>
                )}
                <IframeResizer
                  src={iframeUrl}
                  style={{
                    width: '1px',
                    minWidth: '100%',
                    minHeight: '62.5em',
                  }}
                  className={loading ? 'hidden' : ''}
                  onLoad={() => setLoading(false)}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default Review