import React, { useContext, useEffect, useState } from 'react'
import autron_icon_light_mode from 'assets/img/autron_icon_light_mode.png'
import ThemeContext from 'services/providers/ThemeContext'
import autron_icon_dark_mode from 'assets/img/autron_icon_dark_mode.png'

const LogoLoader = ({ loading }) => {
  const [loaded, setLoaded] = useState(false)
  const { lightMode } = useContext(ThemeContext)
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }
  }, [loading])

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen -translate-y-8 flex justify-center items-center ${
        lightMode ? 'bg-white' : ''
      } transition-opacity duration-500 ${
        loaded ? 'opacity-0 pointer-events-none' : 'opacity-100'
      }`}
    >
      <img
        src={lightMode ? autron_icon_light_mode : autron_icon_dark_mode}
        alt="Logo Loader"
        className="w-20 animate-pulse"
      />
    </div>
  )
}

export default LogoLoader
