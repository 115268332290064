import { useQuery } from 'react-query'
import apiStripe from 'services/api/Stripe'

const useStripeSetup = () => {
  const { isLoading, error, data } = useQuery(
    'GET/stripe/setup',
    apiStripe.setup
  )

  if (error) {
    console.error('Error fetching Stripe setup:', error)
  }

  return {
    isLoading,
    setup: data?.data,
  }
}

export default useStripeSetup
