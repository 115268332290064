import { apiProvider } from './Provider'

export class ApiCore {
  constructor(options) {
    if (options.getAll) {
      this.getAll = () => {
        return apiProvider.getAll(options.url, options.getAllCredentials)
      }
    }

    if (options.getSingle) {
      this.getSingle = (id) => {
        return apiProvider.getSingle(
          options.url,
          id,
          options.getSingleCredentials
        )
      }
    }

    if (options.createSingle) {
      this.createSingle = (data) => {
        return apiProvider.createSingle(
          options.url,
          data,
          options.createSingleCredentials
        )
      }
    }

    if (options.updateSingle) {
      this.updateSingle = (id, data) => {
        return apiProvider.updateSingle(
          options.url,
          id,
          data,
          options.updateSingleCredentials
        )
      }
    }

    if (options.deleteSingle) {
      this.deleteSingle = (id) => {
        return apiProvider.deleteSingle(
          options.url,
          id,
          options.deleteSingleCredentials
        )
      }
    }

    if (options.updateMultiple) {
      this.updateMultiple = (data) => {
        return apiProvider.updateMultiple(
          options.url,
          data,
          options.updateSingleCredentials
        )
      }
    }

    if (options.post) {
      this.post = (data) => {
        return apiProvider.post(options.url, data, options.postCredentials)
      }
    }

    if (options.put) {
      this.put = (data) => {
        return apiProvider.put(options.url, data, options.putCredentials)
      }
    }

    if (options.patch) {
      this.patch = (data) => {
        return apiProvider.patch(options.url, data, options.patchCredentials)
      }
    }

    if (options.remove) {
      this.remove = (id) => {
        return apiProvider.remove(options.url, id, options.removeCredentials)
      }
    }
  }
}
